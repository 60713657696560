import React from "react";

import "./jobdetails.css";

import { AlertMessage, ImageName } from "../../../../enums";
import { ApiCall, ApiCallVendor } from "../../../../services/middleware";
import { Decoder } from "../../../../services/auth";
import { ErrorCode, UsersEnums } from "../../../../services/constant";
import { toast, ToastContainer } from "react-toastify";
import { emailValidator } from "../../../../validators";
import Select, { components } from "react-select";
import { InputText, MultiSelectBox, SelectBox } from "../../SharedComponents/inputText";
import history from "../../../../history";
import ReactLoader from "../../../Loader";
import {
  consoleLog,
  getDateWithMonthName,
  getLookUpDataFromAPI,
  getUtcDateWithMonthName,
  set12hourTimeFormatUtc,
  SetDateFormat,
  SetDOBFormat,
  SetTimeFormat,
  SetUSAdateFormatV2,
} from "../../../../services/common-function";
import ReactStars from "react-rating-stars-component";
import { Link } from "react-router-dom";
import { IMAGE_PATH_ONLY, LOCAL_HOST } from "../../../../services/config/api_url";
import EditJob from "./EditJobDetails";
import Button from '@mui/material/Button';



import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  TextField
} from "@material-ui/core";
import Card from 'react-bootstrap/Card';
import CloseButton from 'react-bootstrap/CloseButton';
import { TiDelete } from "react-icons/ti";
import { Stepper, Step } from 'react-form-stepper';
import MainChatPage from "../../../ChatPages/Mainpage";
import Modal from 'react-bootstrap/Modal';
import ChatGroupList from "./ChatGroupList";
import moment from "moment";
import { MdDelete } from "react-icons/md";
import { FcInfo } from "react-icons/fc";
import { FiEdit } from "react-icons/fi";
import { RiMailSendLine } from "react-icons/ri";
import EmailModal from "../EmailModal/EmailModal";
import { MdOutlineSms } from "react-icons/md";
import SmsModal from "../SmsModal/SmsModal";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { COMMON } from "../../../../services/constant/connpmData";



// .............................................................

const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    boxShadow: "0px 0px 4px 0px rgb(0 0 0 / 28%)",
    borderRadius: "6px",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = "yellow";
    return {
      ...styles,
      color: isFocused ? "grey" : "white",
      color: "#000",
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img
        src={ImageName.IMAGE_NAME.DOWN_ARROW_BLUE}
        style={{ width: "17px" }}
      />
    </components.DropdownIndicator>
  );
};

const appointmentTypeArr = [
  {
    label: "F2F",
    value: "1",
  },
  {
    label: "VRI",
    value: "2",
  },
];

const jobDuration = [
  {
    label: "Minute",
    value: "Minute",
  },
  {
    label: "Hour",
    value: "Hour",
  },
  {
    label: "Day",
    value: "Day",
  },
];

export default class JobDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: true,
      authUserData: {},
      requestId: "",
      clientId: "",
      clientFirstName: "",
      clientLastName: "",
      clientName: "",
      clientEmailId: "",
      clientPhone: "",
      clientIndustryType: "",
      countryCode: "",
      //   .................jobDetails...........
      jobId: "",
      appointmentType: "",
      jobType: "",
      language: "",
      date: "",
      serviceAddress: "",
      siteContant: "",
      consumer: "",
      notesByClient: "",
      notesBy7C: "",

      // ................sendQuote............
      quoteId: "",
      quoteStatus: 0,
      targetAdience: "",
      location: "",
      dateTime: "",
      notesFromClient: "",
      deliveryType: "",
      duration: "",
      rushFee: 0,
      interpretationFee: 0,
      bookingFee: 0,
      nightFee: 0,
      cancelFee: 0,
      weekendFee: 0,
      particularsArr: [],
      particular: "",
      particularAmount: [],
      totalParticularAmt: 0,
      particularAmt: 0,
      particularDataArr: [],
      particularAmountDataArr: [],
      totalAmount: 0,

      appointmentTypeArr: [],
      appointmentTypeData: [],
      languageArr: [],

      sourceLangData: {},
      targetLangData: {},
      listData: [],
      isView: false,

      allChecked: false,
      vendorAssigned: [],
      targetLanguage: "",
      bidFee: 0,
      totalBidFee: 0,
      bidVendorName: "",
      bidVendorId: "",
      isVendorAssigned: false,

      completeSummary: {},
      mislleniousExpenses: [],
      miscTotal: 0.0,
      availabileData: {},
      payable: [],
      receivable: [],

      allRating: [
        {
          label: "None",
          value: "",
        },
        {
          label: "0",
          value: "0",
        },
        {
          label: "1",
          value: "1",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "3",
          value: "3",
        },
        {
          label: "4",
          value: "4",
        },
        {
          label: "5",
          value: "5",
        },
      ],
      selectedFilterRating: {},
      filterRating: "",
      allWeekDay: [
        {
          label: "None",
          value: "",
        },
        {
          label: "Sunday",
          value: "0",
        },
        {
          label: "Monday",
          value: "1",
        },
        {
          label: "Tuesday",
          value: "2",
        },
        {
          label: "Wednesday",
          value: "3",
        },
        {
          label: "Thursday",
          value: "4",
        },
        {
          label: "Friday",
          value: "5",
        },
        {
          label: "Saturday",
          value: "6",
        },
      ],
      selectedFilterWeek: {},
      filterWeek: "",
      filterDistance: "",
      otherService: "",
      otherServiceData: "",

      arrivalTimeCheck: false,
      startTimeCheck: false,
      endTimeCheck: false,

      reqId: "",
      pathCheck: false,
      allJobDetails: {},


      showShare: false,
      allEmailArr: [],
      shareEmailText: "",
      vacationList: [],

      bidId: "",
      isRushFee: false,
      quoteRemarks: "",
      assignedVendor: "",
      progressLogData: [],
      jobCreateDate: "",

      allJobNotes: [],
      notesText: "",
      addParticipantShow: false,
      chatParticipants: [],
      selectedChatParticipants: [],
      chatGroup: [],
      selectedChatGroupValue: '',
      chatGroupName: "",

      allJobNotifications: [],
      chatParticipantModal: false,
      groupChatParticipant: [],
      addGrouoChatParticipantModal: false,
      selectedAddGroupChatParticipantValue: [],
      allEmailSmsLog: [],

      distanceFrom: "",
      distanceTo: "",
      relationshipFrom: "",
      relationshipToClient: "",
      relationshipToLei: "",
      costFrom: "",
      costTo: "",
      clientAlphaId: "",
      allAuthUserListForQuote: [],
      isAssignClick: false,
      loader: false,
      isNeedAttention: false,

      viewNoteModal: false,
      viewNote: "",

      rejectModal: false,
      rejectReason: "",
      selectedRejectData: {},

      needAttentionModal: false,
      updateNeedAttention: false,

      load: false,
      isBtnSubmit: false,

      showTab: "job",
      allRelationshipForVendor: [],
      selectedRelationship: {},
      allTotalCostForVendor: [],
      selectedTotalCost: {},
      allGenderForVendor: [],
      selectedGender: {},
      allDistanceForVendor: [],
      selectedMileage: {},
      filterVendorGender: "",

      updatedAmount: "",
      updateDetails: false,
      updateBillId: "",
      billQty: "",
      billUnitPrice: "",
      billRemarks: "",

      quoteDeclineReason: "",
      quoteDeclineDescription: "",
      additionalJobInfo: {},
      isScheduled: 0,
      filterVendorName: "",

      sentEmailModal: false,
      sentSmsModal: false,
      numJobId: 0,
      userType: 0,
      viewEmailModal: false,
      viewEmailModalData: {},
      vendorRejectReasonModal: false,
      vendorRejectReasonData: "",
      addGroupChatParticipant: [],
      cancelledReason: "",
      sortingFilterArr: COMMON.SORTING_ARRAY_AVAILABLE_VENDOR,
      selectedVendorSortingData: {},
      filterVendorSort: "",
      filterVendorSortBy: "",
      leiName: "",
      vendorInfo: {},
      unassignVendorModal: false,
      assignVendorModal: false,
      allAuditData: [],
      allChatParticipants: []
    };
  }

  loaderChange = async (type) => {
    this.setState({
      loader: type
    })
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.getElementById("backdrop").style.display = "none";
    let mainData = this.props.location,
      preData = mainData.state;

    // console.log("MainData Form >>> ", mainData)
    if (mainData.from !== undefined) {
      if (mainData.from != 1) {
        this.setState({
          showTab: mainData.from
        })
      }
    } else {
      this.setState({
        showTab: "job"
      })
    }
    // consoleLog("predata:::",preData);
    if (preData === undefined) {
      return history.push("/adminViewAllJobs");
    } else {
      this.setState({
        reqId: preData,
      });

      this.load();
    }
    // this.load();

    if (this.props.match.path === "/adminJobDetailsFromBillVerification") {
      this.setState({
        pathCheck: true,
      });
    } else {
      this.setState({
        pathCheck: false,
      });
    }


    let authData = localStorage.getItem("AuthToken");
    let authUser = Decoder.decode(authData);

    this.setState({
      userType: authUser.data.usertypeid
    });
    var classInstance = this;

    var viewModal = document.getElementById("viewModal");
    var bidModal = document.getElementById("bid-modal");
    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
      if (event.target == viewModal) {
        classInstance.closeViewModal();
      } else if (event.target == bidModal) {
        classInstance.closeBidModal();
      }
    };
  }

  load = async () => {
    let languageArrData = [],
      languageObjData = {},
      languageResArrData = [],
      clientDetails = {},
      jobDetails = {},
      taskLanguage = "",
      quoteData = {},
      quoteInfo = {},
      targetLanguage = "",
      completeSummary = {},
      mislleniousExpenses = [],
      miscTotal = 0.0,
      payable = [],
      receivable = [],
      otherService = "",
      otherServiceData = "",
      allRelationshipArr = [],
      allGenderArr = [],
      allCostArr = [],
      allDistanceArr = [],
      allLookupValue = [];

    let mainData = this.props.location,
      preData = mainData.state;
    this.setState({
      requestId: preData,
    });
    let detailData = {
      requestId: preData,
    };

    allLookupValue = await getLookUpDataFromAPI();


    allLookupValue.GENDER_TYPE.map((data) => {
      allGenderArr.push({
        label: data.name,
        value: data.id,
      });
    });
    // //...............Get complete summury............

    // let summuryRes = await ApiCall("getJobCompleteSummary", detailData);
    // if (
    //   summuryRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
    //   summuryRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    // ) {
    //   let payload = Decoder.decode(summuryRes.data.payload);
    //   consoleLog("Conmplete Summary Job >>>>>>>>>>> ", payload.data);
    //   if (payload.data.jobSummary) {
    //     completeSummary = payload.data.jobSummary;
    //   }
    //   if (payload.data.miscJobExpense.length > 0) {
    //     mislleniousExpenses = payload.data.miscJobExpense;
    //     payload.data.miscJobExpense.map((data) => {
    //       miscTotal = parseFloat(miscTotal) + parseFloat(data.amount);
    //     });
    //   }
    // }

    // .............................................

    let detailsRes = await ApiCall("getJobDetails", detailData);

    let detailPayload = Decoder.decode(detailsRes.data.payload);
    consoleLog("Interpreatation Job Details.....>>", detailPayload);
    this.setState({
      allJobDetails: detailPayload.data
    })
    if (detailPayload.data.clientDetails) {
      clientDetails = detailPayload.data.clientDetails;
    }

    // consoleLog("job details:::",detailPayload.data.jobDetails)

    if (detailPayload.data.jobDetails) {
      jobDetails = detailPayload.data.jobDetails;
      await this.fetchChatGroup(jobDetails.id);
      this.fetchProgressData(jobDetails.id);
      this.fetchEmailSmsLog(jobDetails.id);
      this.fetchAuditData(jobDetails.id);
      this.fetchJobNotification(jobDetails.id);
      this.fetchNotes(jobDetails.id);
      await this.fetchChatParticipants(jobDetails.id);
      await this.fetchGroupChatParticipants(jobDetails.id);
      if (jobDetails.quoteStatus == 1) {
        this.fetchAuthorisedQuoteAcceptPerson(jobDetails.id);
      }
    }
    if (Object.keys(detailPayload.data.quoteInfo).length > 0) {
      quoteInfo = detailPayload.data.quoteInfo;

      if (
        quoteInfo.additionalFee != null ||
        quoteInfo.additionalFee != undefined ||
        quoteInfo.additionalFee != ""
      ) {
        quoteData = JSON.parse(quoteInfo.additionalFee);
      }

      // if (quoteInfo.total === 0) {
      //   this.setState({
      //     isView: false
      //   })
      // } else {
      //   this.setState({
      //     isView: true
      //   })
      // }

      if (jobDetails.quoteStatus === 0) {
        this.setState({
          isView: false,
        });
      } else {
        this.setState({
          isView: true,
        });
      }
    }
    if (detailPayload.data.taskList.length > 0) {
      taskLanguage =
        detailPayload.data.taskList[0].sourceLanguage +
        " --> " +
        detailPayload.data.taskList[0].targetLanguage;
      targetLanguage = detailPayload.data.taskList[0].targetLanguageId;
    } else {
      taskLanguage = detailPayload.data.jobDetails.sourceLanguage;
    }

    //................ For all Vendor.................
    let vData = {
      "requestId": preData,
      "targetLanguageId": targetLanguage,
      "rating": "",
      "weekIndex": "",
      "distanceFrom": "",
      "distanceTo": "",
      "relationshipFrom": "",
      "relationshipTo": "",
      "costFrom": "",
      "costTo": ""
    }

    this.getAvailableVendors(vData);

    if (jobDetails.quoteStatus === 10) {

      //...............Get complete summury............

      let summuryRes = await ApiCall("getJobCompleteSummary", detailData);
      if (
        summuryRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        summuryRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        let payload = Decoder.decode(summuryRes.data.payload);
        consoleLog("Conmplete Summary Job >>>>>>>>>>> ", payload.data);
        if (payload.data.jobSummary) {
          completeSummary = payload.data.jobSummary;
        }
        if (payload.data.miscJobExpense.length > 0) {
          mislleniousExpenses = payload.data.miscJobExpense;
          payload.data.miscJobExpense.map((data) => {
            miscTotal = parseFloat(miscTotal) + parseFloat(data.amount);
          });
        }
      }
    }

    if (jobDetails.quoteStatus === 10 || jobDetails.quoteStatus === 11) {

      //...............Get Payable and Receivable Data............

      let payableRes = await ApiCall(
        "fetchAccountPayableInterpretation",
        detailData
      );
      if (
        payableRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        payableRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        let payload = Decoder.decode(payableRes.data.payload);
        consoleLog("Payable List:::", payload.data);
        if (payload.data.details.length > 0) {
          payable = payload.data.details;
        }
      }

      let receivableRes = await ApiCall(
        "fetchAccountReceivabaleInterpretation",
        detailData
      );
      if (
        receivableRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        receivableRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        let payload = Decoder.decode(receivableRes.data.payload);
        consoleLog("Receivable List:::", payload.data);
        if (payload.data.details.length > 0) {
          receivable = payload.data.details;
        }
      }
    }

    if (jobDetails.appointmentType !== "F2F") {
      otherService = jobDetails.otherServices;
      otherServiceData = jobDetails.otherServicesData;
    }

    /* Data for Relationship of vendor */
    for (let i = 0; i <= 10; i++) {
      allRelationshipArr.push({
        label: i.toString(),
        value: i.toString()
      })
    }

    /* Data for Cost of vendor */
    for (let i = 0; i <= 50; i++) {
      allCostArr.push({
        label: "$ " + i,
        value: i.toString()
      })
    }

    /* Data for distance of vendor */
    for (let i = 0; i <= 200; i++) {
      if (i % 10 === 0) {
        allDistanceArr.push({
          label: i.toString(),
          value: i.toString()
        })
      }
    }

    // ...........................................

    this.setState({
      // sourceLangData: languageObjData,
      // targetLangData: languageObjData,
      // languageArr: languageArrData,
      // .......... For Client Details..........
      clientId: jobDetails.clientId,
      clientAlphaId: clientDetails.clientAlphaId,
      clientFirstName: clientDetails.fName,
      clientLastName: clientDetails.lName,
      clientEmailId: clientDetails.businessEmail,
      clientName: clientDetails.clientName,
      clientIndustryType: clientDetails.industtryType,
      clientPhone: clientDetails.adminPhone,
      //............For Job Details  tab..........
      numJobId: jobDetails.id,
      quoteStatus: jobDetails.quoteStatus,
      jobId: jobDetails.jobId,
      appointmentType: jobDetails.appointmentType,
      jobType: jobDetails.jobType,
      language: taskLanguage,
      date: jobDetails.scheduleDate,
      countryCode: clientDetails.adminCountryCode,
      serviceAddress:
        jobDetails.location === null ||
          jobDetails.location === undefined ||
          jobDetails.location === ""
          ? "N/A"
          : jobDetails.location,
      siteContant:
        jobDetails.siteContact === undefined ||
          jobDetails.siteContact === null ||
          jobDetails.siteContact === ""
          ? "N/A"
          : jobDetails.siteContact,
      consumer: jobDetails.consumer,
      notesByClient: jobDetails.noteByClient,
      notesBy7C: jobDetails.noteFor7C,
      quoteId:
        quoteInfo.quoteId === null ||
          quoteInfo.quoteId === undefined ||
          quoteInfo.quoteId === ""
          ? "N/A"
          : quoteInfo.quoteId,
      targetAdience:
        jobDetails.targetAudience === null ||
          jobDetails.targetAudience === undefined ||
          jobDetails.targetAudience === ""
          ? "N/A"
          : jobDetails.targetAudience,
      location:
        jobDetails.location === null ||
          jobDetails.location === undefined ||
          jobDetails.location === ""
          ? "N/A"
          : jobDetails.location,
      dateTime: jobDetails.scheduleDate,
      scheduleTime: jobDetails.scheduleTime,
      serviceProvider: jobDetails.noOfserviceProvider,
      notesFromClient: jobDetails.noteByClient,
      deliveryType: jobDetails.isOutOfTown === 1 ? "OUT OF TOWN" : "IN TOWN",
      duration: jobDetails.duration,
      clientIndustryType: clientDetails.industtryType,
      interpretationFee: quoteInfo.fee,
      bookingFee: quoteInfo.bookingFee,
      nightFee: quoteInfo.nightFee,
      cancelFee: quoteInfo.cancelFee,
      weekendFee: quoteInfo.weekendFee,
      rushFee: quoteInfo.rushFee,
      totalAmount: quoteInfo.total,
      particularsArr: quoteData !== null ? quoteData : [],
      targetLanguage: targetLanguage,
      completeSummary: completeSummary,
      mislleniousExpenses: mislleniousExpenses,
      miscTotal: miscTotal,
      payable: payable,
      receivable: receivable,
      otherService: otherService,
      otherServiceData: otherServiceData,
      bidId: quoteInfo.bidId,
      isRushFee: Number(quoteInfo.rushFee) > 0 ? true : false,
      quoteRemarks: quoteInfo.remarks == null ? "" : quoteInfo.remarks,
      assignedVendor: jobDetails.vendorName ? jobDetails.vendorName : "NA",
      jobCreateDate: SetUSAdateFormatV2(jobDetails.serviceReqDate),
      isNeedAttention: jobDetails.isNeedAttention === 1 ? true : false,
      updateNeedAttention: jobDetails.isNeedAttention === 1 ? true : false,
      allRelationshipForVendor: allRelationshipArr,
      allTotalCostForVendor: allCostArr,
      allDistanceForVendor: allDistanceArr,
      allGenderForVendor: allGenderArr,
      quoteDeclineReason: quoteInfo.declinedType,
      quoteDeclineDescription: quoteInfo.declinedReason,
      isScheduled: jobDetails.isScheduled,
      additionalJobInfo: Number(jobDetails.isScheduled) === 1 ? jobDetails.additionalJobInfo !== null ? jobDetails.additionalJobInfo : {} : {},
      cancelledReason: jobDetails.rejectReason,
      leiName: jobDetails.leiName ? jobDetails.leiName : "NA",
      isLoad: false,
    });
  };

  fetchProgressData = async (id) => {
    let obj = {
      jobId: id
    }
    let res = await ApiCall("fetchProgressLog", obj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      consoleLog("Progress Log Data  >>>>>", payload.data);
      if (payload.data.progressData.length > 0) {
        let arr = [],
          notiArr = [];
        payload.data.progressData.map((item) => {
          arr.push({
            label: (
              <div style={{ width: "100%", textAlign: "center" }}>
                <span>{item.progressType}</span><br />
                <span>{SetDOBFormat(item.createDate)}&nbsp;{SetTimeFormat(item.createDate)}</span>
              </div>
            )
          })
          notiArr.push({
            body: item.progressType,
            time: moment(item.createDate).fromNow()
          })
        })

        let newNotiArr = notiArr.reverse();
        this.setState({
          progressLogData: arr,
          // allJobNotifications: newNotiArr
        })
      } else {
        this.setState({
          progressLogData: []
        })
      }
    }

  }

  /* Fetch job notification */
  fetchJobNotification = async (id) => {
    let obj = {
      requestId: id
    }
    let res = await ApiCall("getUserNotificationByJobid", obj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      consoleLog("Job Notification Data  >>>>>", payload.data);
      if (payload.data.length > 0) {
        let arr = [];
        arr = payload.data;
        this.setState({
          allJobNotifications: arr
        })
      } else {
        this.setState({
          allJobNotifications: []
        })
      }
    }
  }

  fetchEmailSmsLog = async (id) => {
    let obj = {
      requestId: id
    }
    let res = await ApiCall("getEmailSmsLogByJobId", obj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      consoleLog("Email SMS Log Data  >>>>>", payload);
      if (payload.data.log.length > 0) {
        let arr = [];
        this.setState({
          allEmailSmsLog: payload.data.log
        })
      } else {
        this.setState({
          allEmailSmsLog: []
        })
      }
    }

  }

  fetchAuditData = async (id) => {
    let obj = {
      jobId: id
    }
    let res = await ApiCall("getAuditLog", obj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      consoleLog("Audit Log Data  >>>>>", payload);
      if (payload.data.data.length > 0) {
        let arr = [];
        this.setState({
          allAuditData: payload.data.data
        })
      } else {
        this.setState({
          allAuditData: []
        })
      }
    }

  }

  getAvailableVendors = async (data) => {
    // let res = await ApiCall("getVendorsWorkingStatus", data);
    let res = await ApiCall("getJobAvailableVendor", data);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      try {
        let payload = Decoder.decode(res.data.payload);
        consoleLog("Available Vendor List >>> ", payload.data)
        let vendorList = [],
          brr = [];

        if (payload.data.vendorList.length > 0) {
          vendorList = payload.data.vendorList;
          vendorList.map((vv) => {
            if (vv.isQuoteSent === 1) {
              vv['sent'] = 1;
            } else {
              vv['sent'] = 0;
            }
          })
          let scount = 0;
          payload.data.vendorList.map((aa) => {
            if (aa.isQuoteSent === 1) {
              brr.push(aa.userId);
            }
            if (aa.status === 2) {
              scount++;
            }
          });
          if (scount > 0) {
            this.setState({
              isVendorAssigned: true,
            });
          }
        }
        this.setState({
          listData: vendorList,
          vendorAssigned: brr,
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  blankValueCheck = (data) => {
    if (
      data === null ||
      data === undefined ||
      data === "" ||
      data.length === 0
    ) {
      data = "N/A";
      return data;
    } else {
      return data;
    }
  };

  // listApi = async (data) => {
  //   const res = await ApiCall("fetchapprovedclientcontactlist", data);
  //   console.log("resData::::", res);
  //   if (
  //     res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
  //     res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
  //   ) {
  //     const decodeData = Decoder.decode(res.data.payload);
  //     console.log("Payload data>>>", decodeData);
  //     let listDetails = decodeData.data.clientContactDetailsList;
  //     let totalPage = Math.ceil(decodeData.data.totalCount / this.state.limit);
  //     console.log("Total Page>>>", listDetails);
  //     this.setState({
  //       listData: decodeData.data.clientContactDetailsList,
  //       total_page: totalPage,
  //     });
  //   }
  // };

  onAppointmentChange = (data) => {
    this.setState({
      appointmentTypeData: data,
    });
  };

  onSourceLangChange = (data) => {
    this.setState({
      sourceLangData: data,
    });
  };

  onTargetLangChange = (data) => {
    this.setState({
      targetLangData: data,
    });
  };
  onParticularChange = (e, index) => {
    let amt = this.state.particularsArr,
      particularData = [],
      particularDataArr = [];
    for (let i = 0; i < this.state.particularsArr.length; i++) {
      if (i === index) {
        amt[i].title = e.target.value;
        particularDataArr.push(amt[i].title);
      }
      particularData.push(amt[i].title);
    }

    this.setState({
      particularsArr: amt,
      particularDataArr: particularData,
    });
  };

  onParticularAmountChange = (e, index) => {
    let data = e.target.value,
      particularAmtDataArr = [];
    var valid = !isNaN(data);

    let amount = e.target.value === "" ? 0 : parseFloat(e.target.value);


    let amt = this.state.particularsArr,
      rushfee = this.state.rushFee,
      interpretationFee = this.state.interpretationFee,
      totalAmt = 0,
      sum = 0;
    if (valid) {
      for (let i = 0; i < amt.length; i++) {
        if (i === index) {
          amt[i].amt = amount.toString();
          particularAmtDataArr.push(amt[i].amt);
        }

        sum = sum + parseFloat(amt[i].amt);
      }
      totalAmt = sum + parseFloat(rushfee) + parseFloat(interpretationFee) + this.state.bookingFee + this.state.nightFee + this.state.cancelFee + this.state.weekendFee;
    }

    this.setState({
      particularsArr: amt,
      totalParticularAmt: sum,
      totalAmount: totalAmt.toFixed(2)
    });
  };

  onRushFeeChange = (e) => {
    let totalAmt = 0;

    if (
      e.target.value !== "" &&
      !isNaN(e.target.value) &&
      e.target.value !== 0
    ) {
      totalAmt =
        totalAmt +
        parseFloat(e.target.value) +
        parseFloat(this.state.interpretationFee) + this.state.bookingFee + this.state.nightFee + this.state.cancelFee + this.state.weekendFee;
      this.setState({
        rushFee: parseFloat(e.target.value),
        totalAmount: this.calculateTotalAmount(totalAmt.toFixed(3))
      });
    } else {
      totalAmt =
        totalAmt +
        parseFloat(this.state.interpretationFee) + this.state.bookingFee + this.state.nightFee + this.state.cancelFee + this.state.weekendFee;
      this.setState({
        rushFee: 0,
        totalAmount: this.calculateTotalAmount(totalAmt.toFixed(3))
      });
    }
  };
  onInterpretationFeeChange = async (e) => {
    let totalAmt = 0;


    const newValue = e.target.value;
    const regex = /^[+-]?\d*\.?\d{0,3}$/;
    // console.log("e.target.value", e.target.value)
    if (regex.test(newValue)) {
      const parsedValue = parseFloat(newValue); // Convert input to a float
      if (newValue === "" || newValue === "0") {
        totalAmt =
          totalAmt +
          0 +
          parseFloat(this.state.rushFee) + this.state.bookingFee + this.state.nightFee + this.state.cancelFee + this.state.weekendFee;
        this.setState({
          interpretationFee: 0,
          totalAmount: this.calculateTotalAmount(totalAmt.toFixed(3))
        });
      } else {
        const parsedValue = parseFloat(newValue);
        if (!isNaN(parsedValue)) {
          await this.loaderChange(true);
          totalAmt =
            totalAmt +
            parseFloat(parsedValue) +
            parseFloat(this.state.rushFee) + this.state.bookingFee + this.state.nightFee + this.state.cancelFee + this.state.weekendFee;
          this.setState({
            interpretationFee: parsedValue,
            totalAmount: this.calculateTotalAmount(totalAmt.toFixed(3))
          });
          await this.loaderChange(false);
        }
      }
    }


    // if (
    //   e.target.value !== "" &&
    //   !isNaN(e.target.value) &&
    //   e.target.value !== 0
    // ) {
    //   totalAmt =
    //     totalAmt +
    //     parseFloat(e.target.value) +
    //     parseFloat(this.state.rushFee) + this.state.bookingFee + this.state.nightFee + this.state.cancelFee + this.state.weekendFee +
    //     parseFloat(this.state.totalParticularAmt);
    //   this.setState({
    //     interpretationFee: parseFloat(e.target.value),
    //     totalAmount: totalAmt.toFixed(2),
    //   });
    // } else {
    //   totalAmt =
    //     totalAmt +
    //     parseFloat(this.state.rushFee) + this.state.bookingFee + this.state.nightFee + this.state.cancelFee + this.state.weekendFee +
    //     parseFloat(this.state.totalParticularAmt);
    //   this.setState({
    //     interpretationFee: 0,
    //     totalAmount: totalAmt.toFixed(2),
    //   });
    // }
  };


  onBookingFeeChange = async (e) => {
    let totalAmt = 0;


    const newValue = e.target.value;
    const regex = /^[+-]?\d*\.?\d{0,3}$/;
    // console.log("e.target.value", e.target.value)
    if (regex.test(newValue)) {
      const parsedValue = parseFloat(newValue); // Convert input to a float
      if (newValue === "" || newValue === "0") {
        totalAmt =
          totalAmt +
          0 +
          parseFloat(this.state.rushFee) + this.state.interpretationFee + this.state.nightFee + this.state.cancelFee + this.state.weekendFee;
        this.setState({
          bookingFee: 0,
          totalAmount: this.calculateTotalAmount(totalAmt.toFixed(3))
        });
      } else {
        const parsedValue = parseFloat(newValue);
        // console.log("Parsed Value >>>>> ", parsedValue)
        if (!isNaN(parsedValue)) {
          await this.loaderChange(true);
          totalAmt =
            totalAmt +
            parsedValue
            +
            parseFloat(this.state.rushFee) + this.state.interpretationFee + this.state.nightFee + this.state.cancelFee + this.state.weekendFee;
          // console.log("Total Amount >>>>> ", totalAmt)
          this.setState({
            bookingFee: parsedValue,
            totalAmount: this.calculateTotalAmount(totalAmt.toFixed(3))
          });
          await this.loaderChange(false);
        }
      }
    }
  };


  onNightFeeChange = async (e) => {
    let totalAmt = 0;


    const newValue = e.target.value;
    const regex = /^[+-]?\d*\.?\d{0,3}$/;
    // console.log("e.target.value", e.target.value)
    if (regex.test(newValue)) {
      const parsedValue = parseFloat(newValue); // Convert input to a float
      if (newValue === "" || newValue === "0") {
        totalAmt =
          totalAmt +
          0 +
          parseFloat(this.state.rushFee) + this.state.interpretationFee + this.state.bookingFee + this.state.cancelFee + this.state.weekendFee;
        this.setState({
          nightFee: 0,
          totalAmount: this.calculateTotalAmount(totalAmt)
        });
      } else {
        const parsedValue = parseFloat(newValue);
        if (!isNaN(parsedValue)) {
          await this.loaderChange(true);
          totalAmt =
            totalAmt +
            parseFloat(parsedValue) +
            parseFloat(this.state.rushFee) + this.state.interpretationFee + this.state.bookingFee + this.state.cancelFee + this.state.weekendFee;
          this.setState({
            nightFee: parsedValue,
            totalAmount: this.calculateTotalAmount(totalAmt)
          });
          await this.loaderChange(false);
        }
      }
    }
  };


  onWeekendFeeChange = async (e) => {
    let totalAmt = 0;


    const newValue = e.target.value;
    const regex = /^[+-]?\d*\.?\d{0,3}$/;
    // console.log("e.target.value", e.target.value)
    if (regex.test(newValue)) {
      const parsedValue = parseFloat(newValue); // Convert input to a float
      if (newValue === "" || newValue === "0") {
        totalAmt =
          totalAmt +
          0 +
          parseFloat(this.state.rushFee) +
          this.state.interpretationFee +
          this.state.nightFee + this.state.cancelFee + this.state.bookingFee;
        this.setState({
          weekendFee: 0,
          totalAmount: this.calculateTotalAmount(totalAmt)
        });
      } else {
        const parsedValue = parseFloat(newValue);
        if (!isNaN(parsedValue)) {
          await this.loaderChange(true);
          totalAmt =
            totalAmt +
            parseFloat(parsedValue) +
            parseFloat(this.state.rushFee) + this.state.interpretationFee + this.state.nightFee + this.state.cancelFee + this.state.bookingFee;
          this.setState({
            weekendFee: parsedValue,
            totalAmount: this.calculateTotalAmount(totalAmt)
          });
          await this.loaderChange(false);
        }
      }
    }
  };


  onCancelFeeChange = async (e) => {
    let totalAmt = 0;


    const newValue = e.target.value;
    const regex = /^[+-]?\d*\.?\d{0,3}$/;
    // console.log("e.target.value", e.target.value)
    if (regex.test(newValue)) {
      const parsedValue = parseFloat(newValue); // Convert input to a float
      if (newValue === "" || newValue === "0") {
        totalAmt =
          totalAmt +
          0 +
          parseFloat(this.state.rushFee) +
          this.state.interpretationFee +
          this.state.nightFee +
          this.state.bookingFee +
          this.state.weekendFee;
        this.setState({
          cancelFee: 0,
          totalAmount: this.calculateTotalAmount(totalAmt)
        });
      } else {
        const parsedValue = parseFloat(newValue);
        if (!isNaN(parsedValue)) {
          await this.loaderChange(true);
          totalAmt =
            totalAmt +
            parseFloat(parsedValue) +
            parseFloat(this.state.rushFee) +
            this.state.interpretationFee +
            this.state.nightFee +
            this.state.bookingFee +
            this.state.weekendFee;
          this.setState({
            cancelFee: parsedValue,
            totalAmount: this.calculateTotalAmount(totalAmt)
          });
          await this.loaderChange(false);
        }
      }
    }
  };


  addParticularField = () => {
    let arr = this.state.particularsArr;
    // particularItem = "",
    // particularAmnt = 0;
    // for (let i = 0; i < this.state.particularsArr.length; i++) {
    arr.push({
      title: this.state.particular,
      // particularAmt: parseInt(this.state.particularAmt),
      amt: this.state.particularAmt,
    });

    this.setState({
      particularsArr: arr,
    });
  };

  calculateTotalAmount = (val) => {
    let particularAmt = 0;
    let particularArray = this.state.particularsArr;
    for (let i = 0; i < particularArray.length; i++) {
      particularAmt = particularAmt + Number(particularArray[i].amt);
    }

    let total = Number(val) + particularAmt;
    return total;

  }
  onDeleteParticulars = (index) => {
    // console.log("index no:", index);
    let particularArray = this.state.particularsArr;

    let arr = [],
      amt = 0,
      particularAmt = 0;
    for (let i = 0; i < particularArray.length; i++) {
      if (i != index) {
        particularAmt = particularAmt + parseFloat(particularArray[i].amt);
        arr.push(particularArray[i]);
      }
    }
    amt =
      amt +
      particularAmt + this.state.bookingFee + this.state.nightFee + this.state.cancelFee + this.state.weekendFee +
      parseFloat(this.state.rushFee) +
      parseFloat(this.state.interpretationFee);

    // console.log("_____+++++", arr);

    this.setState({
      particularsArr: arr,
      totalAmount: amt.toFixed(2),
      totalParticularAmt: particularAmt.toFixed(2),
    });
  };
  onBack = () => {
    return history.push("/adminViewAllJobs");
  };

  onUpdateQuote = async () => {
    let mainData = this.props.location;
    let preData = mainData.state;
    let errorCount = 0;

    // let validateInterpretationFee = inputEmptyValidate(
    //   this.state.interpretationFee
    // );

    if (this.state.interpretationFee === 0) {
      toast.error(AlertMessage.MESSAGE.JOB.EMPTY_FEE, {
        hideProgressBar: true,
      });
      errorCount++;
    } else if (this.state.quoteRemarks.length > 200) {
      toast.error("Quote remarks cannot be more than 200 characters");
      errorCount++;
    }

    if (errorCount === 0) {
      let data = {
        requestId: preData,
        fee: this.state.interpretationFee.toString(),
        total: this.state.totalAmount,
        rushFee: this.state.rushFee.toString(),
        additionalFee: this.state.particularsArr,
        quoteId: this.state.quoteId,
        remarks: this.state.quoteRemarks,
        bookingFee: this.state.bookingFee.toString(),
        nightFee: this.state.nightFee.toString(),
        weekendFee: this.state.weekendFee.toString(),
        cancelFee: this.state.cancelFee.toString()
      };

      consoleLog("Sent Quote Data >>>>>> ", data);

      let res = await ApiCall("updateQuote", data);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        toast.success(AlertMessage.MESSAGE.JOB.QUOTE_UPDATE, {
          hideProgressBar: true,
        });
        return history.push("/adminViewAllJobs");
      }
    }
  };


  onSaveQuote = async () => {
    let mainData = this.props.location;
    let preData = mainData.state;
    let errorCount = 0;

    // let validateInterpretationFee = inputEmptyValidate(
    //   this.state.interpretationFee
    // );

    if (this.state.interpretationFee === 0) {
      toast.error(AlertMessage.MESSAGE.JOB.EMPTY_FEE, {
        hideProgressBar: true,
      });
      errorCount++;
    } else if (this.state.quoteRemarks.length > 200) {
      toast.error("Quote remarks cannot be more than 200 characters");
      errorCount++;
    }

    if (errorCount === 0) {
      let data = {
        requestId: preData,
        fee: this.state.interpretationFee.toString(),
        total: this.state.totalAmount.toString(),
        rushFee: this.state.rushFee.toString(),
        additionalFee: this.state.particularsArr,
        quoteId: this.state.quoteId,
        approvalStatus: this.state.quoteStatus.toString(),
        remarks: this.state.quoteRemarks,
        bookingFee: this.state.bookingFee.toString(),
        nightFee: this.state.nightFee.toString(),
        weekendFee: this.state.weekendFee.toString(),
        cancelFee: this.state.cancelFee.toString()
      };
      // consoleLog("Save Quote Req >>>> ", data)
      let res = await ApiCall("saveQuote", data);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        toast.success(AlertMessage.MESSAGE.JOB.QUOTE_SAVE);
        return history.push("/adminViewAllJobs");
      }
    }
  };

  allVendorCheck = async (e) => {
    let arr = this.state.listData;
    let brr = [];
    if (e.target.checked) {
      arr.map((data) => {
        data.isQuoteSent = 1;
        brr.push(data.userId);
      });
      await this.loaderChange(true);
      this.setState({
        allChecked: true,
        listData: arr,
        vendorAssigned: brr,
      });
      await this.loaderChange(false);
    } else {
      arr.map((data) => {
        data.isQuoteSent = 0;
      });
      brr = [];
      await this.loaderChange(true);
      this.setState({
        allChecked: false,
        listData: arr,
        vendorAssigned: brr,
      });
      await this.loaderChange(false);
    }
  };

  listChecked = async (e, i) => {
    let arr = this.state.listData,
      brr = this.state.vendorAssigned;
    if (e.target.checked) {
      arr[i].isQuoteSent = 1;
      brr.push(arr[i].userId);
      await this.loaderChange(true);
      this.setState({
        vendorAssigned: brr,
      });
      await this.loaderChange(false);
    } else {
      arr[i].isQuoteSent = 0;
      brr.map((data, k) => {
        if (data === arr[i].userId) {
          brr.splice(k, 1);
        }
      });
      await this.loaderChange(true);
      this.setState({
        vendorAssigned: brr,
        allChecked: false,
      });
      await this.loaderChange(false);
    }
  };

  loaderChange = async (type) => {
    this.setState({
      load: type
    });
  }

  handleVendorAssign = async () => {
    this.setState({
      isLoad: true
    })
    let data = {
      requestId: this.state.requestId,
      vendorId: this.state.vendorAssigned,
    };
    // consoleLog("Sent Offer Data>>>", data)
    let res = await ApiCall("sentOfferToVendor", data);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      toast.success(AlertMessage.MESSAGE.JOB.OFFER_SENT_SUCCESS);
      setTimeout(
        function () {
          // return history.push("/adminViewAllJobs");
          this.load()
        }.bind(this),
        1000
      );
    } else {
      this.setState({
        isLoad: false
      })
    }
  };

  handleViewModal = async (userId) => {
    let res = await ApiCall("fetchVendorAvailablityList", {
      vendorId: userId,
    });
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);

      this.setState({
        availabileData: payload.data,
      });
      this.openViewModal();
    }

    // ...........for  vacation..........

    let objData = {
      vendorId: userId,
      offset: "0",
      limit: "1000",
    };
    let resVac = await ApiCallVendor(
      "fetchVendorVaccationListByUserId",
      objData
    );

    if (
      resVac.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      resVac.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let DecodeData = Decoder.decode(resVac.data.payload);
      // consoleLog("response vac::", DecodeData);
      if (DecodeData.data.vaccationList.length > 0) {
        this.setState({
          vacationList: DecodeData.data.vaccationList,
        });
      } else {
        this.setState({
          vacationList: [],
        });
      }
    }
  };
  openViewModal = () => {
    document.getElementById("backdrop").style.display = "block";
    document.getElementById("viewModal").style.display = "block";
    document.getElementById("viewModal").classList.add("show");
  };
  closeViewModal = () => {
    document.getElementById("backdrop").style.display = "none";
    document.getElementById("viewModal").style.display = "none";
    document.getElementById("viewModal").classList.remove("show");
  };

  handleBidModal = async (vid) => {
    let arr = this.state.listData;
    arr.map((data) => {
      if (data.userId === vid) {
        this.setState({
          bidVendorName: data.fName + " " + data.lName,
          bidFee: data.bidFee,
          totalBidFee: data.totalBidFee,
          bidVendorId: vid,
        });
      }
    });
    this.openBidModal();
  };
  openBidModal = () => {
    document.getElementById("backdrop").style.display = "block";
    document.getElementById("bid-modal").style.display = "block";
    document.getElementById("bid-modal").classList.add("show");
  };
  closeBidModal = () => {
    document.getElementById("backdrop").style.display = "none";
    document.getElementById("bid-modal").style.display = "none";
    document.getElementById("bid-modal").classList.remove("show");
  };

  vendoeAssigned = async (vid) => {
    let apiObj = {
      requestId: this.state.requestId,
      vendorId: vid,
    };
    let res = await ApiCall("assignTraningInterpretion", apiObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      toast.success(AlertMessage.MESSAGE.JOB.VENDOR_ASSIGNED_SUCCESS);
      this.closeBidModal();
      setTimeout(
        function () {
          return history.push("/adminViewAllJobs");
        }.bind(this),
        1000
      );
    }
  };

  acceptClient = async () => {
    let data = {
      clientId: this.state.clientId,
      requestId: this.state.requestId
    }
    // let res = await ApiCall("acceptClientQuote", data);
    let res = await ApiCall("acceptClientQuotev3", data);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      toast.success(AlertMessage.MESSAGE.JOB.QUOTE_ACCEPTED_BY_CLIENT);
      setTimeout(
        function () {
          return history.push("/adminViewAllJobs")
        }
          .bind(this),
        1000
      );
    }
  }

  filterModal = () => {
    this.openFilterModal();
  };
  // .............filter modal function...................
  openFilterModal = () => {
    document.getElementById("backdrop").style.display = "block";
    document.getElementById("filter-model").style.display = "block";
    document.getElementById("filter-model").classList.add("show");
  };

  closeFilterModal = () => {
    document.getElementById("backdrop").style.display = "none";
    document.getElementById("filter-model").style.display = "none";
    document.getElementById("filter-model").classList.remove("show");
  };

  onDownloadMisc = (pos) => {
    window.open(
      IMAGE_PATH_ONLY + this.state.mislleniousExpenses[pos].incidentals,
      "_blank"
    );
  };

  changeCompSumpRadioArrivalInterpreter = (e) => {
    // consoleLog("value radio::", e.target.value)
  };

  approvePayable = async (id, status) => {
    let obj = {
      id: id,
      status: status,
      reason: this.state.rejectReason
    };
    let res = await ApiCall("changeStatusAccountPayable", obj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      this.closeRejectModal();
      if (status === "1") {
        toast.success("Verified successfully");
      } else {
        toast.success("Rejected successfully");
      }
      let apidata = {
        requestId: this.state.requestId,
      };

      let payable = [],
        receivable = [];

      let payableRes = await ApiCall(
        "fetchAccountPayableInterpretation",
        apidata
      );
      if (
        payableRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        payableRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        let payload = Decoder.decode(payableRes.data.payload);
        // consoleLog("Payable List:::", payload.data);
        if (payload.data.details.length > 0) {
          payable = payload.data.details;
        }
      }

      let receivableRes = await ApiCall(
        "fetchAccountReceivabaleInterpretation",
        apidata
      );
      if (
        receivableRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        receivableRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        let payload = Decoder.decode(receivableRes.data.payload);
        // consoleLog("Receivable List:::", payload.data);
        if (payload.data.details.length > 0) {
          receivable = payload.data.details;
        }
      }

      this.setState({
        payable: payable,
        receivable: receivable,
      });
    }
  };

  onArrivalTimeCheck = (id) => {
    if (id === "1") {
      this.setState({
        arrivalTimeCheck: true,
      });
    } else {
      this.setState({
        arrivalTimeCheck: false,
      });
    }
  };

  onStartTimeCheck = (id) => {
    if (id === "1") {
      this.setState({
        startTimeCheck: true,
      });
    } else {
      this.setState({
        startTimeCheck: false,
      });
    }
  };

  onEndTimeCheck = (id) => {
    if (id === "1") {
      this.setState({
        endTimeCheck: true,
      });
    } else {
      this.setState({
        endTimeCheck: false,
      });
    }
  };

  ratingChangedInterpreter = (value) => {
    let obj = this.state.completeSummary;
    obj.clientRatings = value;
    this.setState({
      completeSummary: obj,
    });
  };

  _onSameInterpreterCheck = (id) => {
    let obj = this.state.completeSummary;
    if (id === "1") {
      obj.isPrefVendor = true;
    } else {
      obj.isPrefVendor = false;
    }
    this.setState({
      completeSummary: obj,
    });
  };

  sameInterpreterReasonChange = (e) => {
    let obj = this.state.completeSummary;
    obj.prefferedReason = e.target.value;
    this.setState({
      completeSummary: obj,
    });
  };

  _onClientFollowUpCheck = (id) => {
    let obj = this.state.completeSummary;
    if (id === "1") {
      obj.followUp = "YES";
    } else {
      obj.followUp = "NO";
    }
    this.setState({
      completeSummary: obj,
    });
  };

  joinVideo = (appointmentType, jobId) => {
    // consoleLog("AppointmentType: ", appointmentType);
    // consoleLog("Job Type::", jobId);
    localStorage.setItem("All_job_data", JSON.stringify(this.state.allJobDetails));
    window.open(LOCAL_HOST + `/servicePage/${appointmentType}/${jobId}`);
  }

  ////// sample 



  handleOpenShareModal = () => {
    this.setState({
      showShare: true
    });
    // let str = "skhtest123@gmail.com",
    //   arr = [];
    // for (let i = 0; i < 50; i++) {
    //   arr.push(str)
    // };
    // this.setState({
    //   allEmailArr: arr
    // })
  }

  handleCloseShareModal = () => {
    this.setState({
      showShare: false
    })
  }

  deleteEmail = (pos) => {
    let arr = this.state.allEmailArr,
      brr = [];
    arr.map((data, i) => {
      if (i != pos) {
        brr.push(data)
      }
    });
    // console.log("Length>>", brr.length)
    this.setState({
      allEmailArr: brr
    })
  }

  onChangeEmail = (e) => {

    if (e.charCode == 13 || e.charCode == 32) {
      // alert('Enter... (KeyPress, use charCode)');
      // if(emailValidator(e.target.value)){

      // }
      console.log("ASD>>", emailValidator(e.target.value))
      let arr = this.state.allEmailArr;
      arr.push(e.target.value);
      this.setState({
        allEmailArr: arr,
        shareEmailText: ''
      })
    } else {
      this.setState({
        shareEmailText: e.target.value
      })
    }
  }

  changeEmailText = (e) => {
    let val = e.target.value;
    let temp = val.split(',');
    if (temp.length > 1) {
      let arr = this.state.allEmailArr;
      for (let i = 0; i < temp.length - 1; i++) {
        if (temp[i] != "") {
          arr.push(temp[i]);

        }
      }
      this.setState({
        allEmailArr: arr,
      })
    }
    this.setState({
      shareEmailText: temp[temp.length - 1]
    })
  }

  getVendorStatus = (item) => {
    let str = "";
    // if (item.isQuoteSent == 1) {
    if (item.status == 0) {
      str = 'Offered'
    } else if (item.status == 2) {
      str = 'Assigned'
    } else if (item.status == 5) {
      str = 'Unavailable'
    } else if (item.status == 7) {
      str = ''
    }
    // } else {
    //   str = ""
    // }
    return str;
  }

  getVendorStatusColor = (item) => {
    let str = "";
    // if (item.isQuoteSent == 1) {
    if (item.status == 0) {
      str = 'orange'
    } else if (item.status == 2) {
      str = 'green'
    } else if (item.status == 5 || item.status == 7) {
      str = 'red'
    }
    // } else {
    //   str = ""
    // }
    return str;
  }

  vendorAssigned = async (vid, bidIdrequest) => {
    this.closeAssignVendorModal();

    let bidId = bidIdrequest === null ? "0" : bidIdrequest;

    await this.loaderChange(true);
    this.setState({
      isAssignClick: true,
      isLoad: true
    })
    await this.loaderChange(false);

    let apiObj = {
      id: bidId,
      vendorId: vid,
      isAdmin: 1,
      requestId: this.state.requestId
    };

    // consoleLog("Vendor assigned api request >>>>>> ", apiObj)
    let res = await ApiCallVendor("setAvailableStatusByVendor", apiObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      toast.success(AlertMessage.MESSAGE.JOB.VENDOR_ASSIGNED_SUCCESS);
      setTimeout(
        function () {
          // return history.push("/adminViewAllJobs");
          this.load();
        }.bind(this),
        1000
      );
    } else {
      toast.error(res.message);
      this.setState({
        isLoad: false
      })
    }
  };


  vendorUnAssigned = async (vid, bidId) => {
    this.closeUnassignVendorModal();
    this.setState({
      isLoad: true
    })
    let apiObj = {
      id: bidId,
      vendorId: vid,
    };

    // consoleLog("Vendor Unassigned api request >>>>>> ", apiObj)
    let res = await ApiCallVendor("unassignInterpreterFromJob", apiObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      toast.success(AlertMessage.MESSAGE.JOB.VENDOR_UNASSIGNED_SUCCESS);
      setTimeout(
        function () {
          // return history.push("/adminViewAllJobs");
          this.load();
        }.bind(this),
        1000
      );
    } else {
      toast.error(res.message);
      this.setState({
        isLoad: false
      })
    }
  };

  addRushFee = () => {
    this.setState({
      isRushFee: true
    })
  }

  removeRushFee = () => {
    this.setState({
      isRushFee: false,
      totalAmount: Number(this.state.totalAmount - this.state.rushFee),
      rushFee: 0
    })
  }

  changeQuoteRemarks = (e) => {
    this.setState({
      quoteRemarks: e.target.value
    })
  }

  changeInternalNotes = (e) => {
    this.setState({
      notesText: e.target.value
    })
  }

  addNotes = async () => {
    let errorCounter = 0;
    if (this.state.notesText.trim() === "") {
      toast.error("Please input some text");
      errorCounter++;
      return false;
    }

    if (errorCounter === 0) {
      let apiObj = {
        requestId: this.state.requestId,
        notes: this.state.notesText,
      };
      // consoleLog("ADD NOTES REQ >>> ", apiObj)
      let res = await ApiCall("addInternalNotes", apiObj);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        this.fetchNotes(this.state.requestId);
        toast.success(AlertMessage.MESSAGE.NOTES.ADD_SUCCESS);
        this.setState({
          notesText: ""
        })
      }
    }
  }

  fetchNotes = async (id) => {
    let apiObj = {
      requestId: id
    };
    // consoleLog("FETCH NOTES REQ >>> ", apiObj)
    let res = await ApiCall("fetchInternalNotes", apiObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      // consoleLog("FETCH NOTES Job", payload.data);
      this.setState({
        allJobNotes: payload.data.notesData
      })
    }
  }

  fetchChatParticipants = async (id) => {
    let apiObj = {
      jobId: id
    };
    // let res = await ApiCall("getChatParticipantList", apiObj);
    let res = await ApiCall("getSpecialChatParticipantList", apiObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let arr = [];
      let payload = Decoder.decode(res.data.payload);
      consoleLog("JOB PARTICIANT LIST >>>> ", payload.data)
      payload.data.participants.map((pp) => {
        arr.push({
          label: pp.name + " ( " + pp.userId + " - " + this.getUserType(pp.userTypeId) + " )",
          name: pp.name,
          value: pp.userId,
          userTypeId: pp.userTypeId,
          id: pp.id
        });
      })


      if (payload.data.staffList) {
        payload.data.staffList.map((st) => {
          arr.push({
            label: st.name + " (" + st.userId + " - Staff)",
            value: st.userId,
            userTypeId: st.userTypeId,
            id: st.id
          });
        });
      }


      this.setState({
        chatParticipants: arr
      })
    }
  }

  getUserType = (id) => {
    let str = "";
    if (id == 3) {
      str = "Client";
    } else if (id == 4) {
      str = "vendor";
    } else if (id == 7) {
      str = "Client Supervisor";
    } else {
      str = "Client Requester"
    }

    return str;
  }

  openParticipantModal = () => {
    this.setState({
      addParticipantShow: true
    })
  }

  closeParticipantModal = () => {
    this.setState({
      addParticipantShow: false,
      selectedChatParticipants: []
    })
  }

  onSelectChatParticipants = (value) => {
    this.setState({
      selectedChatParticipants: value
    })
  }

  changeChatGroupName = (e) => {
    this.setState({
      chatGroupName: e.target.value
    })
  }

  submitParticipants = async () => {
    let errorCounter = 0;
    if (this.state.chatGroupName === "") {
      errorCounter++;
      toast.error(AlertMessage.MESSAGE.CHAT.BLANK_GROUP_NAME);
      return false;
    } else if (this.state.selectedChatParticipants.length === 0) {
      errorCounter++;
      toast.error(AlertMessage.MESSAGE.CHAT.BLANK_PARTICIPANTS);
      return false;
    }

    if (errorCounter === 0) {
      let req = {
        groupName: this.state.chatGroupName,
        participants: this.state.selectedChatParticipants,
        jobId: this.state.jobId,
        requestId: this.state.requestId
      }

      let res = await ApiCall("submitChatJoinRequest", req);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        this.fetchChatGroup(this.state.requestId)
        this.closeParticipantModal();
        toast.success("Group initiated successfully");
        this.setState({
          chatGroupName: "",
          selectedChatParticipants: []
        })
      } else {
        toast.error("Some Error Occured !!!");
      }
    }
  }

  /* Single chat initiate function start */

  onSelectChatParticipantsForSingle = (value) => {
    let arr = [];
    arr.push(value);
    this.setState({
      selectedChatParticipants: arr
    })
  }

  submitParticipantsForSingle = async () => {
    let errorCounter = 0;
    if (this.state.selectedChatParticipants.length === 0) {
      errorCounter++;
      toast.error(AlertMessage.MESSAGE.CHAT.BLANK_PARTICIPANTS);
      return false;
    }

    if (errorCounter === 0) {
      let req = {
        groupName: '',
        participants: this.state.selectedChatParticipants,
        jobId: this.state.jobId,
        requestId: this.state.requestId
      }

      let res = await ApiCall("singleJobChatInitiate", req);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        this.fetchChatGroup(this.state.requestId)
        this.closeParticipantModal();
        toast.success("Chat initiated successfully");
        this.setState({
          chatGroupName: "",
          selectedChatParticipants: []
        })
      } else {
        toast.error("Some Error Occured !!!");
      }
    }
  }
  /* Single chat initiate function End */

  changeChatGroup = (value) => {
    consoleLog("Change Chat group >>> ", value)
    let data = localStorage.getItem("AuthToken");
    let authUser = Decoder.decode(data);
    // consoleLog("Group Value >>> ", value.participantId);
    let temp = [];
    for (let i = 0; i < value.participantId.length; i++) {
      if (authUser.data.userid !== value.participantId[i])
        temp.push({
          label: value.participantName[i],
          value: value.participantId[i],
          actualId: value.participantActulaId[i]
        })
    }
    // consoleLog("Temp Arr >>> ", temp)


    // let arr = [];

    // consoleLog("Group Chat Participants >>> ", temp)
    // this.state.chatParticipants.map((pp) => {
    //   if (temp.length > 0) {
    //     let found = false;
    //     for (let gp of temp) {
    //       if (pp.id == gp.actualId) {
    //         found = true;
    //         break;
    //       }
    //     }
    //     if (found === false) {
    //       consoleLog("PArtners >>> ", pp)
    //       arr.push({
    //         label: pp.label,
    //         name: pp.name,
    //         value: pp.value,
    //         userTypeId: pp.userTypeId,
    //         id: pp.id
    //       });
    //     }
    //   } else {
    //     arr.push({
    //       label: pp.label,
    //       name: pp.name,
    //       value: pp.value,
    //       userTypeId: pp.userTypeId,
    //       id: pp.id
    //     });
    //   }
    // })

    // Filter logic
    const excludedParticipants = this.state.allChatParticipants.filter(
      (participant) =>
        !value.participantId.includes(participant.value));

    // console.log("Filtered Participants: ", excludedParticipants);

    this.setState({
      selectedChatGroupValue: value,
      groupChatParticipant: temp,
      addGroupChatParticipant: excludedParticipants
      // chatParticipants: arr
    })
    this.showChatPage(value)
  }

  fetchChatGroup = async (id) => {
    let apiObj = {
      jobId: id
    };

    let res = await ApiCall("fetchChatJoinRequest", apiObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      consoleLog("All Chat Group", payload.data);
      let arr = [];
      if (payload.data.length > 0) {
        payload.data.map((item, i) => {
          let obj = {
            label: item.groupName,
            value: item
          }
          arr.push(obj);
        })
      }
      // consoleLog("Fetch chat group arr >>> ", arr)

      await this.loaderChange(true);
      this.setState({
        chatGroup: arr
      })
      await this.loaderChange(false);
    }
  }

  showChatPage = (value) => {
    return value !== "" ? <>
      <div className="prticipants-area _fl">
        <div className="row">
          <div className="col-md-6">
            <h3><span style={{ cursor: "pointer" }} onClick={() => this.openChatParticipantModal()}>{Number(value.participantId.length) - 1} Participant</span></h3>
          </div>

          <div className="col-md-6">
            <a href="javascript:void(0)" className="add-part-btn" onClick={this.openAddGroupChatParticipantModal}>
              + Add Participants
            </a>
          </div>
        </div>
      </div>
      <MainChatPage jobId={value._id} jobAlphaId={this.state.jobId} />
    </> : <></>

  }

  openChatParticipantModal = () => {
    this.setState({
      chatParticipantModal: true
    })
  }

  closeChatParticipantModal = () => {
    this.setState({
      chatParticipantModal: false
    })
  }

  openAddGroupChatParticipantModal = () => {
    this.setState({
      addGrouoChatParticipantModal: true
    })
  }

  closeAddGroupChatParticipantModal = () => {
    this.setState({
      addGrouoChatParticipantModal: false
    })
  }

  removeParticipants = async (value) => {
    if (this.state.groupChatParticipant.length > 1) {
      let apiObj = {
        id: this.state.selectedChatGroupValue._id,
        participantName: value.label,
        participantId: value.value,
        participantActualId: value.actualId
      };
      // consoleLog("Remove Participants data req >>> ", apiObj)
      let res = await ApiCall("removeParticipantFromGroup", apiObj);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        let payload = Decoder.decode(res.data.payload);
        // this.fetchChatGroup(this.state.requestId);
        this.changeChatGroup(this.state.selectedChatGroupValue);
        this.closeChatParticipantModal();
        toast.success(AlertMessage.MESSAGE.CHAT.REMOVE_PARTICIPANTS);
      }
    } else {
      toast.error(AlertMessage.MESSAGE.CHAT.NOT_REMOVE_PARTICIPANTS)
    }

  }

  submitParticipantsInGroup = async () => {

    consoleLog("Add Group Particpant >>> ", this.state.selectedAddGroupChatParticipantValue);
    let participantId = [],
      participantName = [],
      participantActualId = [];

    if (this.state.selectedAddGroupChatParticipantValue.length > 0) {
      this.state.selectedAddGroupChatParticipantValue.map((data) => {
        participantId.push(data.value);
        participantActualId.push(data.id);
        participantName.push(data.name)
      })
    }

    let reqObj = {
      id: this.state.selectedChatGroupValue._id,
      participantName: participantName,
      participantId: participantId,
      participantActualId: participantActualId
    }

    // consoleLog("Add Participnat in exist group req >> ", reqObj)
    let res = await ApiCall("addParticipantFromGroup", reqObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {

      const filteredParticipants = this.state.addGroupChatParticipant.filter(
        (participant) =>
          !this.state.selectedAddGroupChatParticipantValue.some(
            (selected) => selected.value === participant.value
          )
      );

      this.closeAddGroupChatParticipantModal();
      await this.fetchChatGroup(this.state.requestId);
      toast.success(AlertMessage.MESSAGE.CHAT.ADD_PARTICIPANTS);
      await this.fetchChatParticipants(this.state.requestId);
      this.changeChatGroup(this.state.selectedChatGroupValue);
      this.setState({
        addGroupChatParticipant: filteredParticipants,
        selectedAddGroupChatParticipantValue: []
      })
    } else {
      toast.error(res.message)
    }


  }

  selectAddGroupParticipants = (value) => {
    this.setState({
      selectedAddGroupChatParticipantValue: value
    })
  }

  availableVendorsView = () => {
    if (this.state.quoteStatus === 0 ||
      this.state.quoteStatus === 1 ||
      this.state.quoteStatus === 2 ||
      this.state.quoteStatus === 3 ||
      this.state.quoteStatus === 4 ||
      this.state.quoteStatus === 5 ||
      this.state.quoteStatus === 6 ||
      this.state.quoteStatus === 7 ||
      this.state.quoteStatus === 8) {
      return true;
    } else {
      return false;
    }
  }

  quoteView = () => {
    if (this.state.quoteStatus < 9) {
      return true;
    } else {
      return false;
    }
  }


  vedorOfferedView = () => {
    if (this.state.quoteStatus === 0 ||
      this.state.quoteStatus === 1 ||
      this.state.quoteStatus === 2 ||
      this.state.quoteStatus === 8) {
      return true;
    } else {
      return false;
    }
  }

  // distanceFromChange = (event) => {
  //   let newValue = event.target.value.replace(/[^0-9.]/g, ''); // Replace non-numeric characters except decimal point
  //   let decimalCount = newValue.split('.').length - 1;
  //   if (decimalCount <= 1) {
  //     this.setState({
  //       distanceFrom: newValue
  //     })
  //   }
  // };

  distanceToChange = (event) => {
    let newValue = event.target.value.replace(/[^0-9.]/g, ''); // Replace non-numeric characters except decimal point
    let decimalCount = newValue.split('.').length - 1;
    if (decimalCount <= 1) {
      this.setState({
        distanceTo: newValue
      })
    }
  };

  // relationshipFromChange = (event) => {
  //   let newValue = event.target.value.replace(/[^0-9.]/g, ''); // Replace non-numeric characters except decimal point
  //   let decimalCount = newValue.split('.').length - 1;
  //   if (decimalCount <= 1) {
  //     this.setState({
  //       relationshipFrom: newValue
  //     })
  //   }
  // };

  relationshipToClientChange = (event) => {
    let newValue = event.target.value.replace(/[^0-9.]/g, ''); // Replace non-numeric characters except decimal point
    let decimalCount = newValue.split('.').length - 1;
    if (decimalCount <= 1) {
      this.setState({
        relationshipToClient: newValue
      })
    }
  };

  relationshipToLeiChange = (event) => {
    let newValue = event.target.value.replace(/[^0-9.]/g, ''); // Replace non-numeric characters except decimal point
    let decimalCount = newValue.split('.').length - 1;
    if (decimalCount <= 1) {
      this.setState({
        relationshipToLei: newValue
      })
    }
  };

  // costFromChange = (event) => {
  //   let newValue = event.target.value.replace(/[^0-9.]/g, ''); // Replace non-numeric characters except decimal point
  //   let decimalCount = newValue.split('.').length - 1;
  //   if (decimalCount <= 1) {
  //     this.setState({
  //       costFrom: newValue
  //     })
  //   }
  // };

  costToChange = (event) => {
    let newValue = event.target.value.replace(/[^0-9.]/g, ''); // Replace non-numeric characters except decimal point
    let decimalCount = newValue.split('.').length - 1;
    if (decimalCount <= 1) {
      this.setState({
        costTo: newValue
      })
    }
  };

  filterVendorChange = (event) => {
    let newValue = event.target.value;
    this.setState({
      filterVendorName: newValue
    })
  };

  filterRatingChange = (value) => {
    this.setState({
      filterRating: value.value,
    });
  };

  filterWeekChange = (value) => {
    this.setState({
      filterWeek: value.value,
    });
  };

  // filterDistanceChange = (value) => {
  //   this.setState({
  //     selectedMileage: value,
  //     distanceFrom: value.value
  //   });
  // };

  // filterRelationshipChange = (value) => {
  //   this.setState({
  //     relationshipFrom: value.value,
  //     selectedRelationship: value
  //   });
  // };

  // filterCostChange = (value) => {
  //   this.setState({
  //     costFrom: value.value,
  //     selectedTotalCost: value
  //   });
  // };

  filterGenderChange = (value) => {
    this.setState({
      selectedGender: value,
      filterVendorGender: value.value
    });
  };



  apply_vendoroff_filter = () => {
    // let data = {
    //   requestId: this.state.requestId,
    //   targetLanguageId: this.state.targetLanguage,
    //   rating: this.state.filterRating,
    //   weekIndex: this.state.filterWeek,
    //   distanceFrom: this.state.distanceFrom,
    //   distanceTo: this.state.distanceTo,
    //   relationshipFrom: this.state.relationshipFrom,
    //   relationshipTo: this.state.relationshipToClient,
    //   costFrom: this.state.costFrom,
    //   costTo: this.state.costTo,
    //   gender: this.state.filterVendorGender,
    //   cost : this.state.costTo,
    //   distance : this.state.distanceTo,
    // }

    let data = {
      requestId: this.state.requestId,
      rating: this.state.filterRating,
      relationship: this.state.relationshipToClient,
      relationshipLei: this.state.relationshipToLei,
      gender: this.state.filterVendorGender,
      cost: this.state.costTo,
      distance: this.state.distanceTo,
      name: this.state.filterVendorName,
      sort: this.state.filterVendorSort,
      sortBy: this.state.filterVendorSortBy
    }

    consoleLog("apply vendor offered filter request >>>>>>>>>> ", data);
    this.getAvailableVendors(data);
  }

  resetVendorOffFilter = () => {
    this.setState({
      selectedFilterRating: {},
      filterRating: "",
      selectedFilterWeek: {},
      filterWeek: "",
      distanceFrom: "",
      distanceTo: "",
      relationshipFrom: "",
      relationshipToClient: "",
      relationshipToLei: "",
      costFrom: "",
      costTo: "",
      selectedMileage: {},
      selectedRelationship: {},
      selectedGender: {},
      selectedTotalCost: {},
      filterVendorGender: "",
      filterVendorName: "",
      selectedVendorSortingData: {},
      filterVendorSort: "",
      filterVendorSortBy: ""
    });

    // let data = {
    //   requestId: this.state.requestId,
    //   targetLanguageId: this.state.targetLanguage,
    //   rating: "",
    //   weekIndex: "",
    //   distanceFrom: "",
    //   distanceTo: "",
    //   relationshipFrom: "",
    //   relationshipTo: "",
    //   costFrom: "",
    //   costTo: "",
    //   gender: ""
    // }


    let data = {
      requestId: this.state.requestId,
      rating: "",
      relationship: "",
      relationshipLei: "",
      gender: "",
      cost: "",
      distance: "",
      name: "",
      short: "",
      shortBy: ""
    }

    // consoleLog("apply vendor offered filter request >>>>>>>>>> ", data);
    this.getAvailableVendors(data);
  }

  goToClientProf = () => {
    let data = {
      userId: this.state.clientAlphaId
    }
    this.props.history.push({
      pathname: "/adminClientEdit",
      state: data,
    });
  }

  fetchAuthorisedQuoteAcceptPerson = async (id) => {
    let apiObj = {
      requestId: id
    };

    let res = await ApiCall("getListOfAuthorizedPersonForQuoteAccept", apiObj);
    try {
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        let payload = Decoder.decode(res.data.payload);
        consoleLog("All Authorized person >>>> ", payload.data);
        if (payload.data.authUserList.length > 0) {
          let arr = [];
          for (let i = 0; i < payload.data.authUserList.length; i++) {
            if (payload.data.authUserList[i].userTypeId == 6) {
              arr.push(payload.data.authUserList[i]);
              break;
            } else if (payload.data.authUserList[i].userTypeId == 7) {
              arr.push(payload.data.authUserList[i]);
              break;
            } else {
              arr.push(payload.data.authUserList[i]);
            }
          }
          this.setState({
            allAuthUserListForQuote: arr
          })
        } else {
          this.setState({
            allAuthUserListForQuote: []
          })
        }
        // let arr = [];
        // if (payload.data.length > 0) {
        //   payload.data.map((item, i) => {
        //     let obj = {
        //       label: item.jobId + " - " + item.groupName,
        //       value: item
        //     }
        //     arr.push(obj)

        //   })
        // }
        // this.setState({
        //   chatGroup: arr
        // })
      } else {
        toast.error(res.message);
      }
    } catch (e) {
      console.log(e);
    }
  }

  openViewNoteModal = (data) => {
    this.setState({
      updateBillId: data.id,
      viewNote: data.description,
      updatedAmount: data.totalAmount,
      billQty: data.quantity,
      billUnitPrice: data.unitPrice,
      viewNoteModal: true
    })
  }

  closeViewNoteModal = () => {
    this.setState({
      viewNote: "",
      updatedAmount: "",
      billQty: "",
      billUnitPrice: "",
      billRemarks: "",
      viewNoteModal: false,
      updateDetails: false
    })
  }

  openRejectModal = (data) => {
    this.setState({
      selectedRejectData: data,
      rejectModal: true
    })
  }

  closeRejectModal = () => {
    this.setState({
      selectedRejectData: {},
      rejectReason: "",
      rejectModal: false
    })
  }

  rejectReasonChange = (e) => {
    this.setState({
      rejectReason: e.target.value
    })
  }

  submitReject = () => {
    this.approvePayable(this.state.selectedRejectData.id, "3")
  }

  openNeedAttentionModal = () => {
    this.setState({
      needAttentionModal: true
    })
  }

  closeNeedAttentionModal = async () => {
    await this.loaderChange(true);
    this.setState({
      needAttentionModal: false,
      updateNeedAttention: this.state.isNeedAttention
    })
    await this.loaderChange(false);
  }

  changeUpdateNeedAttention = async (e) => {
    // console.log("Need Attention Value >>> ", e.target.value);
    if (e.target.value === "1") {
      await this.loaderChange(true);
      this.setState({
        updateNeedAttention: true
      })
      await this.loaderChange(false);
    } else {
      await this.loaderChange(true);
      this.setState({
        updateNeedAttention: false
      })
      await this.loaderChange(false);
    }

  }

  onSubmitNeedAttentionUpdate = async () => {
    let req = {
      requestId: this.state.requestId,
      updateNeedAttention: this.state.updateNeedAttention ? "1" : "0"
    }

    this.setState({
      isBtnSubmit: true
    });
    let res = await ApiCall("updateNeedAttentionStatus", req);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      this.closeNeedAttentionModal();
      this.setState({
        isBtnSubmit: false
      });
      toast.success("Need Attention Status Updated Successfully.")
      this.load()
    } else {
      toast.error(res.message);
    }
  }

  // changeUpdateAmount = (value) => {
  //   value = value.replace(/[^0-9.]/g, '');
  //   this.setState({
  //     updatedAmount: value
  //   })
  // }

  changeViewDescription = (e) => {
    this.setState({
      viewNote: e.target.value
    })
  }

  changeUpdateDetails = () => {
    this.setState({
      updateDetails: true
    })
  }

  changeBillQty = async (e) => {

    const newValue = e.target.value;
    let amt = 0;
    const regex = /^[+-]?\d*\.?\d{0,3}$/;
    if (regex.test(newValue)) {
      if (newValue === "" || newValue === "0") {
        await this.loaderChange(true);
        this.setState({
          billQty: 0,
          updatedAmount: 0
        })
        await this.loaderChange(false);
      } else {
        const parsedValue = parseFloat(newValue);
        if (!isNaN(parsedValue)) {
          if (Number(this.state.billUnitPrice) > 0) {
            amt = newValue * Number(this.state.billUnitPrice);
          } else {
            amt = 0;
          }
          await this.loaderChange(true);
          this.setState({
            billQty: parsedValue,
            updatedAmount: amt
          })
          await this.loaderChange(false);
        }
      }
    }
  }

  changeUnitPrice = async (e) => {

    const newValue = e.target.value;
    let amt = 0;
    const regex = /^[+-]?\d*\.?\d{0,3}$/;
    if (regex.test(newValue)) {
      if (newValue === "" || newValue === "0") {
        await this.loaderChange(true);
        this.setState({
          billUnitPrice: 0,
          updatedAmount: 0
        })
        await this.loaderChange(false);
      } else {
        const parsedValue = parseFloat(newValue);
        if (!isNaN(parsedValue)) {
          if (Number(this.state.billQty) > 0) {
            amt = newValue * Number(this.state.billQty);
          } else {
            amt = 0;
          }
          await this.loaderChange(true);
          this.setState({
            billUnitPrice: parsedValue,
            updatedAmount: amt
          })
          await this.loaderChange(false);
        }
      }
    }
  }

  changeBillRemarks = (e) => {
    this.setState({
      billRemarks: e.target.value
    })
  }

  updateBillDetails = async () => {
    let errorCount = 0;
    if (this.state.billQty == 0 || this.state.billQty === "") {
      toast.error("Quantity cannot be 0 or blank");
      errorCount++;
    } else if (this.state.billUnitPrice == 0 || this.state.billUnitPrice === "") {
      toast.error("Unit price cannot be 0 or blank");
      errorCount++;
    } else if (this.state.updatedAmount == 0 || this.state.updatedAmount === "") {
      toast.error("Total amount cannot be 0 or blank");
      errorCount++;
    } else if (this.state.billRemarks === "") {
      toast.error("Please enter remarks");
      errorCount++;
    }
    if (errorCount == 0) {
      let reqObj = {
        id: this.state.updateBillId,
        totalAmount: this.state.updatedAmount,
        quantity: this.state.billQty,
        unitPrice: this.state.billUnitPrice,
        description: this.state.viewNote,
        remarks: this.state.billRemarks
      }

      // consoleLog("Updated BillDetails >>>> ", reqObj);
      let res = await ApiCall("updateExistingBill", reqObj);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        toast.success("Bill Updated Successfully");
        this.closeViewNoteModal();
        this.load();
      } else {
        toast.error(res.message)
      }
    }
  }

  showEmailMod = async () => {
    // await this.loaderChange(true);
    this.setState({
      sentEmailModal: true
    })
    await this.loaderChange(false);
    //return (<EmailModal jobId={this.state.numJobId} closeMailModal={() => this.closeEmailSendModal()} />)
  }
  openEmailSendModal = async () => {

    await this.loaderChange(true);
    this.setState({
      sentEmailModal: true
    })
    await this.loaderChange(false);
  }

  closeEmailSendModal = async () => {
    await this.loaderChange(true);
    this.fetchEmailSmsLog(this.state.numJobId);
    this.setState({
      sentEmailModal: false
    })
    await this.loaderChange(false);
  }

  openSmsModal = async () => {
    await this.loaderChange(true);
    this.setState({
      sentSmsModal: true
    })
    await this.loaderChange(false);
  }

  closeSmsModal = async () => {
    await this.loaderChange(true);
    this.fetchEmailSmsLog(this.state.numJobId);
    this.setState({
      sentSmsModal: false
    })
    await this.loaderChange(false);
  }

  openViewEmailModal = async (data) => {
    await this.loaderChange(true);
    this.setState({
      viewEmailModal: true,
      viewEmailModalData: data
    });
    await this.loaderChange(false);
  }

  closeViewEmailModal = async () => {
    await this.loaderChange(true);
    this.setState({
      viewEmailModal: false,
      viewEmailModalData: {}
    });
    await this.loaderChange(false);
  }

  openVendorRejectReasonModal = async (data) => {
    await this.loaderChange(true);
    this.setState({
      vendorRejectReasonModal: true,
      vendorRejectReasonData: data
    });
    await this.loaderChange(false);
  }

  closeVendorRejectReasoModal = async () => {
    await this.loaderChange(true);
    this.setState({
      vendorRejectReasonModal: false,
      vendorRejectReasonData: ""
    });
    await this.loaderChange(false);
  }


  isVendorSendOffer = () => {
    let counter = 0;
    this.state.listData.map((data) => {
      if (Number(data.sent) === 1) {
        counter++;
      }
    })

    if (this.state.listData.length === counter) {
      return true;
    } else {
      return false;
    }
  }

  fetchGroupChatParticipants = async (id) => {
    let apiObj = {
      jobId: id
    };
    let res = await ApiCall("getChatParticipantList", apiObj);
    // let res = await ApiCall("getSpecialChatParticipantList", apiObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let arr = [];
      let payload = Decoder.decode(res.data.payload);
      payload.data.participants.map((pp) => {
        arr.push({
          label: pp.name + " ( " + pp.userId + " - " + this.getUserType(pp.userTypeId) + " )",
          name: pp.name,
          value: pp.userId,
          userTypeId: pp.userTypeId,
          id: pp.id
        });
      })


      if (payload.data.staffList) {
        payload.data.staffList.map((st) => {
          arr.push({
            label: st.name + " (" + st.userId + " - Staff)",
            name: st.name,
            value: st.userId,
            userTypeId: st.userTypeId,
            id: st.id
          });
        });
      }

      consoleLog("JOB group PARTICIANT LIST >>>> ", arr)

      this.setState({
        allChatParticipants: arr,
        addGroupChatParticipant: arr
      })
    }
  }

  sortingVendoDataChange = (value) => {
    // consoleLog("Sorting Vendor By >>> ", value)
    this.setState({
      selectedVendorSortingData: value,
      filterVendorSort: value.sort,
      filterVendorSortBy: value.shortBy
    });
  };

  openUnassignVendorModal = (vid, bid) => {
    let obj = {
      vendorId: vid,
      bidRequestId: bid
    }
    this.setState({
      vendorInfo: obj,
      unassignVendorModal: true
    })
  }

  closeUnassignVendorModal = () => {
    this.setState({
      vendorInfo: {},
      unassignVendorModal: false
    })
  }

  openAssignVendorModal = (vid, bid) => {
    let obj = {
      vendorId: vid,
      bidRequestId: bid
    }
    this.setState({
      vendorInfo: obj,
      assignVendorModal: true
    })
  }

  closeAssignVendorModal = () => {
    this.setState({
      vendorInfo: {},
      assignVendorModal: false
    })
  }


  render() {
    // const open = Boolean(this.state.anchorEl); //used in MenuButton open
    // const open1 = Boolean(this.state.anchorEl1);
    return (
      <React.Fragment>
        {/* <ToastContainer hideProgressBar theme="colored" /> */}
        <div class="component-wrapper" hidden={!this.state.isLoad}>
          <ReactLoader />
        </div>

        <div className="component-wrapper" hidden={this.state.isLoad}>
          {/* <ReactLoader /> */}
          <div
            className="vn_frm"
            style={{ color: "grey", paddingBottom: "2%", paddingTop: "5%" }}
          >
            {" "}
            <Link to="/adminDashboard">Dashboard</Link> /{" "}
            {this.state.pathCheck == true ? (
              <Link to="/adminMainBillUnderVerification">Bills Under Verification</Link>
            ) : (
              this.props.location.from == 1 ?
                <Link to="/adminViewAllJobs">Interpretation Jobs</Link> :
                <Link to="/adminRemoteInterpreationJobList">Remote Interpretation History</Link>
            )}{" "}
            / Job Details
          </div>
          <div className="job-details-tab jobdltste _fl sdw">
            <ul className="nav nav-tabs" style={{ justifyContent: "left" }}>
              <li className="nav-item">
                {" "}
                <a
                  className={this.state.showTab === "job" ? "nav-link active" : "nav-link"}
                  data-toggle="tab"
                  href="#jobdetails"
                >
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.JOB_DETAILS} />
                    </figure>{" "}
                    Job Details
                  </div>
                </a>{" "}
              </li>
              <React.Fragment>
                <li className="nav-item">
                  {" "}
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#editjobdetails"
                  >
                    <div className="taber">
                      <figure>
                        <img src={ImageName.IMAGE_NAME.JOB_DETAILS} />
                      </figure>{" "}
                      Edit Job Details
                    </div>
                  </a>{" "}
                </li>
              </React.Fragment>
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#clientdetails">
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.TAB_USER_ICON} />
                    </figure>
                    Client Details
                  </div>
                </a>{" "}
              </li>
              {/* {this.quoteView() ? */}
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#sendqute">
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.TABBAR} />
                    </figure>
                    {!this.state.isView ? "Send Quote" : "View Quote"}
                  </div>
                </a>{" "}
              </li>
              {/* : <></>} */}
              {this.availableVendorsView() ? (
                <li className="nav-item">
                  {" "}
                  <a className="nav-link" data-toggle="tab" href="#vendoroff">
                    <div className="taber">
                      <figure>
                        <img src={ImageName.IMAGE_NAME.TAB_USER_ICON} />
                      </figure>
                      Available Vendors
                    </div>
                  </a>{" "}
                </li>
              ) : (
                <React.Fragment></React.Fragment>
              )}
              {this.state.quoteStatus === 10 &&
                this.state.completeSummary.postJobStat > 0 ? (
                <>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#comp_sum">
                      <div className="taber">
                        <figure>
                          <img src={ImageName.IMAGE_NAME.COMP_SUM} />
                        </figure>
                        Completion Summary
                      </div>
                    </a>
                  </li>
                </>
              ) : (
                <></>
              )}
              {/* {this.state.quoteStatus === 10 &&
                this.state.completeSummary.postJobStat > 1 ? ( */}
              {this.state.quoteStatus === 10 || this.state.quoteStatus === 11 ? (
                <>
                  <li className="nav-item">
                    <a
                      className={this.state.showTab === "bills" ? "nav-link active" : "nav-link"}
                      data-toggle="tab"
                      href="#payablerecavable"
                    >
                      <div className="taber">
                        <figure>
                          <img src={ImageName.IMAGE_NAME.MENU.INVOICE_DOLLER} />
                        </figure>
                        &nbsp;Payables & Receivables
                      </div>
                    </a>
                  </li>
                </>
              ) : (
                <></>
              )}
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#notetab">
                  <div className="taber">
                    <figure>
                      <img
                        src={ImageName.IMAGE_NAME.CHAT_ICON}
                        style={{ padding: "10px", width: "48px" }}
                      />
                    </figure>
                    Notes{" "}
                  </div>
                </a>{" "}
              </li>
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#chattab">
                  <div className="taber">
                    <figure>
                      <img
                        src={ImageName.IMAGE_NAME.CHAT_ICON}
                        style={{ padding: "10px", width: "48px" }}
                      />
                    </figure>
                    Chat{" "}
                  </div>
                </a>{" "}
              </li>
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#nofifications">
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.NOTIFICATION_ICON} />
                    </figure>
                    Notifications
                  </div>
                </a>{" "}
              </li>
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#audit">
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.DOCUMENTICON} />
                    </figure>
                    Audit History
                  </div>
                </a>{" "}
              </li>
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#emailsms">
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.CHARSMSICON} />
                    </figure>
                    Email & SMS
                  </div>
                </a>{" "}
              </li>
            </ul>

            <div className="tab-content">
              <div className={this.state.showTab === "job" ? "tab-pane  active" : "tab-pane"} id="jobdetails">
                {/* <button onClick={() => this.handleOpenShareModal()}>Open Share</button> */}
                <div className="job-section-tab">
                  <table
                    width="100%"
                    cellspacing="0"
                    cellpadding="0"
                    border="0"
                  >
                    <tbody>
                      <tr>
                        <td width="50%" align="left">
                          Job ID
                        </td>
                        <th width="50%" align="right">
                          {this.state.jobId}
                        </th>
                      </tr>

                      <tr>
                        <td width="50%" align="left">
                          Appointment Type
                        </td>
                        <th width="50%" align="right">
                          <div className="f2f-b">
                            {this.state.appointmentType}
                          </div>
                        </th>
                      </tr>

                      <tr>
                        <td width="50%" align="left">
                          Job Type
                        </td>
                        <th width="50%" align="right">
                          {this.state.jobType}
                        </th>
                      </tr>

                      <tr>
                        <td width="50%" align="left">
                          Language
                        </td>
                        <th width="50%" align="right">
                          {this.state.language}
                        </th>
                      </tr>
                      <tr>
                        <td width="50%" align="left">
                          Create Date
                        </td>
                        <th width="50%" align="right">
                          {this.state.jobCreateDate}
                        </th>
                      </tr>
                      <tr>
                        <td width="50%" align="left">
                          Date
                        </td>
                        <th width="50%" align="right">
                          {SetDateFormat(this.state.date)}{" "}
                          {this.state.scheduleTime}
                        </th>
                      </tr>

                      {this.state.appointmentType === "F2F" ?
                        <tr>
                          <td width="50%" align="left">
                            Service Location Address
                          </td>
                          <th width="50%" align="right">
                            {this.state.serviceAddress}
                          </th>
                        </tr> : <></>
                      }

                      <tr>
                        <td width="50%" align="left">
                          No. of Interpreters
                        </td>
                        <th width="50%" align="right">
                          {this.state.serviceProvider}
                        </th>
                      </tr>
                      {this.state.appointmentType === "F2F" ?
                        <tr>
                          <td width="50%" align="left">
                            Site Contact
                          </td>
                          <th width="50%" align="right">
                            {this.state.siteContant}
                          </th>
                        </tr> : <></>
                      }

                      <tr>
                        <td width="50%" align="left">
                          Client
                        </td>
                        <th width="50%" align="right" class="blueText">
                          <span style={{ cursor: "pointer" }} onClick={() => { this.goToClientProf() }}>{this.state.clientName}</span>
                        </th>
                      </tr>
                      <tr>
                        <td width="50%" align="left">
                          LEI
                        </td>
                        <th width="50%" align="right" >
                          <span style={{ cursor: "pointer" }} >{this.state.leiName}</span>
                        </th>
                      </tr>
                      {this.state.quoteStatus == 1 ?
                        <tr>
                          <td width="50%" align="left">
                            Authorized person for quote approve
                          </td>
                          <th width="50%" align="right" class="blueText">
                            {this.state.allAuthUserListForQuote.length > 0 ? <>
                              {this.state.allAuthUserListForQuote.map((usr, i) => <>
                                {usr.name + " ( " + usr.userTypeName + " ) "}<br />
                              </>)}
                            </> : <></>}
                          </th>
                        </tr> : <></>
                      }
                      <tr>
                        <td width="50%" align="left">
                          Notes For Interpreter
                        </td>
                        <th width="50%" align="right">
                          &nbsp;{this.state.notesByClient}
                        </th>
                      </tr>
                      {this.state.quoteStatus == 11 ?
                        <tr>
                          <td width="50%" align="left">
                            Job Cancelled Reason
                          </td>
                          <th width="50%" align="right">
                            &nbsp;{this.state.cancelledReason}
                          </th>
                        </tr> : <></>
                      }
                      {this.state.appointmentType !== "F2F" ? (
                        <React.Fragment>
                          {this.state.quoteStatus >= 10 && this.state.quoteStatus < 12 ? <></> :
                            <tr>
                              <td width="50%" align="left" class="blueText">
                                <span>7C Lingo</span> voice/video services
                              </td>
                              <th width="50%" align="right">
                                &nbsp;
                                {this.state.otherService === 1 ?
                                  <React.Fragment>
                                    {this.state.quoteStatus === 8 || this.state.quoteStatus === 9 ?
                                      <Button variant="contained" onClick={() => this.joinVideo(this.state.appointmentType, this.state.jobId)}>Join</Button>
                                      : "Not yet assigned to a interpreter"
                                    }
                                  </React.Fragment> : "NO"}
                              </th>
                            </tr>
                          }
                          {this.state.otherService === 0 ? (
                            <React.Fragment>
                              <tr>
                                <td width="50%" align="left">
                                  3rd party services
                                </td>
                                <th width="50%" align="right">
                                  &nbsp;{this.state.otherServiceData}
                                </th>
                              </tr>
                            </React.Fragment>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                      <tr>
                        <td width="50%" align="left">
                          {/* <p class="notes">
                            Notes From <span>7C Lingo</span>
                          </p> */}
                          Notes For <span style={{ color: "#005d83" }}>7C Lingo</span>
                        </td>
                        <th width="50%" align="right">
                          &nbsp;{this.state.notesBy7C}
                        </th>
                      </tr>
                      <tr>
                        <td width="50%" align="left">
                          Assigned Vendor
                        </td>
                        <th width="50%" align="right">
                          {this.state.assignedVendor}
                        </th>
                      </tr>
                      {Number(this.state.isScheduled) === 1 ? <>
                        <tr>
                          <td width="50%" align="left">
                            Job Preferences
                          </td>
                          <th width="50%" align="right">
                            {Object.keys(this.state.additionalJobInfo).length > 0 ? <>
                              {this.state.additionalJobInfo.type === '2' ? <><span className="job_prefrences"><p>{this.state.additionalJobInfo.name}</p><br /><p>Reschedule Date : {this.state.additionalJobInfo.value}</p></span></> : <><>{this.state.additionalJobInfo.name}</></>}
                            </> : <>N/A</>}
                          </th>
                        </tr>
                      </> : <></>}

                      <tr>
                        <td width="50%" align="left">
                          Need Attention
                        </td>
                        <th width="50%" align="right">
                          {this.state.isNeedAttention ? "YES" : "NO"} <FiEdit size={20} onClick={() => this.openNeedAttentionModal()} style={{ cursor: "pointer" }} />
                        </th>
                      </tr>
                    </tbody>
                  </table>

                  {this.state.progressLogData.length > 0 ? <>
                    <Stepper style={{ overflowX: "auto" }}
                      steps={this.state.progressLogData}
                      activeStep={this.state.progressLogData.length}
                      styleConfig={{ completedBgColor: "#00a0df" }}
                    />
                  </> : <></>}
                </div>

              </div>
              {/* ................for edit job details page................. */}
              <div className="tab-pane" id="editjobdetails">
                <div className="job-section-tab">
                  <EditJob mainData={this.state.reqId} />
                </div>
              </div>
              {/* ........................send quote...................... */}
              <div className="tab-pane" id="sendqute">
                <div className="job-section-tab">
                  <div className="view-quote-details-wrap">
                    <h3>view quote details</h3>
                    <p>
                      <span>Job ID</span>
                      {this.state.jobId}
                    </p>
                    {/* <p>
                      <span>Quote ID</span>
                      {this.state.quoteId}
                    </p> */}
                    {/* <p>
                      <span>Target Audience</span> {this.state.targetAdience}
                    </p> */}
                    <p>
                      <span>Location</span> {this.state.location}
                    </p>
                    <p>
                      <span>Appontment Type</span> {this.state.appointmentType}
                    </p>
                    <p>
                      <span>Date & Time</span>{" "}
                      {SetDateFormat(this.state.dateTime)}{" "}
                      {this.state.scheduleTime}
                    </p>
                    <p>
                      <span>Notes from Client</span>
                      {this.state.notesFromClient}
                    </p>
                    <p>
                      <span>Industry Type</span> {this.state.jobType}
                    </p>
                    <p>
                      <span>Language</span> {this.state.language}
                    </p>
                  </div>

                  <div className="add-more-field">
                    <div className="table-listing-app create">
                      <div className="creat_quot">
                        <h3>
                          {!this.state.isView ? "Create Quote" : "View Quote"}
                        </h3>
                        <div className="depr_table">
                          <div className="table-responsive_mb">
                            {this.state.quoteStatus < 9 ?
                              <div class="addmore_service text-right">
                                <a href="javascript:void(0)" onClick={() => this.addParticularField()}>
                                  <div style={{ marginTop: "8px" }}>
                                    Add Additional Field
                                  </div>
                                </a>
                              </div> : <></>
                            }

                            {this.state.quoteStatus < 9 && !this.state.isRushFee ?
                              <div class="addmore_service text-right mr_0 " >
                                <a href="javascript:void(0)" onClick={() => this.addRushFee()}>
                                  <div style={{ marginTop: "8px" }} >
                                    Add Rush Fee
                                  </div>
                                </a>
                              </div> : <></>
                            }

                            <table
                              width="100%"
                              cellspacing="0"
                              cellpadding="0"
                              border="0"
                            >
                              <tbody>
                                {this.state.appointmentType === "F2F" ? (
                                  <tr>
                                    <th width="50%" align="left">
                                      Delivery Type
                                    </th>
                                    <th width="50%" align="right">
                                      {this.state.deliveryType}
                                    </th>
                                  </tr>
                                ) : (
                                  <React.Fragment />
                                )}

                                {/* <tr>
                                  <td width="50%" align="left">
                                    No. of service provider
                                  </td>
                                  <th width="50%" align="right">
                                    {this.state.serviceProvider}
                                  </th>
                                </tr> */}

                                <tr>
                                  <td width="50%" align="left">
                                    Duration
                                  </td>
                                  <td width="50%" align="right">
                                    {this.state.duration}
                                  </td>
                                </tr>

                                <tr hidden={!this.state.isRushFee}>
                                  <td width="50%" align="left">
                                    Rush Fee
                                  </td>
                                  <td width="50%" align="right">
                                    <div className="row">
                                      <div className="col-md-6"></div>
                                      <div className="col-md-6">
                                        <div
                                          className="input-group"
                                          style={{ justifyContent: "end" }}
                                        >
                                          <div class="input-group-prepend">
                                            <span
                                              class="input-group-text dollar"
                                              id="basic-addon1"
                                            >
                                              $
                                            </span>
                                          </div>

                                          <input
                                            disabled={
                                              this.state.quoteStatus < 9
                                                ? false
                                                : true
                                            }
                                            className="inputfield flr"
                                            type="number"
                                            // step="any"
                                            value={this.state.rushFee}
                                            style={{ width: "75%" }}
                                            onChange={(e) =>
                                              this.onRushFeeChange(e)
                                            }
                                          />
                                          {this.state.quoteStatus < 9 ?
                                            <TiDelete
                                              size={40}
                                              style={{ cursor: 'pointer', color: "red", float: "right" }}
                                              onClick={() => this.removeRushFee()} /> :
                                            <></>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td width="50%" align="left">
                                    Interpretation Fee
                                  </td>
                                  <td width="50%" align="right">
                                    <div className="row">
                                      <div className="col-md-6"></div>
                                      <div className="col-md-6">
                                        <div
                                          className="input-group"
                                          style={{ justifyContent: "end" }}
                                        >
                                          <div class="input-group-prepend">
                                            <span
                                              class="input-group-text dollar"
                                              id="basic-addon1"
                                            >
                                              $
                                            </span>
                                          </div>

                                          <input
                                            disabled={
                                              this.state.quoteStatus < 9
                                                ? false
                                                : true
                                            }
                                            className="inputfield flr"
                                            type="number"
                                            value={this.state.interpretationFee}
                                            style={{ width: "75%" }}
                                            onChange={(e) =>
                                              this.onInterpretationFeeChange(e)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                {/* {this.state.bookingFee > 0 ? */}
                                <tr>
                                  <td width="50%" align="left">
                                    Booking Fee
                                  </td>
                                  <td width="50%" align="right">
                                    <div className="row">
                                      <div className="col-md-6"></div>
                                      <div className="col-md-6">
                                        <div
                                          className="input-group"
                                          style={{ justifyContent: "end" }}
                                        >
                                          <div class="input-group-prepend">
                                            <span
                                              class="input-group-text dollar"
                                              id="basic-addon1"
                                            >
                                              $
                                            </span>
                                          </div>

                                          <input
                                            disabled={
                                              this.state.quoteStatus < 9
                                                ? false
                                                : true
                                            }
                                            className="inputfield flr"
                                            type="number"
                                            value={this.state.bookingFee}
                                            style={{ width: "75%" }}
                                            onChange={(e) =>
                                              this.onBookingFeeChange(e)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                {/* : <></>} */}

                                {/* {this.state.nightFee > 0 ? */}
                                <tr>
                                  <td width="50%" align="left">
                                    Night Fee
                                  </td>
                                  <td width="50%" align="right">
                                    <div className="row">
                                      <div className="col-md-6"></div>
                                      <div className="col-md-6">
                                        <div
                                          className="input-group"
                                          style={{ justifyContent: "end" }}
                                        >
                                          <div class="input-group-prepend">
                                            <span
                                              class="input-group-text dollar"
                                              id="basic-addon1"
                                            >
                                              $
                                            </span>
                                          </div>

                                          <input
                                            disabled={
                                              this.state.quoteStatus < 9
                                                ? false
                                                : true
                                            }
                                            className="inputfield flr"
                                            type="number"
                                            value={this.state.nightFee}
                                            style={{ width: "75%" }}
                                            onChange={(e) =>
                                              this.onNightFeeChange(e)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                {/* : <></>} */}

                                {/* {this.state.weekendFee > 0 ? */}
                                <tr>
                                  <td width="50%" align="left">
                                    Weekend Fee
                                  </td>
                                  <td width="50%" align="right">
                                    <div className="row">
                                      <div className="col-md-6"></div>
                                      <div className="col-md-6">
                                        <div
                                          className="input-group"
                                          style={{ justifyContent: "end" }}
                                        >
                                          <div class="input-group-prepend">
                                            <span
                                              class="input-group-text dollar"
                                              id="basic-addon1"
                                            >
                                              $
                                            </span>
                                          </div>

                                          <input
                                            disabled={
                                              this.state.quoteStatus < 9
                                                ? false
                                                : true
                                            }
                                            className="inputfield flr"
                                            type="number"
                                            value={this.state.weekendFee}
                                            style={{ width: "75%" }}
                                            onChange={(e) =>
                                              this.onWeekendFeeChange(e)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                {/* : <></>} */}

                                {this.state.quoteStatus == 11 ?
                                  <tr>
                                    <td width="50%" align="left">
                                      Cancel Fee
                                    </td>
                                    <td width="50%" align="right">
                                      <div className="row">
                                        <div className="col-md-6"></div>
                                        <div className="col-md-6">
                                          <div
                                            className="input-group"
                                            style={{ justifyContent: "end" }}
                                          >
                                            <div class="input-group-prepend">
                                              <span
                                                class="input-group-text dollar"
                                                id="basic-addon1"
                                              >
                                                $
                                              </span>
                                            </div>

                                            <input
                                              disabled={
                                                this.state.quoteStatus < 9
                                                  ? false
                                                  : true
                                              }
                                              className="inputfield flr"
                                              type="number"
                                              value={this.state.cancelFee}
                                              style={{ width: "75%" }}
                                              onChange={(e) =>
                                                this.onCancelFeeChange(e)
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  : <></>}
                                {(this.state.particularsArr && this.state.particularsArr.length > 0) ? <React.Fragment>
                                  {this.state.particularsArr.map((item, key) => (
                                    <tr>
                                      <td width="50%" align="left">
                                        <input
                                          // disabled
                                          className="inputfield flr"
                                          type="text"
                                          placeholder="Enter Details"
                                          value={item.title}
                                          onChange={(e) =>
                                            this.onParticularChange(e, key)
                                          }
                                        />
                                      </td>
                                      <td width="50%" align="right">
                                        <div className="row">
                                          <div className="col-md-5"></div>
                                          <div className="col-md-6">
                                            <div
                                              className="input-group"
                                              style={{ justifyContent: "end" }}
                                            >
                                              <div class="input-group-prepend">
                                                <span
                                                  class="input-group-text dollar"
                                                  id="basic-addon1"
                                                >
                                                  $
                                                </span>
                                              </div>

                                              <input
                                                className="inputfield flr"
                                                type="number"
                                                placeholder="Enter Amount"
                                                value={item.amt}
                                                style={{ width: "75%" }}
                                                onChange={(e) =>
                                                  this.onParticularAmountChange(
                                                    e,
                                                    key
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          {this.state.quoteStatus < 9 ?
                                            <div className="col-md-1 delete-btn">
                                              <img
                                                src={ImageName.IMAGE_NAME.TRASH_BTN}
                                                onClick={() =>
                                                  this.onDeleteParticulars(key)
                                                }
                                                style={{ cursor: "pointer" }}
                                              />
                                            </div> : <></>
                                          }
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </React.Fragment> : <React.Fragment />}


                                <tr>
                                  <td width="50%" align="left">
                                    Remarks (In 500 characters)
                                  </td>
                                  <td width="50%" align="right">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <textarea
                                          rows="4"
                                          disabled={
                                            this.state.quoteStatus < 9
                                              ? false
                                              : true
                                          }
                                          className="remarks_textarea"
                                          type="text"
                                          value={this.state.quoteRemarks}
                                          onChange={(e) =>
                                            this.changeQuoteRemarks(e)
                                          }
                                        ></textarea>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                {this.state.quoteStatus == 7 ? <>
                                  <tr>
                                    <td
                                      width="50%"
                                      align="left"
                                    >
                                      Decline reason
                                    </td>
                                    <td width="50%" align="right">
                                      <p>{this.state.quoteDeclineReason}</p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      width="50%"
                                      align="left"
                                    >
                                      Description (if any)
                                    </td>
                                    <td width="50%" align="right">
                                      <p>{this.state.quoteDeclineDescription}</p>
                                    </td>
                                  </tr>
                                </> : <></>}
                                <tr>
                                  <td
                                    width="50%"
                                    align="left"
                                    style={{ color: "#5ea076" }}
                                  >
                                    <p className="total_quote_payable">
                                      Total Payable
                                    </p>
                                  </td>
                                  <td
                                    width="50%"
                                    align="right"
                                    style={{ color: "#5ea076" }}
                                  >
                                    <p className="total_quote_payable">
                                      $ {parseFloat(this.state.totalAmount)}
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="_button-style m30 _fl text-center">
                    <a
                      href="javascript:void(0)"
                      className="white-btn"
                      style={{ textDecoration: "none" }}
                      onClick={this.onBack}
                    >
                      Back
                    </a>
                    {this.state.quoteStatus < 9 ? (
                      <>
                        <a
                          href="javascript:void(0)"
                          className="blue-btn"
                          style={{ textDecoration: "none" }}
                          onClick={this.onUpdateQuote}
                        >
                          {this.state.quoteStatus == 7 ? "Resend Quote" :
                            "Send Quote"}
                        </a>
                        {/* {this.state.quoteStatus == 0 ? */}
                        <a
                          href="javascript:void(0)"
                          className="blue-btn"
                          style={{ textDecoration: "none" }}
                          onClick={this.onSaveQuote}
                        >
                          Save Quote
                        </a>
                        {/* : <></>
                        } */}
                      </>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    {this.state.quoteStatus < 9 ? <>
                      <a
                        href="javascript:void(0)"
                        className="green-btn"
                        style={{ textDecoration: "none", color: "#fff" }}
                        onClick={this.acceptClient}
                      >
                        Accept
                      </a><span style={{ color: "gray" }}>(On behalf of client)</span>
                      {/* <button type="button" class="btn btn-success" onClick={this.acceptClient}>Accept</button> <span style={{ color: "gray" }}>(On behalf of client)</span> */}
                    </> : <></>
                    }
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="clientdetails">
                <div className="job-section-tab">
                  <h3>Client Information</h3>
                  <div className="c-l-s _fl">
                    <div className="row">
                      <div className="col-md-4">
                        <h4>First Name</h4>
                        <p>{this.state.clientFirstName}</p>
                      </div>
                      <div className="col-md-4">
                        <h4>Last Name</h4>
                        <p>{this.state.clientLastName}</p>
                      </div>
                      <div className="col-md-4">
                        <h4>Client</h4>
                        <p>
                          <span className="blue" style={{ cursor: "pointer" }} onClick={() => { this.goToClientProf() }}>{this.state.clientName}</span>
                        </p>
                      </div>
                      <div className="col-md-4">
                        <h4>Email ID</h4>
                        <p>{this.state.clientEmailId}</p>
                      </div>
                      <div className="col-md-4">
                        <h4>Phone Number</h4>
                        <p>
                          {"+"}
                          {this.state.countryCode} {this.state.clientPhone}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <h4>Industry Type</h4>
                        <p>{this.state.clientIndustryType}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={this.state.showTab === "bills" ? "tab-pane active" : "tab-pane"} id="payablerecavable">
                <div className="acc_payable">
                  <h3>Account Payables </h3>
                  <div className="table-listing-app tblt">
                    <div className="table-responsive">
                      <table
                        width="100%"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                      >
                        <tr>
                          <th style={{ width: "15%" }}>Job ID</th>
                          <th style={{ width: "20%" }}>Language</th>
                          <th style={{ width: "15%" }}>Vendor[S]</th>
                          <th style={{ width: "10%" }}>Completed On</th>
                          <th style={{ width: "10%" }}>Total Amount $</th>
                          <th style={{ width: "10%" }}>Remarks</th>
                          <th style={{ width: "10%" }}>Status</th>
                          <th style={{ width: "10%" }}>Action</th>
                        </tr>
                        {this.state.payable.length > 0 ? (
                          <React.Fragment>
                            {this.state.payable.map((data) => (
                              <React.Fragment>
                                <tr>
                                  <td>{this.state.jobId}</td>
                                  <td>
                                    {data.sourceLanguage} {">"}{" "}
                                    {data.targetlanguage}
                                  </td>
                                  <td>{data.name}</td>
                                  <td>
                                    {getUtcDateWithMonthName(data.endTime)} |{" "}
                                    {set12hourTimeFormatUtc(data.endTime)}
                                  </td>
                                  <td>${data.totalAmount}</td>
                                  <td>{data.remarks === "" ? "N/A" : data.remarks}</td>
                                  <td>
                                    {data.status === 0 ? (
                                      <React.Fragment>
                                        Verification Pending
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        {data.status === 1 ? (
                                          <React.Fragment>
                                            Verified
                                          </React.Fragment>
                                        ) : data.status === 3 ? <>Rejected</> :
                                          <React.Fragment>
                                            Invoice Generated
                                          </React.Fragment>
                                        }
                                      </React.Fragment>
                                    )}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <FiEdit size={20} onClick={() => this.openViewNoteModal(data)} style={{ cursor: "pointer" }} />
                                    {data.status === 0 || data.status === 3 ? (
                                      <React.Fragment>
                                        <a href="javascript:void(0)">
                                          <img
                                            src={
                                              ImageName.IMAGE_NAME.BLUE_TICK_JPG
                                            }
                                            style={{
                                              width: "20px",
                                              height: "20px",
                                            }}
                                            onClick={() =>
                                              this.approvePayable(data.id, "1")
                                            }
                                          />
                                        </a>
                                        {data.status === 0 ?
                                          <a href="javascript:void(0)" style={{ paddingLeft: "10px" }}>
                                            <img
                                              src={
                                                ImageName.IMAGE_NAME.CROSS_BTN
                                              }
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                              onClick={() =>
                                                this.openRejectModal(data)
                                              }
                                            />
                                          </a> : <></>
                                        }
                                        {/* <a href="javascript:void(0)"><img src={ImageName.IMAGE_NAME.CROSS_BTN} style={{ width: "20px", height: "20px" }} /></a> */}
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment></React.Fragment>
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <td colSpan={8}>No Data Found</td>
                          </React.Fragment>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
                <div className="acc_payable">
                  <h3>Account Receivables</h3>
                  <div className="table-listing-app tblt">
                    <div className="table-responsive">
                      <table
                        width="100%"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                      >
                        <tr>
                          <th style={{ width: "15%" }}>Job ID</th>
                          <th style={{ width: "20%" }}>Language</th>
                          <th style={{ width: "15%" }}>Client[S]</th>
                          <th style={{ width: "10%" }}>Completed On</th>
                          <th style={{ width: "10%" }}>Total Amount $</th>
                          <th style={{ width: "10%" }}>Remarks</th>
                          <th style={{ width: "10%" }}>Status</th>
                          <th style={{ width: "10%" }}>Action</th>
                        </tr>
                        {this.state.receivable.length > 0 ? (
                          <React.Fragment>
                            {this.state.receivable.map((data) => (
                              <React.Fragment>
                                <tr>
                                  <td>{this.state.jobId}</td>
                                  <td>
                                    {data.sourceLanguage} {">"}{" "}
                                    {data.targetlanguage}
                                  </td>
                                  <td>{data.name}</td>
                                  <td>{data.endTime === null ? "NA" : <>
                                    {getUtcDateWithMonthName(data.endTime)} |{" "}
                                    {set12hourTimeFormatUtc(data.endTime)}
                                  </>}
                                  </td>
                                  <td>${data.totalAmount}</td>
                                  <td>{data.remarks === "" ? "N/A" : data.remarks}</td>
                                  <td>
                                    {data.status === 0 ? (
                                      <React.Fragment>
                                        Verification Pending
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        {data.status === 1 ? (
                                          <React.Fragment>
                                            Verified
                                          </React.Fragment>
                                        ) : data.status === 3 ? <>Rejected</> :
                                          <React.Fragment>
                                            Invoice Generated
                                          </React.Fragment>
                                        }
                                      </React.Fragment>
                                    )}
                                  </td>

                                  <td style={{ textAlign: "center" }}>
                                    <FiEdit size={20} onClick={() => this.openViewNoteModal(data)} style={{ cursor: "pointer" }} />
                                    {data.status === 0 || data.status === 3 ? (
                                      <React.Fragment>
                                        <a href="javascript:void(0)">
                                          <img
                                            src={
                                              ImageName.IMAGE_NAME.BLUE_TICK_JPG
                                            }
                                            style={{
                                              width: "20px",
                                              height: "20px",
                                            }}
                                            onClick={() =>
                                              this.approvePayable(data.id, "1")
                                            }
                                          />
                                        </a>
                                        {data.status === 0 ?
                                          <a href="javascript:void(0)" style={{ paddingLeft: "10px" }}>
                                            <img
                                              src={
                                                ImageName.IMAGE_NAME.CROSS_BTN
                                              }
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                              onClick={() =>
                                                this.openRejectModal(data)
                                              }
                                            />
                                          </a> : <></>
                                        }
                                        {/* <a href="javascript:void(0)"><img src={ImageName.IMAGE_NAME.CROSS_BTN} style={{ width: "20px", height: "20px" }} /></a> */}
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment></React.Fragment>
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="comp_sum">
                <div className="job-section-tab">
                  {Object.keys(this.state.completeSummary).length > 0 ? (
                    <React.Fragment>
                      <h2>CLIENT</h2>
                      <div className="tbl-iformation">
                        <div className="table-responsive">
                          <table
                            width="100%"
                            cellspacing="0"
                            cellpadding="0"
                            border="0"
                          >
                            <tbody>
                              <tr>
                                <td width="33%" align="left">
                                  Interpreter Start Time
                                </td>
                                <th width="33%" align="center">
                                  {this.state.completeSummary.actualStartTime}
                                </th>
                                <th width="33%" align="center">{this.state.completeSummary.isStartApproved}</th>
                                {/* <th width="33%" align="center">
                                  <div
                                    className="row"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-evenly",
                                    }}
                                  >
                                    <div className="col-md-2">
                                      <div className="check-field">
                                        <label className="checkbox_btn">
                                          <input
                                            type="radio"
                                            name="radioStart"
                                            checked={
                                              this.state.startTimeCheck === true
                                                ? true
                                                : false
                                            }
                                            onClick={(e) =>
                                              this.onStartTimeCheck("1")
                                            }
                                          />
                                          <span className="checkmark3"></span>{" "}
                                          Yes
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-2">
                                      <div className="check-field">
                                        <label className="checkbox_btn">
                                          <input
                                            type="radio"
                                            name="radioStart"
                                            checked={
                                              this.state.startTimeCheck ===
                                                false
                                                ? true
                                                : false
                                            }
                                            onClick={(e) =>
                                              this.onStartTimeCheck("0")
                                            }
                                          />
                                          <span className="checkmark3"></span>{" "}
                                          No
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </th> */}
                              </tr>
                              <tr>
                                <td width="33%" align="left">
                                  Interpreter End Time
                                </td>
                                <th width="33%" align="center">
                                  {this.state.completeSummary.actualEndTime}
                                </th>
                                <th width="33%" align="center">{this.state.completeSummary.isEndApproved}</th>
                                {/* <th width="33%" align="center">
                                  <div
                                    className="row"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-evenly",
                                    }}
                                  >
                                    <div className="col-md-2">
                                      <div className="check-field">
                                        <label className="checkbox_btn">
                                          <input
                                            type="radio"
                                            name="radioEnd"
                                            checked={
                                              this.state.endTimeCheck === true
                                                ? true
                                                : false
                                            }
                                            onClick={(e) =>
                                              this.onEndTimeCheck("1")
                                            }
                                          />
                                          <span className="checkmark3"></span>{" "}
                                          Yes
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-2">
                                      <div className="check-field">
                                        <label className="checkbox_btn">
                                          <input
                                            type="radio"
                                            name="radioEnd"
                                            checked={
                                              this.state.endTimeCheck === false
                                                ? true
                                                : false
                                            }
                                            onClick={(e) =>
                                              this.onEndTimeCheck("0")
                                            }
                                          />
                                          <span className="checkmark3"></span>{" "}
                                          No
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </th> */}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="_fl margin-top-30 br-bx">
                        <div className="row">
                          <div className="col-md-6">
                            <p> Rate For Interpreter</p>
                          </div>
                          <div className="col-md-6">
                            <div className="rt-rating">
                              {/* <figure><img src={ImageName.IMAGE_NAME.RATTINGSTAR} /></figure> */}
                              <ReactStars
                                count={5}
                                onChange={(value) =>
                                  this.ratingChangedInterpreter(value)
                                }
                                size={44}
                                isHalf={false}
                                activeColor="#009fe0"
                                value={this.state.completeSummary.clientRatings}
                                edit={false}
                              />
                              {this.state.completeSummary.clientRatings === 0 ? (
                                <React.Fragment>
                                  <a href="javascript:void(0)" className="poor">
                                    Very Poor
                                  </a>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  {this.state.completeSummary.clientRatings ===
                                    1 ? (
                                    <React.Fragment>
                                      <a
                                        href="javascript:void(0)"
                                        className="poor"
                                      >
                                        Poor
                                      </a>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      {this.state.completeSummary
                                        .clientRatings === 2 ? (
                                        <React.Fragment>
                                          <a
                                            href="javascript:void(0)"
                                            className="poor"
                                          >
                                            Average
                                          </a>
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          {this.state.completeSummary
                                            .clientRatings === 3 ? (
                                            <React.Fragment>
                                              <a
                                                href="javascript:void(0)"
                                                className="poor"
                                              >
                                                Good
                                              </a>
                                            </React.Fragment>
                                          ) : (
                                            <React.Fragment>
                                              {this.state.completeSummary
                                                .clientRatings === 4 ? (
                                                <React.Fragment>
                                                  <a
                                                    href="javascript:void(0)"
                                                    className="poor"
                                                  >
                                                    Very Good
                                                  </a>
                                                </React.Fragment>
                                              ) : (
                                                <React.Fragment>
                                                  {this.state.completeSummary.clientRatings === 5 ? <React.Fragment>
                                                    <a
                                                      href="javascript:void(0)"
                                                      className="poor"
                                                    >
                                                      Outstanding
                                                    </a>
                                                  </React.Fragment> : <React.Fragment>
                                                  </React.Fragment>
                                                  }
                                                </React.Fragment>
                                              )}
                                            </React.Fragment>
                                          )}
                                        </React.Fragment>
                                      )}
                                    </React.Fragment>
                                  )}
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                        {this.state.appointmentType !== "F2F" ?
                          <div className="row">
                            <div className="col-md-6">
                              <p> Call Quality</p>
                            </div>
                            <div className="col-md-6">
                              <div className="rt-rating">
                                {/* <figure><img src={ImageName.IMAGE_NAME.RATTINGSTAR} /></figure> */}
                                <ReactStars
                                  count={5}
                                  // onChange={(value) =>
                                  //   this.ratingChangedInterpreter(value)
                                  // }
                                  size={44}
                                  isHalf={false}
                                  activeColor="#009fe0"
                                  value={this.state.completeSummary.callQuality}
                                  edit={false}
                                />
                                {this.state.completeSummary.callQuality === 0 ? (
                                  <React.Fragment>
                                    <a href="javascript:void(0)" className="poor">
                                      Very Poor
                                    </a>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    {this.state.completeSummary.callQuality ===
                                      1 ? (
                                      <React.Fragment>
                                        <a
                                          href="javascript:void(0)"
                                          className="poor"
                                        >
                                          Poor
                                        </a>
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        {this.state.completeSummary
                                          .callQuality === 2 ? (
                                          <React.Fragment>
                                            <a
                                              href="javascript:void(0)"
                                              className="poor"
                                            >
                                              Average
                                            </a>
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            {this.state.completeSummary
                                              .callQuality === 3 ? (
                                              <React.Fragment>
                                                <a
                                                  href="javascript:void(0)"
                                                  className="poor"
                                                >
                                                  Good
                                                </a>
                                              </React.Fragment>
                                            ) : (
                                              <React.Fragment>
                                                {this.state.completeSummary
                                                  .callQuality === 4 ? (
                                                  <React.Fragment>
                                                    <a
                                                      href="javascript:void(0)"
                                                      className="poor"
                                                    >
                                                      Very Good
                                                    </a>
                                                  </React.Fragment>
                                                ) : (
                                                  <React.Fragment>
                                                    {this.state.completeSummary.callQuality === 5 ? <React.Fragment>
                                                      <a
                                                        href="javascript:void(0)"
                                                        className="poor"
                                                      >
                                                        Outstanding
                                                      </a>
                                                    </React.Fragment> : <React.Fragment>
                                                    </React.Fragment>
                                                    }
                                                  </React.Fragment>
                                                )}
                                              </React.Fragment>
                                            )}
                                          </React.Fragment>
                                        )}
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div> : <React.Fragment></React.Fragment>
                        }
                      </div>

                      <div className="_fl margin-top-30 br-bx">
                        <div className="row">
                          <div className="col-md-6">
                            <p>Would you use the same interpreter?</p>
                          </div>
                          <div className="col-md-6">
                            <p className="np">{this.state.completeSummary.isPrefVendor}</p>
                            {/* <div className="check-field">
                              <label className="checkbox_btn">
                                <input
                                  type="radio"
                                  name="sameInterpreter"
                                  checked={
                                    this.state.completeSummary.isPrefVendor ===
                                      true
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    this._onSameInterpreterCheck("1")
                                  }
                                />
                                <span className="checkmark3"></span> Yes
                              </label>
                            </div>
                            <div className="check-field">
                              <label className="checkbox_btn">
                                <input
                                  type="radio"
                                  name="sameInterpreter"
                                  checked={
                                    this.state.completeSummary.isPrefVendor ===
                                      false
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    this._onSameInterpreterCheck("0")
                                  }
                                />
                                <span className="checkmark3"></span> No
                              </label>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      {this.state.completeSummary.isPrefVendor === "N/A" ||
                        this.state.completeSummary.isPrefVendor === false ? (
                        <React.Fragment></React.Fragment>
                      ) : (
                        <React.Fragment>
                          {this.state.completeSummary.isPrefVendor === "No" ? (
                            <div className="_fl margin-top-30 br-bx">
                              <div className="row">
                                <div className="col-md-6">
                                  <p>Reason</p>
                                </div>
                                <div className="col-md-6">
                                  <p>
                                    {
                                      this.state.completeSummary
                                        .prefferedReason
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <React.Fragment>
                              {/* {this.state.completeSummary.prefferedReason !== null ?
                                <div className="_fl margin-top-30 br-bx">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <p>Reason</p>
                                    </div>
                                    <div className="col-md-6">
                                      <p>
                                        {
                                          this.state.completeSummary
                                            .prefferedReason
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div> : <></>
                              } */}
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                      <div className="_fl margin-top-30 br-bx">
                        <div className="row">
                          <div className="col-md-6">
                            <p>Is there any follow up appointments?</p>
                          </div>
                          <div className="col-md-6">
                            <p className="np">{this.state.completeSummary.clientFollowupString}</p>
                            {/* <a href="javascript:void(0)" className="_viewdetails">View Details</a>  */}
                            {/* <div className="check-field">
                              <label className="checkbox_btn">
                                <input
                                  type="radio"
                                  name="clientFollowUp"
                                  checked={
                                    this.state.completeSummary.followUp ===
                                      "YES"
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    this._onClientFollowUpCheck("1")
                                  }
                                />
                                <span className="checkmark3"></span> Yes
                              </label>
                            </div>
                            <div className="check-field">
                              <label className="checkbox_btn">
                                <input
                                  type="radio"
                                  name="clientFollowUp"
                                  checked={
                                    this.state.completeSummary.followUp === "NO"
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    this._onClientFollowUpCheck("0")
                                  }
                                />
                                <span className="checkmark3"></span> No
                              </label>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="_fl margin-top-30 br-bx">
                        <div className="row">
                          <div className="col-md-12">
                            <h2>VENDOR</h2>
                          </div>
                        </div>
                      </div>
                      <div className="_fl margin-top-30 br-bx">
                        <div className="tbl-iformation ">
                          <div className="table-responsive">
                            <table
                              className="mn"
                              width="100%"
                              cellspacing="0"
                              cellpadding="0"
                              border="0"
                            >
                              <tbody>
                                <tr>
                                  <td width="50%" align="left">
                                    Interpreter Start Time
                                  </td>
                                  <th width="50%" align="center">
                                    {this.state.completeSummary.startTime}
                                  </th>
                                  {/* <th width="33%" align="center">{this.state.completeSummary.isStartApproved}</th> */}
                                </tr>
                                <tr>
                                  <td width="50%" align="left">
                                    Interpreter End Time
                                  </td>
                                  <th width="50%" align="center">
                                    {this.state.completeSummary.endTime}
                                  </th>
                                  {/* <th width="33%" align="center">{this.state.completeSummary.isEndApproved}</th> */}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="_fl margin-top-30 br-bx">

                        {this.state.appointmentType !== "F2F" ?
                          <div className="row">
                            <div className="col-md-6">
                              <p> Call Quality</p>
                            </div>
                            <div className="col-md-6">
                              <div className="rt-rating">
                                {/* <figure><img src={ImageName.IMAGE_NAME.RATTINGSTAR} /></figure> */}
                                <ReactStars
                                  count={5}
                                  // onChange={(value) =>
                                  //   this.ratingChangedInterpreter(value)
                                  // }
                                  size={44}
                                  isHalf={false}
                                  activeColor="#009fe0"
                                  value={this.state.completeSummary.vendorCallQuality}
                                  edit={false}
                                />
                                {this.state.completeSummary.vendorCallQuality === 0 ? (
                                  <React.Fragment>
                                    <a href="javascript:void(0)" className="poor">
                                      Very Poor
                                    </a>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    {this.state.completeSummary.vendorCallQuality ===
                                      1 ? (
                                      <React.Fragment>
                                        <a
                                          href="javascript:void(0)"
                                          className="poor"
                                        >
                                          Poor
                                        </a>
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        {this.state.completeSummary
                                          .vendorCallQuality === 2 ? (
                                          <React.Fragment>
                                            <a
                                              href="javascript:void(0)"
                                              className="poor"
                                            >
                                              Average
                                            </a>
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            {this.state.completeSummary
                                              .vendorCallQuality === 3 ? (
                                              <React.Fragment>
                                                <a
                                                  href="javascript:void(0)"
                                                  className="poor"
                                                >
                                                  Good
                                                </a>
                                              </React.Fragment>
                                            ) : (
                                              <React.Fragment>
                                                {this.state.completeSummary
                                                  .vendorCallQuality === 4 ? (
                                                  <React.Fragment>
                                                    <a
                                                      href="javascript:void(0)"
                                                      className="poor"
                                                    >
                                                      Very Good
                                                    </a>
                                                  </React.Fragment>
                                                ) : (
                                                  <React.Fragment>
                                                    {this.state.completeSummary.vendorCallQuality === 5 ? <React.Fragment>
                                                      <a
                                                        href="javascript:void(0)"
                                                        className="poor"
                                                      >
                                                        Outstanding
                                                      </a>
                                                    </React.Fragment> : <React.Fragment>
                                                    </React.Fragment>
                                                    }
                                                  </React.Fragment>
                                                )}
                                              </React.Fragment>
                                            )}
                                          </React.Fragment>
                                        )}
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div> : <React.Fragment></React.Fragment>
                        }
                      </div>
                      <div className="_fl margin-top-30 br-bx">
                        <div className="row">
                          <div className="col-md-6">
                            <p>Provide Follow up details? (if any)</p>
                          </div>
                          <div className="col-md-6">
                            {this.state.completeSummary.followUpDate ===
                              "N/A" ? (
                              <React.Fragment>
                                <p>N/A</p>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <p>
                                  {this.state.completeSummary.followUpDate}
                                  {/* |{" "}
                                  {this.state.completeSummary.followUpTimeRange} */}
                                </p>
                              </React.Fragment>
                            )}
                            {/* <p>{this.state.completeSummary.followUpDate} | {this.state.completeSummary.followUpTimeRange}</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="_fl margin-top-30 br-bx">
                        <div className="row">
                          <div className="col-md-6">
                            <p>Are you available for follow up?</p>
                          </div>
                          <div className="col-md-6">
                            <p className="np">{this.state.completeSummary.followUp}</p>
                          </div>
                        </div>
                      </div>
                      <div className="_fl margin-top-30 br-bx">
                        <div className="row">
                          <div className="col-md-12">
                            <h2>MISCELLANEOUS EXPENSES</h2>
                          </div>
                        </div>
                      </div>
                      <div className="_fl margin-top-30 br-bx">
                        <div className="row">
                          <div className="col-md-6">
                            <p>
                              Are the miscellaneous expences approved by{" "}
                              <span style={{ color: "#00a0df" }}>7C Lingo</span>
                              ?
                            </p>
                          </div>
                          <div className="col-md-6">
                            <p className="np">
                              {
                                this.state.completeSummary
                                  .expensesApproveByAdmin
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                      {this.state.completeSummary.expensesApproveByAdmin ===
                        "Yes" ? (
                        <div className="_fl margin-top-30 br-bx">
                          <div className="tbl-iformation ">
                            <div className="table-responsive">
                              <table
                                className="mn"
                                width="100%"
                                cellspacing="0"
                                cellpadding="0"
                                border="0"
                              >
                                <tbody>
                                  <tr>
                                    <th
                                      width="33%"
                                      align="center"
                                      style={{ color: "#b4b4b4" }}
                                    >
                                      Expense Type
                                    </th>
                                    <th
                                      width="33%"
                                      align="center"
                                      style={{ color: "#b4b4b4" }}
                                    >
                                      Incidentals
                                    </th>
                                    <th
                                      width="33%"
                                      align="center"
                                      style={{ color: "#b4b4b4" }}
                                    >
                                      Receipt Amount
                                    </th>
                                  </tr>
                                  {this.state.mislleniousExpenses.length > 0 ? (
                                    <React.Fragment>
                                      {this.state.mislleniousExpenses.map(
                                        (data, i) => (
                                          <React.Fragment key={i}>
                                            <tr>
                                              <th width="33%" align="center">
                                                {data.expenseType}
                                              </th>
                                              <th width="33%" align="center">
                                                <p>
                                                  <a href="javascript:void(0)">
                                                    <img
                                                      src={
                                                        ImageName.IMAGE_NAME
                                                          .DOWNLOADSHEET
                                                      }
                                                      onClick={() =>
                                                        this.onDownloadMisc(i)
                                                      }
                                                    />
                                                  </a>
                                                </p>
                                              </th>
                                              <th width="33%" align="center">
                                                $ {data.amount}
                                              </th>
                                            </tr>
                                          </React.Fragment>
                                        )
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment></React.Fragment>
                                  )}
                                  <tr>
                                    <th colspan="2">Total Amount : </th>
                                    <th width="33%" style={{ color: "green" }}>
                                      ${" "}
                                      {parseFloat(this.state.miscTotal).toFixed(
                                        2
                                      )}{" "}
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <ReactLoader />
                    </React.Fragment>
                  )}
                </div>
              </div>

              <div className="tab-pane" id="vendoroff">
                <div className="job-section-tab">
                  <div
                    className="vendor-info p-10 _fl sdw"
                    style={{ paddingBottom: "10px" }}
                  >
                    <div className="vn-form _fl">
                      <div className="row">

                        <div className="col-md-4">
                          <div className="vn_frm">
                            <span>Vendor Name</span>
                            <div>
                              <input type="text" className="inputfield"
                                value={this.state.filterVendorName}
                                onChange={(e) => this.filterVendorChange(e)} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="vn_frm">
                            <span>Rating</span>
                            <div className="bts-drop">
                              <SelectBox
                                optionData={this.state.allRating}
                                value={this.state.selectedFilterRating}
                                onSelectChange={(value) =>
                                  this.filterRatingChange(value)
                                }
                              ></SelectBox>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="vn_frm">
                            <span>Gender</span>
                            <div className="bts-drop">
                              <SelectBox
                                optionData={this.state.allGenderForVendor}
                                value={this.state.selectedGender}
                                onSelectChange={(value) =>
                                  this.filterGenderChange(value)
                                }
                              ></SelectBox>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-md-4">
                          <div className="vn_frm">
                            <span>Available</span>
                            <div className="bts-drop">
                              <SelectBox
                                optionData={this.state.allWeekDay}
                                value={this.state.selectedFilterWeek}
                                onSelectChange={(value) =>
                                  this.filterWeekChange(value)
                                }
                              ></SelectBox>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="vn-form _fl">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="vn_frm">
                            <span>Relationship to client </span>
                            <div>
                              <input type="number" className="inputfield"
                                value={this.state.relationshipToClient}
                                onChange={(e) => this.relationshipToClientChange(e)} />
                            </div>
                            {/* <div className="bts-drop">
                              <SelectBox
                                optionData={this.state.allRelationshipForVendor}
                                value={this.state.selectedRelationship}
                                onSelectChange={(value) =>
                                  this.filterRelationshipChange(value)
                                }
                              ></SelectBox>
                            </div> */}
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="vn_frm">
                            <span>Relationship to LEI </span>
                            <div>
                              <input type="number" className="inputfield"
                                value={this.state.relationshipToLei}
                                onChange={(e) => this.relationshipToLeiChange(e)} />
                            </div>
                            {/* <div className="bts-drop">
                              <SelectBox
                                optionData={this.state.allRelationshipForVendor}
                                value={this.state.selectedRelationship}
                                onSelectChange={(value) =>
                                  this.filterRelationshipChange(value)
                                }
                              ></SelectBox>
                            </div> */}
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="vn_frm">
                            <span>Interpretation Cost upto</span>
                            <div style={{ paddingLeft: "24%" }}>
                              <input type="number" className="inputfield"
                                value={this.state.costTo}
                                onChange={(e) => this.costToChange(e)} />
                            </div>
                            {/* <div className="bts-drop">
                              <SelectBox
                                optionData={this.state.allTotalCostForVendor}
                                value={this.state.selectedTotalCost}
                                onSelectChange={(value) =>
                                  this.filterCostChange(value)
                                }
                              ></SelectBox>
                            </div> */}
                          </div>
                        </div>
                        <div className="col-md-5">
                          {this.state.appointmentType === "F2F" ? (

                            <div className="vn_frm">
                              <span>Round trip distance upto</span>
                              <div>
                                <input type="number" className="inputfield"
                                  value={this.state.distanceTo}
                                  onChange={(e) => this.distanceToChange(e)} />
                              </div>
                              {/* <div className="bts-drop">
                                <SelectBox
                                  optionData={this.state.allDistanceForVendor}
                                  value={this.state.selectedMileage}
                                  onSelectChange={(value) =>
                                    this.filterDistanceChange(value)
                                  }
                                ></SelectBox>
                              </div> */}
                            </div>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                        </div>
                        <div className="col-md-4">
                          <div className="vn_frm">
                            <span>Sort By</span>
                            <div className="bts-drop">
                              <SelectBox
                                optionData={this.state.sortingFilterArr}
                                value={this.state.selectedVendorSortingData}
                                onSelectChange={(value) =>
                                  this.sortingVendoDataChange(value)
                                }
                              ></SelectBox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vn-form _fl">
                      <div className="row">
                        <div className="available_vendor_filter_btn_div">
                          <button className="vendoroff_filter_submit" onClick={() => this.apply_vendoroff_filter()}>Apply</button>
                          <button className="vendoroff_filter_cancel" onClick={() => this.resetVendorOffFilter()}>Reset</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="table-filter-app-b">
                    <div className="filter-btn">
                      <a href="javascript:void(0)" onClick={this.filterModal}>
                        Filter
                      </a>
                    </div>
                  </div> */}
                  <div className="table-listing-app">
                    <div className="table-responsive">
                      <table
                        width="100%"
                        cellspacing="0"
                        cellpadding="0"
                        border="0"
                      >
                        <tbody>
                          <tr>
                            {this.isVendorSendOffer() ? (
                              <React.Fragment>
                                <th style={{ width: "5%" }}></th>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <th style={{ width: "5%" }}>
                                  {this.state.listData.length > 0 ?
                                    <label className="custom_check2">
                                      <input
                                        type="checkbox"
                                        checked={this.state.allChecked}
                                        onClick={this.allVendorCheck}
                                      />
                                      <span className="checkmark2"></span>
                                    </label> : <></>}
                                </th>
                              </React.Fragment>
                            )}
                            <th style={{ width: "15%" }}>Name & Email</th>
                            {/* <th style={{ width: "15%" }}>Email ID</th> */}
                            <th style={{ width: "10%" }}>Phone Number</th>
                            {this.state.appointmentType === "F2F" ?
                              <th style={{ width: "10%" }}>Distance (Round Trip Distance)</th> : <></>}
                            <th style={{ width: "10%" }}>Interpretation Cost</th>
                            <th style={{ width: "10%" }}>Relationship With Client</th>
                            <th style={{ width: "10%" }}>Relationship With LEI</th>
                            <th style={{ width: "5%" }}>Ratings</th>
                            <th style={{ width: "5%" }}>Availability</th>
                            <th style={{ width: "8%" }}>Status</th>
                            <th style={{ width: "7%" }}>Action</th>
                          </tr>
                        </tbody>
                        <tbody>
                          {this.state.listData.length > 0 ? (
                            this.state.listData.map((item, key) => (
                              <tr key={key}>
                                {/* {(Number(item.status) !== 5 || Number(item.status) !== 7) && item.sent === 1 ? (
                                  <React.Fragment>
                                    <th style={{ width: "5%" }}></th>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <td style={{ width: "4%" }}>
                                      {item.sent === 1 ? <></> :
                                        <label className="custom_check2">
                                          <input
                                            type="checkbox"
                                            checked={item.isQuoteSent === 1 ? true : false}
                                            onChange={(e) =>
                                              this.listChecked(e, key)
                                            }
                                          />
                                          <span className="checkmark2"></span>
                                        </label>
                                      }
                                    </td>
                                  </React.Fragment>
                                )} */}

                                {this.state.quoteStatus > 8 ? <React.Fragment>
                                  <th style={{ width: "5%" }}></th>
                                </React.Fragment> : <React.Fragment>
                                  <td style={{ width: "4%" }}>
                                    <label className="custom_check2">
                                      <input
                                        type="checkbox"
                                        checked={item.isQuoteSent === 1 ? true : false}
                                        onChange={(e) =>
                                          this.listChecked(e, key)
                                        }
                                      />
                                      <span className="checkmark2"></span>
                                    </label>
                                  </td>
                                </React.Fragment>
                                }
                                <td style={{ width: "15%" }}>
                                  {item.agencyName === ""
                                    ? item.fName + " " + item.lName
                                    : item.fName +
                                    " " +
                                    item.lName +
                                    " (" +
                                    item.agencyName +
                                    ")"}<br />

                                  <a href="javascript:void(0)" className="viewlink">
                                    {item.email}
                                  </a>
                                </td>
                                {/* <td style={{ width: "15%" }}>
                                  <a href="javascript:void(0)" className="viewlink">
                                    {item.email}
                                  </a>
                                </td> */}
                                <td style={{ width: "10%" }}>{item.mobile}</td>
                                {this.state.appointmentType === "F2F" ?
                                  <td style={{ width: "10%" }}>{item.distance === null ? "N/A" : parseFloat(item.distance).toFixed(2) + " Mile(s)"} </td> : <></>}
                                <td style={{ width: "10%" }}>{item.TotalCost === null ? "N/A" : "$ " + parseFloat(item.TotalCost).toFixed(2)}</td>
                                <td style={{ width: "10%" }}>{item.Relationship}</td>
                                <td style={{ width: "10%" }}>{item.RelationshipLei}</td>
                                <td style={{ width: "5%" }}>
                                  <img src={ImageName.IMAGE_NAME.STARYELLOW} />
                                  <span className="rat_txt">
                                    {item.ratings}
                                  </span>
                                </td>
                                <td style={{ width: "8%" }}>
                                  <a
                                    href="javascript:void(0)"
                                    className="view"
                                    style={{
                                      cursor: "pointer",
                                      color: "green",
                                    }}
                                    onClick={() =>
                                      this.handleViewModal(item.userId)
                                    }
                                  >
                                    View
                                  </a>
                                </td>
                                <td style={{ width: "5%" }}>
                                  {item.sent === 1 || item.status == 5 ? <div style={{ display: "inline-block" }}>
                                    <span
                                      style={{
                                        color: this.getVendorStatusColor(item),
                                        fontSize: "12px",
                                      }}
                                    >
                                      {this.getVendorStatus(item)}
                                    </span>
                                    {item.status === 5 ? <FcInfo size={25} className="rejectIcn" onClick={() => this.openVendorRejectReasonModal(item.rejectReason)} /> : <></>}
                                  </div> : <></>}
                                </td>
                                <td style={{ width: "7%" }}>
                                  {item.status === 2 ?
                                    <a
                                      href="javascript:void(0)"
                                      className="bidAssignBtn"
                                      style={{ margin: "0px" }}
                                      onClick={() => {
                                        this.openUnassignVendorModal(item.userId, item.bidRequestId);
                                      }}
                                    >
                                      Unassign
                                    </a> :
                                    <>
                                      {item.status === 0 ? <>
                                        <a
                                          href="javascript:void(0)"
                                          className="bidAssignBtn"
                                          style={{ margin: "0px" }}
                                          onClick={() => {
                                            this.openAssignVendorModal(item.userId, item.bidRequestId);
                                          }}
                                        >
                                          Assign
                                        </a>
                                      </> : <>
                                        {(item.status === 7 || item.status === 5) ? <>
                                          <a
                                            href="javascript:void(0)"
                                            className="bidAssignBtn"
                                            style={{ margin: "0px" }}
                                            onClick={() => {
                                              this.openAssignVendorModal(item.userId, item.bidRequestId);
                                            }}
                                          >
                                            Assign
                                          </a>
                                        </> : <></>}
                                      </>}
                                    </>
                                  }
                                </td>

                                {/* {this.state.quoteStatus === 8 ? (
                                  <React.Fragment>
                                    <td style={{ width: "15%" }}>
                                      {item.status === 2 ? (
                                        <>
                                          <span
                                            style={{
                                              color: "green",
                                              fontSize: "12px",
                                            }}
                                          >
                                            Assigned
                                          </span>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <td style={{ width: "15%" }}>
                                      {item.status === 1 ? (
                                        <React.Fragment>
                                          <a
                                            href="javascript:void(0)"
                                            className="bidAssignBtn"
                                            onClick={() => {
                                              this.vendoeAssigned(item.userId);
                                            }}
                                          >
                                            Assign
                                          </a>
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment></React.Fragment>
                                      )}
                                    </td>
                                  </React.Fragment>
                                )} */}
                              </tr>
                            ))
                          ) : (
                            <React.Fragment>
                              <tr>
                                <td colSpan={11}>No vendor Found</td>
                              </tr>
                            </React.Fragment>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!this.isVendorSendOffer() ? <>
                    <React.Fragment>
                      <div className="_button-style _fl text-center margin-top-30">
                        {/* <a href="javascript:void(0)" className="grey-btn">
                          Reset
                        </a> */}
                        <a
                          href="javascript:void(0)"
                          className="blue-btn"
                          onClick={this.handleVendorAssign}
                        >
                          Send Offer
                        </a>
                      </div>
                    </React.Fragment>
                  </> : <></>
                  }
                </div>
              </div>


              <Dialog
                open={this.state.unassignVendorModal}
                onClose={() => this.closeUnassignVendorModal()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to unassign the current vendor ?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => this.closeUnassignVendorModal()} color="primary">
                    No
                  </Button>
                  <Button onClick={() => this.vendorUnAssigned(this.state.vendorInfo.vendorId, this.state.vendorInfo.bidRequestId)} color="primary" autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={this.state.assignVendorModal}
                onClose={() => this.closeAssignVendorModal()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Do you want to assign this vendor? (If another vendor is already assigned, the job will be unavailable for him/her.)
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => this.closeAssignVendorModal()} color="primary">
                    No
                  </Button>
                  <Button onClick={() => this.vendorAssigned(this.state.vendorInfo.vendorId, this.state.vendorInfo.bidRequestId)} color="primary" autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>

              <div className="tab-pane" id="chattab">
                <div className="job-section-tab">
                  <div className="prticipants-area _fl">

                    <div className="row">
                      <div className="col-md-6">
                        {/* <h3>3 Participants</h3> */}
                        <button type="button" className="new_chat_btn" onClick={() => this.openParticipantModal()}>
                          <img src={ImageName.IMAGE_NAME.MENU.CHAT_ICON_BLUE} />
                          <span>New Chat</span>
                        </button>
                      </div>

                      {/* <div className="col-md-6">
                        <a href="javascript:void(0)" className="add-part-btn" onClick={this.openParticipantModal}>
                          + Create Group
                        </a>
                      </div> */}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        {this.state.chatGroup.length > 0 ?
                          <ChatGroupList
                            value={this.state.chatGroup}
                            changeGroupValue={(value) => this.changeChatGroup(value)}
                          /> :
                          <></>
                        }
                      </div>
                    </div>
                  </div>
                  {this.showChatPage(this.state.selectedChatGroupValue)}
                  {/* {this.state.selectedChatGroupValue !== "" ? <MainChatPage jobId={this.state.selectedChatGroupValue} /> : <></>} */}
                </div>
              </div>

              <Modal show={this.state.addParticipantShow} onHide={this.closeParticipantModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Participants</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* <div className="web-form-bx">
                    <div className="frm-label">
                      Group Name
                    </div>
                    <div className="form-input-fields">
                      <input
                        type="text"
                        placeholder="Enter group name..."
                        className="textbox4"
                        style={{
                          borderRadius: "9px",
                          boxShadow:
                            "0px 0px 4px 0px rgb(0 0 0 / 20%)",
                        }}
                        value={this.state.chatGroupName}
                        onChange={this.changeChatGroupName}
                      />
                    </div>
                  </div> */}

                  <div className="web-form-bx">
                    <div className="frm-label">Participants *</div>
                    <div className="bts-drop">
                      <SelectBox
                        optionData={this.state.chatParticipants}
                        value={this.state.selectedChatParticipants}
                        onSelectChange={(value) =>
                          this.onSelectChatParticipantsForSingle(value)
                        }
                      ></SelectBox>
                      {/* <MultiSelectBox
                        optionData={this.state.chatParticipants}
                        value={this.state.selectedChatParticipants}
                        onSelectChange={(value) =>
                          this.onSelectChatParticipants(value)
                        }
                      ></MultiSelectBox> */}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.closeParticipantModal}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={this.submitParticipantsForSingle}>
                    Start Chat
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal show={this.state.addGrouoChatParticipantModal} >
                <Modal.Header>
                  <Modal.Title>Add Participants </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="web-form-bx">
                    <div className="frm-label">Participants *</div>
                    <div className="bts-drop">
                      <MultiSelectBox
                        optionData={this.state.addGroupChatParticipant}
                        value={this.state.selectedAddGroupChatParticipantValue}
                        onSelectChange={(value) =>
                          this.selectAddGroupParticipants(value)
                        }
                      ></MultiSelectBox>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.closeAddGroupChatParticipantModal}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={this.submitParticipantsInGroup}>
                    Add
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal show={this.state.chatParticipantModal} onHide={this.closeChatParticipantModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Participants List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="web-form-bx">
                    <div className="frm-label">Participants List</div>
                    <div className="row">
                      {this.state.groupChatParticipant.map((data, i) => <>
                        <div className="col-md-10" key={i}>
                          {data.label}
                        </div>
                        {this.state.groupChatParticipant.length > 1 ?
                          <div className="col-md-2">
                            <MdDelete style={{ cursor: "pointer" }} onClick={() => this.removeParticipants(data)} />
                          </div> : <></>}
                      </>)}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.closeChatParticipantModal}>
                    Close
                  </Button>
                  {/* <Button variant="primary" onClick={this.submitParticipants}>
                    Add
                  </Button> */}
                </Modal.Footer>
              </Modal>

              <div className="tab-pane" id="notetab">
                <div className="job-section-tab">
                  <div className="chat-app-information-component">
                    <div className="chat-app-component">
                      {this.state.allJobNotes.map((data, i) =>
                        <div className="participants-chat-row" key={i}>
                          <figure>
                            <img src={ImageName.IMAGE_NAME.LOGO1} />
                          </figure>
                          <div className="chatinfo-bx">
                            <p className="cht-user">
                              <b>{data.userName}</b> <span>{SetUSAdateFormatV2(data.createDate) + " " + SetTimeFormat(data.createDate)}</span>
                            </p>
                            <p className="chat-text">
                              {data.notes}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>


                  </div>
                  <div className="chat-app-type-area">
                    <input
                      type="text"
                      value={this.state.notesText}
                      name="notes"
                      className="chat-field-bx"
                      onChange={(e) => this.changeInternalNotes(e)}
                    />
                    <button type="submit" className="send-btn-app" onClick={() => this.addNotes()}>
                      send
                    </button>
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="nofifications">
                <div className="job-section-tab">
                  <div className="chat-app-information-component">
                    <div className="chat-app-component">
                      {this.state.allJobNotifications.map((data, key) => <React.Fragment key={key}>
                        <div class="accordion" id={"accordionExample" + key} >
                          <div class="card notify_card">
                            <div
                              class="card-header"
                              id={"headingOne" + key}
                              type="button"
                              style={{
                                borderRadius: "20px ",
                                padding: "20px 0px",
                                backgroundColor: "white",
                              }}
                              data-toggle="collapse"
                              data-target={"#collapseOne" + key}
                              aria-expanded="false"
                              aria-controls={"collapseOne" + key}
                            // onClick={() => this._onCheckNotification(item,key)}
                            >
                              <div>
                                <div className="row notify">
                                  <div className="col-md-2">
                                    <React.Fragment>
                                      <img
                                        src={ImageName.IMAGE_NAME.CLINGO_LOGO_GRAY_COLOR}
                                        style={{ width: "40px" }}
                                      ></img>
                                    </React.Fragment>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="job_details">
                                      {data.title}<br />
                                      {data.body}
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="notify_time">
                                      {moment(data.createDate).format("LLL")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* ................ Audit History Tab ............. */}

              <div className="tab-pane" id="audit">
                <div className="job-section-tab">
                  <div className="chat-app-information-component">
                    <div className="chat-app-component">
                      {this.state.allAuditData.map((data, key) => <React.Fragment key={key}>
                        <div class="accordion" id={"accordionExample" + key} >
                          <div class="card notify_card">
                            <div
                              class="card-header"
                              id={"headingOne" + key}
                              type="button"
                              style={{
                                borderRadius: "20px ",
                                padding: "20px 0px",
                                backgroundColor: "white",
                              }}
                              data-toggle="collapse"
                              data-target={"#collapseOne" + key}
                              aria-expanded="false"
                              aria-controls={"collapseOne" + key}
                            // onClick={() => this._onCheckNotification(item,key)}
                            >
                              <div>
                                <div className="row notify">
                                  <div className="col-md-2">
                                    <React.Fragment>
                                      <img
                                        src={ImageName.IMAGE_NAME.CLINGO_LOGO_GRAY_COLOR}
                                        style={{ width: "40px" }}
                                      ></img>
                                    </React.Fragment>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="job_details">
                                      <span style={{ fontSize: '14px' }}
                                        dangerouslySetInnerHTML={{ __html: data.description.replace(/\n/g, '<br />') }}>
                                      </span><br />
                                      Action By : {data.userName}<br />
                                      Log Type : {data.logType}
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="notify_time">
                                      {moment(data.createdOn).format("LLL")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* ................ Email & SMS data tab ................. */}
              <div className="tab-pane" id="emailsms">
                <div className="job-section-tab">
                  {/* <EditJob mainData={this.state.reqId} /> */}
                  <h2>Email & SMS Log Details</h2>
                  {(this.state.userType === UsersEnums.APPLICATION_ROLE.SUPER_ADMIN || this.state.userType === UsersEnums.APPLICATION_ROLE.ADMIN_STAFF) ?
                    <div className="mailSmsBtn">
                      <RiMailSendLine size={30} title="Send Email"
                        style={{ cursor: 'pointer' }} onClick={() => this.openEmailSendModal()} />
                      {this.state.sentEmailModal == true ? <React.Fragment><EmailModal showShare={this.state.sentEmailModal} jobId={this.state.numJobId} closeMailModal={() => this.closeEmailSendModal()} /></React.Fragment> : <React.Fragment></React.Fragment>}
                      <MdOutlineSms size={30} title="Send SMS" style={{ cursor: 'pointer' }} onClick={() => this.openSmsModal()} />
                      {this.state.sentSmsModal == true ? <React.Fragment><SmsModal showsms={this.state.sentSmsModal} jobId={this.state.numJobId} closeSms={() => this.closeSmsModal()} /></React.Fragment> : <React.Fragment></React.Fragment>}

                    </div> :
                    <></>
                  }
                  <div className="chat-app-information-component">
                    <div className="chat-app-component">
                      {this.state.allEmailSmsLog.length > 0 ? <React.Fragment>
                        {this.state.allEmailSmsLog.map((data, key) => <React.Fragment key={key}>
                          <div class="accordion" id={"accordionExample" + key} >
                            <div class="card notify_card">
                              <div
                                class="card-header"
                                id={"headingOne" + key}
                                type="button"
                                style={{
                                  borderRadius: "20px ",
                                  padding: "20px 0px",
                                  backgroundColor: "white",
                                }}
                                data-toggle="collapse"
                                data-target={"#collapseOne" + key}
                                aria-expanded="false"
                                aria-controls={"collapseOne" + key}
                              // onClick={() => this._onCheckNotification(item,key)}
                              >
                                <div>
                                  <div className="row notify" onClick={() => this.openViewEmailModal(data)}>
                                    <div className="col-md-2">
                                      <React.Fragment>
                                        <img
                                          src={ImageName.IMAGE_NAME.CLINGO_LOGO_GRAY_COLOR}
                                          style={{ width: "40px" }}
                                        ></img>
                                      </React.Fragment>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="job_details">
                                        {data.logType} : {data.subject}
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="notify_time">
                                        <strong>Send By : </strong>  {data.sender} <br />
                                        {data.senderName ? <>
                                          <strong>Sender Name : </strong>  {data.senderName} <br />
                                        </> : <></>
                                        }
                                        <strong>Received By : </strong>  {data.receiver} <br />
                                        {data.receiverName ? <>
                                          <strong>Receiver Name : </strong>  {data.receiverName} <br />
                                        </> : <></>
                                        }
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="notify_time">
                                        {SetDateFormat(data.receivedDate)} {SetTimeFormat(data.receivedDate)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>)}
                      </React.Fragment> : <React.Fragment></React.Fragment>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* //.................... For availability Modal................... */}

        <div id="viewModal" className="modal fade modelwindow" role="dialog">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <ul className="nav nav-tabs nav-pills nav-fill">
                <li
                  className="nav-item"
                // onClick={() => this.onTabClick_Invoice_vendor(1)}
                >
                  {" "}
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#home_5"
                    style={{ color: "black" }}
                  >
                    Availability
                  </a>{" "}
                </li>
                <li
                  className="nav-item"
                // onClick={() => this.onTabClick_Invoice_vendor(2)}
                >
                  {" "}
                  <a className="nav-link" data-toggle="tab" href="#menu12_5" style={{ color: "black" }}>
                    Vacation
                  </a>{" "}
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane  active" id="home_5">
                  <div className="filter-head _fl document-hd">
                    <h3 className="text-center center-text">
                      Interpreter Availability
                    </h3>
                    <button
                      type="button"
                      className="close"
                      onClick={() => this.closeViewModal()}
                    >
                      X
                    </button>
                  </div>

                  <div className="modal-body">
                    <div className="table-listing-app card">
                      <div className="table-responsive">
                        {Object.keys(this.state.availabileData).length > 0 ? (
                          <BidModal value={this.state.availabileData} />
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </div>
                    </div>

                    <div className="_button-style _fl text-center">
                      {/* <a className="grey-btn" onClick={() => this.closeModal()}>cancel</a> */}
                      {/* <a className="blue-btn">save</a> */}
                      {/* <a href="#" className="buledark-btn">save & reverse</a> */}
                    </div>
                  </div>
                </div>

                {/* .................vacation.......... */}
                <div className="tab-pane  fade" id="menu12_5">
                  <div className="filter-head _fl document-hd">
                    <h3 className="text-center center-text">
                      Vacation Summary
                    </h3>
                    {/* <button
      type="button"
      className="close"
      onClick={() => this.closeAvailabilityViewModal()}
    >
      X
    </button> */}
                  </div>
                  <div className="modal-body">
                    <div className="table-listing-app card pt-30">
                      <div className="table-responsive">
                        {this.state.vacationList.length > 0 ? (
                          <React.Fragment>
                            {this.state.vacationList.map((item, key) => (
                              <div class="">
                                <div class="card notify_card">
                                  <div
                                    className=""
                                    type="button"
                                    style={{
                                      borderRadius: "20px",
                                      padding: "20px 0px",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <div>
                                      <div className="row notify">
                                        <div className="col-md-2">
                                          {
                                            <img
                                              className=""
                                              src={
                                                ImageName.IMAGE_NAME
                                                  .CALANDER_WITH_AEROPLANE
                                              }
                                              style={{
                                                width: "40px",
                                                marginLeft: "30px",
                                              }}
                                            ></img>
                                          }
                                        </div>
                                        <div className="col-md-4">
                                          <div className="job_details">
                                            <span>FROM : </span>{" "}
                                            {SetDateFormat(item.formDate)}
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="job_details">
                                            <span>TO : </span>{" "}
                                            {SetDateFormat(item.toDate)}
                                          </div>
                                        </div>
                                        {/* <div className="col-md-2">
                    <img
                      src={ImageName.IMAGE_NAME.EDIT_SQUARE}
                      style={{ cursor: "pointer" }}
                      id="basic-button"
                      className="serv-cat-edit-btn"
                      onClick={() => this.editPage(key)}
                    />
                    <img
                      src={ImageName.IMAGE_NAME.TRASH_BTN}
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                      id="basic-button"
                      className="serv-cat-del-btn"
                        onClick={() => this.deletePage(item,key)}
                    />
                  </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <table
                              width="100%"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                            >
                              <tr>
                                <td colSpan="7">
                                  <center style={{ fontSize: "20px" }}>
                                    No data found !!!
                                  </center>
                                </td>
                              </tr>
                            </table>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>

                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>

        {/* //...................... For Bid Modal....................... */}
        <div
          id="bid-modal"
          className="modal fade modelwindow largewindow"
          role="dialog"
        >
          <div className="modal-dialog modal-md modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body p30">
                <div className="mtch-top-text">
                  <p>
                    <span>Job ID</span> {this.state.jobId}
                  </p>
                  <p>
                    <span>Task</span> {this.state.language}
                  </p>
                  <p>
                    <span>Client Name</span> {this.state.clientFirstName}{" "}
                    {this.state.clientLastName}
                  </p>
                </div>

                <div className="mv-text _fl">
                  <h2>Vendor Bid Details</h2>
                </div>

                <div className="matching-vendor-table _fl sdw">
                  <div className="depr_table p10">
                    <div className="table-responsive">
                      <table
                        width="100%"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                      >
                        <tbody>
                          <tr>
                            <th style={{ width: "60%" }}>Vendor Name</th>
                            <th style={{ width: "10%" }} className="th_1">
                              {this.state.bidVendorName}
                            </th>
                          </tr>
                          <tr>
                            <td>Rate / Hour</td>
                            <td>$ {this.state.bidFee}</td>
                          </tr>

                          <tr className="tt-count">
                            <td className="f1">Total Bid</td>
                            <td> $ {this.state.totalBidFee}</td>
                          </tr>
                          {/* <tr>
                            <td>&nbsp;</td>
                            <td  ><a className="bidAssignBtn">Assign</a></td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12" style={{ textAlign: "center" }}>
                      <a
                        href="javascript:void(0)"
                        className="bidAssignBtn"
                        onClick={() => {
                          this.vendoeAssigned(this.state.bidVendorId);
                        }}
                      >
                        Assign
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ..................modal................................. */}
        <div id="filter-model" className="modal fade modelwindow" role="dialog">
          <div className="modal-dialog modal-md modal-dialog-centered">
            {/* <!-- Modal content--> */}
            <div className="modal-content">
              <div className="filter-head _fl mdf">
                <h3>Filter by</h3>
                <div className="reset-btn-dp">
                  <button className="reset" data-dismiss="modal">
                    <img
                      src={ImageName.IMAGE_NAME.RESET_BTN}
                      onClick={this.onResetFilter}
                    />
                    Reset
                  </button>
                  <button className="apply" data-dismiss="modal">
                    <img
                      src={ImageName.IMAGE_NAME.BLUE_TICK}
                      onClick={this.onFilterApply}
                    />
                    Apply
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="model-info f-model">
                  <div className="m-select _fl">
                    <div class="row">
                      <div class="col-md-5">
                        <div class="sf-row">
                          <div class="lable-text" style={{ fontSize: "13px" }}>
                            Distance
                          </div>
                          <input type="text" />
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                      <div class="col-md-5">
                        <div class="sf-row">
                          <div class="lable-text" style={{ fontSize: "13px" }}>
                            Rating
                          </div>
                          <div class="dropdwn" style={{ cursor: "pointer" }}>
                            <SelectBox />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: "20px" }}>
                      <div class="col-md-5">
                        <div class="sf-row">
                          <div class="lable-text" style={{ fontSize: "12px" }}>
                            Department
                          </div>
                          <div
                            class="dropdwn"
                            style={{
                              cursor: "pointer",
                              width: "65%",
                              marginLeft: "100px",
                            }}
                          >
                            <Select
                              styles={customStyles}
                              options={this.state.departmentArr}
                              components={{
                                DropdownIndicator,
                                IndicatorSeparator: () => null,
                              }}
                              value={this.state.departmentData}
                              placeholder="Select"
                              onChange={(value) => {
                                this.onDepartmentChange(value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal-backdrop fade show"
          id="backdrop"
          style={{ display: "none" }}
        ></div>

        <div>
          {/* <Button variant="outlined" color="primary" onClick={this.handleOpenShareModal}>
            Share
          </Button> */}
          <Dialog open={this.state.showShare} onClose={this.handleCloseShareModal} aria-labelledby="form-dialog-title" fullWidth
            maxWidth="md">
            <DialogTitle id="form-dialog-title">Share Your Link to external users</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Share Meeting Link
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Email Address"
                type="email"
                fullWidth
                value={this.state.shareEmailText}
                onKeyPress={this.onChangeEmail}
                onChange={
                  this.changeEmailText
                }
              />
              <div className="row">
                {this.state.allEmailArr.map((em, i) =>
                  <div className="col-md-4" style={{ paddingTop: '10px' }}>
                    <Card>
                      <Card.Body className="share_card_body">
                        {em}
                        <CloseButton style={{ float: 'right' }} onClick={() => this.deleteEmail(i)} />
                      </Card.Body>
                    </Card>

                  </div>)}
              </div>
              {/* <Card style={{ width: '18rem' }}>
                <Card.Body className="share_card_body">
                  sagarkumarhensh@gmail.com
                  <CloseButton style={{ float: 'right' }} />
                </Card.Body>
              </Card> */}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseShareModal} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleCloseShareModal} color="primary">
                Share
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <Modal
          show={this.state.viewNoteModal}
          onHide={this.closeViewNoteModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Modal.Body>
            {/* <Box sx={styleCancelModal}> */}
            <div className="doc_action_modal">
              <div className="edit_details" hidden={this.state.updateDetails} >
                <FiEdit size={25} onClick={this.changeUpdateDetails} />
              </div>
              <div className="vn_frm">
                <span
                  style={{
                    width: "35%",
                    fontSize: "15px",
                  }}
                >
                  Quantity
                </span>
                <input
                  placeholder="Quantity"
                  className="inputfield"
                  type="number"
                  value={this.state.billQty}
                  onChange={(e) =>
                    this.changeBillQty(e)
                  }
                  disabled={!this.state.updateDetails}
                />
              </div>
              <div className="vn_frm">
                <span
                  style={{
                    width: "35%",
                    fontSize: "15px",
                  }}
                >
                  Unit Price
                </span>

                <input
                  placeholder="unitPrice"
                  className="inputfield"
                  type="number"
                  value={this.state.billUnitPrice}
                  onChange={(e) =>
                    this.changeUnitPrice(e)
                  }
                  disabled={!this.state.updateDetails}
                />
              </div>
              <div className="vn_frm">
                <span
                  style={{
                    width: "35%",
                    fontSize: "15px",
                  }}
                >
                  Total Amount
                </span>
                <InputText
                  placeholder="amount"
                  className="inputfield"
                  value={this.state.updatedAmount}
                  // onTextChange={(value) => {
                  //   this.changeUpdateAmount(value);
                  // }}
                  disabled={true}
                />
              </div>
              <span className="tr_nl">
                <label>Description</label>
                <textarea
                  rows="2"
                  placeholder="Enter Descriptions ..."
                  className="in-textarea msg min"
                  value={this.state.viewNote}
                  style={{
                    height: "100px",
                    color: "var(--grey)",
                    borderRadius: "10px",
                    boxShadow: "2px",
                    resize: "none",
                  }}
                  onChange={this.changeViewDescription}
                  disabled={!this.state.updateDetails}
                ></textarea>
              </span>

              <div hidden={!this.state.updateDetails}>
                <label>Remarks</label>
                <textarea
                  rows="2"
                  placeholder="Enter Remarks ..."
                  className="in-textarea msg min"
                  value={this.state.billRemarks}
                  style={{
                    height: "100px",
                    color: "var(--grey)",
                    borderRadius: "10px",
                    boxShadow: "2px",
                    resize: "none",
                  }}
                  onChange={this.changeBillRemarks}
                  disabled={!this.state.updateDetails}
                ></textarea>
                {/* {this.state.viewNote} */}
              </div>
              <div className="edit_details_btn_sec" hidden={!this.state.updateDetails}>
                <Button onClick={this.closeViewNoteModal} color="primary">
                  Close
                </Button>
                <Button onClick={this.updateBillDetails} color="primary">
                  Update
                </Button>
              </div>
            </div>
            {/* </Box> */}

          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.rejectModal}
          onHide={this.closeRejectModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Modal.Header className="reject_payables_details" closeButton>
            <Modal.Title>Are you sure want to reject ? </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="">
              <span className="tr_nl">
                <label>Enter Reject Reason</label>
                <textarea
                  rows="2"
                  placeholder="Please type anything..."
                  className="in-textarea msg min"
                  value={this.state.rejectReason}
                  style={{
                    height: "100px",
                    color: "var(--grey)",
                    borderRadius: "10px",
                    boxShadow: "2px",
                    resize: "none",
                  }}
                  onChange={(e) => this.rejectReasonChange(e)}
                ></textarea>
                {/* {this.state.viewNote} */}
              </span>
              <Button onClick={this.closeRejectModal} color="primary">
                Close
              </Button>
              <Button onClick={this.submitReject} color="primary">
                Reject
              </Button>

            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.needAttentionModal}
          onHide={this.closeNeedAttentionModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Modal.Header closeButton>
            <Modal.Title>Need Attention</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="doc_action_modal">
              <div className="web-form-bx">
                <div className="frm-label">
                  Need Attention ? *
                </div>
                <div className="check-field">
                  <label className="checkbox_btn">
                    <input
                      type="radio"
                      name="radio1"
                      checked={this.state.updateNeedAttention}
                      value="1"
                      onChange={this.changeUpdateNeedAttention}
                    />
                    <span className="checkmark3"></span> Yes
                  </label>
                </div>
                <div className="check-field">
                  <label className="checkbox_btn">
                    <input
                      type="radio"
                      name="radio1"
                      checked={!this.state.updateNeedAttention}
                      value="0"
                      onChange={this.changeUpdateNeedAttention}
                    />
                    <span className="checkmark3"></span> No
                  </label>
                </div>
              </div>


            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="">
              <div className="text-center">
                <button
                  type="submit"
                  className="cn_btn"
                  onClick={() => this.closeNeedAttentionModal()}
                >
                  Close
                </button>
                {this.state.isBtnSubmit ? <button type="submit" className="sv_btn">
                  <i class="fa fa-spinner fa-spin"></i>
                </button> :
                  <button
                    type="submit"
                    className="sv_btn"
                    onClick={() => {
                      this.onSubmitNeedAttentionUpdate();
                    }}
                  >
                    Submit
                  </button>
                }
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.viewEmailModal}
          onHide={this.closeViewEmailModal}
          size="lg"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          centered
        >
          <Modal.Header className="email_details" closeButton>
            <Modal.Title>{this.state.viewEmailModalData.logType === 'Email' ? 'Email Data' : 'SMS Data'} </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="row">
              {this.state.viewEmailModalData.logType === 'Email' ?
                <div className="col-md-12">
                  <label>Sender : &nbsp;</label>
                  {this.state.viewEmailModalData.sender}
                </div> : <></>
              }
              <div className="col-md-12">
                <label>Sent To : &nbsp;</label>
                {this.state.viewEmailModalData.receiver}
              </div>
              {this.state.viewEmailModalData.logType === 'Email' ?
                <div className="col-md-12">
                  <label>Subject : &nbsp;</label>
                  {this.state.viewEmailModalData.subject}
                </div> : <></>
              }
              <div className="col-md-12">
                <span className="tr_nl">
                  {ReactHtmlParser(this.state.viewEmailModalData.content)}
                </span>
              </div>
              <Button onClick={this.closeViewEmailModal} color="primary">
                Close
              </Button>

            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.vendorRejectReasonModal}
          onHide={this.closeVendorRejectReasoModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          size="md"
          centered
        >
          <Modal.Header className="reject_payables_details" closeButton>
            <Modal.Title>Reject reason </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="">
              <span className="tr_nl">
                <label>Reject Reason</label>
                <textarea
                  rows="2"
                  placeholder="Please type anything..."
                  className="in-textarea msg min"
                  value={this.state.vendorRejectReasonData}
                  style={{
                    height: "100px",
                    color: "var(--grey)",
                    borderRadius: "10px",
                    boxShadow: "2px",
                    resize: "none",
                  }}
                  disabled={true}
                ></textarea>
                {/* {this.state.viewNote} */}
              </span>
              <Button onClick={this.closeVendorRejectReasoModal} color="primary">
                Close
              </Button>
            </div>
          </Modal.Body>
        </Modal>

      </React.Fragment >
    );
  }
}

class BidModal extends React.Component {
  constructor(props) {
    super(props);
    consoleLog("props::", this.props.value)
  }

  render() {
    return (
      <React.Fragment>
        <table width="100%" border="0" cellpadding="0" cellspacing="0">
          <tr>
            <th style={{ width: "14%" }}>Sunday</th>
            <th style={{ width: "14%" }}>Monday</th>
            <th style={{ width: "14%" }}>Tuesday</th>
            <th style={{ width: "14%" }}>Wednesday</th>
            <th style={{ width: "14%" }}>Thursday</th>
            <th style={{ width: "14%" }}>Friday</th>
            <th style={{ width: "14%" }}>Saturday</th>
          </tr>
          <tr>
            <td
              style={{
                justifyContent: "center",
              }}
            >
              {this.props.value.Sunday.map((obj) => (
                <React.Fragment>
                  <div>
                    {obj.serviceName.split(",").map((item) => (
                      <div className="f2f_rate">{item}</div>
                    ))}
                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "8px",
                      }}
                    >
                      {obj.startTime} - {obj.endTime}
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              ))}
            </td>
            {/* .............monday................. */}
            <td
              style={{
                justifyContent: "center",
              }}
            >
              {this.props.value.Monday.map((obj) => (
                <React.Fragment>
                  <div>
                    {obj.serviceName.split(",").map((item) => (
                      <div className="f2f_rate">{item}</div>
                    ))}
                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "8px",
                      }}
                    >
                      {obj.startTime} - {obj.endTime}
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              ))}
            </td>
            {/* ..............Tuesday................ */}
            <td
              style={{
                justifyContent: "center",
              }}
            >
              {this.props.value.Tuesday.map((obj) => (
                <React.Fragment>
                  <div>
                    {obj.serviceName.split(",").map((item) => (
                      <div className="f2f_rate">{item}</div>
                    ))}
                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "8px",
                      }}
                    >
                      {obj.startTime} - {obj.endTime}
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              ))}
            </td>
            {/* ..........wednesday............... */}
            <td
              style={{
                justifyContent: "center",
              }}
            >
              {this.props.value.Wednesday.map((obj) => (
                <React.Fragment>
                  <div>
                    {obj.serviceName.split(",").map((item) => (
                      <div className="f2f_rate">{item}</div>
                    ))}
                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "8px",
                      }}
                    >
                      {obj.startTime} - {obj.endTime}
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              ))}
            </td>
            {/* ................thursday............... */}
            <td
              style={{
                justifyContent: "center",
              }}
            >
              {this.props.value.Thursday.map((obj) => (
                <React.Fragment>
                  <div>
                    {obj.serviceName.split(",").map((item) => (
                      <div className="f2f_rate">{item}</div>
                    ))}
                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "8px",
                      }}
                    >
                      {obj.startTime} - {obj.endTime}
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              ))}
            </td>
            {/* ............friday.................... */}
            <td
              style={{
                justifyContent: "center",
              }}
            >
              {this.props.value.Friday.map((obj) => (
                <React.Fragment>
                  <div>
                    {obj.serviceName.split(",").map((item) => (
                      <div className="f2f_rate">{item}</div>
                    ))}
                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "8px",
                      }}
                    >
                      {obj.startTime} - {obj.endTime}
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              ))}
            </td>
            {/* ................saturday.......... */}
            <td
              style={{
                justifyContent: "center",
              }}
            >
              {this.props.value.Saturday.map((obj) => (
                <React.Fragment>
                  <div>
                    {obj.serviceName.split(",").map((item) => (
                      <div className="f2f_rate">{item}</div>
                    ))}
                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "8px",
                      }}
                    >
                      {obj.startTime} - {obj.endTime}
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              ))}
            </td>
          </tr>
        </table>
      </React.Fragment>
    );
  }
}
