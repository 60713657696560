import React, { Component } from "react";
import { styled, Box } from "@mui/system"; //imported for modal
import Switch from "@mui/material/Switch";

import "./clientRfqDetails.css";

import { AlertMessage, ImageName } from "../../../../enums";
import { ApiCall } from "../../../../services/middleware";
import { CryptoDecoder, Decoder } from "../../../../services/auth";
import { CommonData, ErrorCode } from "../../../../services/constant";
import { toast, ToastContainer } from "react-toastify";
import {
  inputEmptyValidate,
  mobileNumberValidator,
  numberValidator,
  zipValidate,
} from "../../../../validators";
import { Regex } from "../../../../services/config";
import Select, { components } from "react-select";
import { SmallSelectBox } from "../../SharedComponents/inputText";
import history from "../../../../history";
import { consoleLog, SetDateFormat, SetTimeFormat, SetUSAdateFormatV2 } from "../../../../services/common-function";
import ReactLoader from "../../../Loader";
import { Link } from "react-router-dom";
// import EditJob from "../../Manage_Interpretation_jobs/JobDetails/EditJobDetails";
import EditJobRFQ from "../../Manage_Interpretation_jobs/JobDetails/EditJobDetailsRFQ";
import moment from "moment";
import ChatGroupList from "./ChatGroupList";
import MainChatPage from "../../../ChatPages/Mainpage";
import Modal from 'react-bootstrap/Modal';
import { MultiSelectBox, SelectBox } from "../../SharedComponents/inputText";
import Button from '@mui/material/Button';
import { MdDelete } from "react-icons/md";
import { SignalCellularNullRounded } from "@material-ui/icons";






// .............................................................

export default class ClientRfqDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: true,
      requestId: "",
      clientFirstName: "",
      clientLastName: "",
      clientName: "",
      clientEmailId: "",
      clientPhone: "",
      clientIndustryType: "",
      //   .................jobDetails...........
      jobId: "",
      appointmentType: "",
      jobType: "",
      language: "",
      date: "",
      serviceAddress: "",
      siteContant: "",
      consumer: "",
      notesByClient: "",
      notesBy7C: "",
      scheduleTime: "",

      // ................sendQuote............
      quoteId: "",
      targetAdience: "",
      location: "",
      dateTime: "",
      notesFromClient: "",
      deliveryType: "",
      duration: "",
      rushFee: 0,
      interpretationFee: 0,
      bookingFee: 0,
      nightFee: 0,
      cancelFee: 0,
      weekendFee: 0,
      particularsArr: [],
      particular: "",
      particularAmount: [],
      totalParticularAmt: 0,
      particularAmt: 0,
      particularDataArr: [],
      particularAmountDataArr: [],
      totalAmount: 0,

      appointmentTypeArr: [],
      appointmentTypeData: [],
      languageArr: [],

      sourceLangData: {},
      targetLangData: {},
      listData: [],
      translationUnitCost: "",
      dtpUnitCost: "",
      translationRate: "",
      countryCode: "",
      otherService: "",
      otherServiceData: "",

      reqId: "",
      isApproveClient: false,
      isApproveLei: false,
      jobDetails: {},

      allJobNotes: [],
      notesText: "",
      requestId: "",

      allJobNotifications: [],
      chatGroup: [],
      selectedChatGroupValue: '',
      groupChatParticipant: [],
      chatParticipantModal: false,
      addGrouoChatParticipantModal: false,
      selectedChatGroupValue: '',
      addParticipantShow: false,
      chatGroupName: "",
      chatParticipants: [],
      selectedChatParticipants: [],
      selectedAddGroupChatParticipantValue: [],
      loader: false,
      isBtnSubmit: false,

    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.load();
    this.loaderChange()
  }

  loaderChange = async (type) => {
    this.setState({
      loader: type
    })
  }

  load = async () => {
    let mainData = this.props.location,
      preData = mainData.state;
    if (preData === undefined) {
      return history.push("/adminClientRfqList")
    } else {
      this.setState({
        reqId: preData.id
      })
      // consoleLog("PredataClientRFQ>>>>", preData);

      let languageArrData = [],
        languageObjData = {},
        languageResArrData = [],
        clientDetails = {},
        jobDetails = {},
        taskLanguage = "",
        quoteInfo = {},
        otherService = "",
        otherServiceData = "";;

      //............For Language Load All .............

      // let languageResData = await ApiCall("getlanguagelist");
      // let languagePayload = Decoder.decode(languageResData.data.payload);
      // languageResArrData = languagePayload.data.languagelist;

      // for (let n = 0; n < languageResArrData.length; n++) {
      //   languageArrData.push({
      //     label: languageResArrData[n].language,
      //     value: languageResArrData[n].id,
      //   });
      //   if (languageResArrData[n].language === "English") {
      //     languageObjData = {
      //       label: languageResArrData[n].language,
      //       value: languageResArrData[n].id,
      //     };
      //   }
      // }
      // .............................................

      this.setState({
        requestId: preData.id
      })

      let detailData = {
        requestId: preData.id,
      };

      let detailsRes = await ApiCall("getJobDetails", detailData);

      let detailPayload = Decoder.decode(detailsRes.data.payload);
      consoleLog("Interpreattion Job details>>>>>", detailPayload);
      if (detailPayload.data.clientDetails) {
        clientDetails = detailPayload.data.clientDetails;
      }
      if (detailPayload.data.jobDetails) {
        jobDetails = detailPayload.data.jobDetails;
        this.fetchJobQuoteData(jobDetails.id)
        this.fetchNotes(jobDetails.id);
        this.fetchProgressData(jobDetails.id);
        this.fetchChatGroup(jobDetails.id);
        this.fetchChatParticipants(jobDetails.id);

        let reqPayload = {
          requestId: jobDetails.clientId
        }
        let checkClientApprovalRes = await ApiCall("getUserApprovalStatus", reqPayload);
        try {
          if (
            checkClientApprovalRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            checkClientApprovalRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
          ) {
            const decodeData = Decoder.decode(checkClientApprovalRes.data.payload);
            // consoleLog("getUserApprovalStatus Payload data>>>", decodeData);
            if (decodeData.data.approvalStatus === 1) {
              this.setState({
                isApproveClient: true
              });

              let reqPayloadLei = {
                requestId: jobDetails.leiID
              }
              // console.log("LEI Request data >>>> ", reqPayloadLei)
              let checkLeiApprovalRes = await ApiCall("getUserApprovalStatus", reqPayloadLei);
              if (
                checkLeiApprovalRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
                checkLeiApprovalRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
              ) {
                const leiDecodeData = Decoder.decode(checkLeiApprovalRes.data.payload);
                if (leiDecodeData.data.approvalStatus === 1) {
                  this.setState({
                    isApproveLei: true
                  });
                }
              }
            }
          }
        } catch (e) {
          consoleLog(e);
        }

      }
      if (detailPayload.data.quoteInfo) {
        quoteInfo = detailPayload.data.quoteInfo;
      }
      if (detailPayload.data.taskList.length > 0) {
        // consoleLog("TaskList", detailPayload.data.taskList)
        taskLanguage = detailPayload.data.taskList[0].sourceLanguage + " --> " + detailPayload.data.taskList[0].targetLanguage
      } else {
        taskLanguage = detailPayload.data.jobDetails.sourceLanguage
      }

      if (jobDetails.appointmentType !== "F2F") {
        otherService = jobDetails.otherServices;
        otherServiceData = jobDetails.otherServicesData;
      }

      // ...........................................

      this.setState({
        // sourceLangData: languageObjData,
        // targetLangData: languageObjData,
        // languageArr: languageArrData,
        // .......... For Client Details..........
        clientFirstName: clientDetails.fName,
        clientLastName: clientDetails.lName,
        clientEmailId: clientDetails.businessEmail,
        clientName: clientDetails.clientName,
        clientIndustryType: clientDetails.industtryType,
        clientPhone: clientDetails.adminPhone,
        //............For Job Details  tab..........
        jobId: jobDetails.jobId,
        appointmentType: jobDetails.appointmentType,
        jobType: jobDetails.jobType,
        language: taskLanguage,
        date: jobDetails.scheduleDate,
        countryCode: clientDetails.adminCountryCode,
        serviceAddress:
          jobDetails.location === null ||
            jobDetails.location === undefined ||
            jobDetails.location === ""
            ? "N/A"
            : jobDetails.location,
        siteContant: jobDetails.siteContact === undefined ||
          jobDetails.siteContact === null ||
          jobDetails.siteContact === "" ? "N/A" : jobDetails.siteContact,
        consumer: jobDetails.consumer,
        notesByClient: jobDetails.noteByClient,
        notesBy7C: jobDetails.noteFor7C,
        quoteId: quoteInfo.quoteId,
        targetAdience:
          jobDetails.targetAudience === null ||
            jobDetails.targetAudience === undefined ||
            jobDetails.targetAudience === ""
            ? "N/A"
            : jobDetails.targetAudience,
        location:
          jobDetails.location === null ||
            jobDetails.location === undefined ||
            jobDetails.location === ""
            ? "N/A"
            : jobDetails.location,
        dateTime: jobDetails.scheduleDate,
        scheduleTime: jobDetails.scheduleTime,
        serviceProvider: jobDetails.noOfserviceProvider,
        notesFromClient: jobDetails.noteFor7C,
        deliveryType: clientDetails.isOutOfTown === 1 ? "OUT OF TOWN" : "IN TOWN",
        duration: jobDetails.duration,
        clientIndustryType: clientDetails.industtryType,
        otherService: otherService,
        otherServiceData: otherServiceData,
        jobDetails: jobDetails,
        isLoad: false,
      });
    }
  };

  // listApi = async (data) => {
  //   const res = await ApiCall("fetchapprovedclientcontactlist", data);
  //   console.log("resData::::", res);
  //   if (
  //     res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
  //     res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
  //   ) {
  //     const decodeData = Decoder.decode(res.data.payload);
  //     console.log("Payload data>>>", decodeData);
  //     let listDetails = decodeData.data.clientContactDetailsList;
  //     let totalPage = Math.ceil(decodeData.data.totalCount / this.state.limit);
  //     console.log("Total Page>>>", listDetails);
  //     this.setState({
  //       listData: decodeData.data.clientContactDetailsList,
  //       total_page: totalPage,
  //     });
  //   }
  // };

  onTranslationUnitCostChange = (e) => {
    let val = zipValidate(e.target.value);
    if (Regex.ONLY_15DIGITS_REGEX.test(+val)) {
      this.setState({
        translationUnitCost: val,
      });
    }
  };
  onTranslationRateChange = (e) => {
    let val = zipValidate(e.target.value);
    if (Regex.ONLY_15DIGITS_REGEX.test(+val)) {
      this.setState({
        translationRate: val,
      });
    }
  };

  onAppointmentChange = (data) => {
    this.setState({
      appointmentTypeData: data,
    });
  };

  onSourceLangChange = (data) => {
    this.setState({
      sourceLangData: data,
    });
  };

  onTargetLangChange = (data) => {
    this.setState({
      targetLangData: data,
    });
  };
  onParticularChange = (e, index) => {
    let amt = this.state.particularsArr,
      particularData = [],
      particularDataArr = [];
    for (let i = 0; i < this.state.particularsArr.length; i++) {
      if (i === index) {
        amt[i].title = e.target.value;
        particularDataArr.push(amt[i].title);
      }
      particularData.push(amt[i].title);
    }
    // console.log("amount==>", particularData);
    // console.log("particular==>", amt);

    this.setState({
      particularsArr: amt,
      particularDataArr: particularData,
    });
  };

  onParticularAmountChange = (e, index) => {
    const regex = /^[+-]?\d*\.?\d{0,3}$/;
    const newValue = e.target.value;
    if (regex.test(newValue)) {
      let data = e.target.value,
        particularAmtDataArr = [];
      var valid = !isNaN(data);
      let amount = e.target.value === "" ? 0 : parseFloat(data);
      let amt = this.state.particularsArr,
        rushfee = this.state.rushFee,
        interpretationFee = this.state.interpretationFee,
        totalAmt = 0,
        sum = 0;
      if (valid) {
        for (let i = 0; i < amt.length; i++) {
          if (i === index) {
            amt[i].amt = amount.toString();
            particularAmtDataArr.push(amt[i].amt);
          }
          sum = sum + parseFloat(amt[i].amt);
        }
        totalAmt = sum + parseFloat(rushfee) + parseFloat(interpretationFee);
      }
      this.setState({
        particularsArr: amt,
        totalParticularAmt: sum,
        totalAmount: totalAmt,
      });

    }
  };




  onRushFeeChange = (e) => {
    let totalAmt = 0;

    if (
      e.target.value !== "" &&
      !isNaN(e.target.value) &&
      e.target.value !== 0
    ) {
      totalAmt =
        totalAmt +
        parseFloat(e.target.value) +
        parseFloat(this.state.interpretationFee) + this.state.bookingFee + this.state.nightFee + this.state.cancelFee + this.state.weekendFee;
      this.setState({
        rushFee: parseFloat(e.target.value),
        totalAmount: this.calculateTotalAmount(totalAmt.toFixed(3))
      });
    } else {
      totalAmt =
        totalAmt +
        parseFloat(this.state.interpretationFee) + this.state.bookingFee + this.state.nightFee + this.state.cancelFee + this.state.weekendFee;
      this.setState({
        rushFee: 0,
        totalAmount: this.calculateTotalAmount(totalAmt.toFixed(3))
      });
    }
  };


  onInterpretationFeeChange = async (e) => {
    let totalAmt = 0;


    const newValue = e.target.value;
    const regex = /^[+-]?\d*\.?\d{0,3}$/;
    // console.log("e.target.value", e.target.value)
    if (regex.test(newValue)) {
      const parsedValue = parseFloat(newValue); // Convert input to a float
      if (newValue === "" || newValue === "0") {
        totalAmt =
          totalAmt +
          0 +
          parseFloat(this.state.rushFee) + this.state.bookingFee + this.state.nightFee + this.state.cancelFee + this.state.weekendFee;
        this.setState({
          interpretationFee: 0,
          totalAmount: this.calculateTotalAmount(totalAmt.toFixed(3))
        });
      } else {
        const parsedValue = parseFloat(newValue);
        if (!isNaN(parsedValue)) {
          await this.loaderChange(true);
          totalAmt =
            totalAmt +
            parseFloat(parsedValue) +
            parseFloat(this.state.rushFee) + this.state.bookingFee + this.state.nightFee + this.state.cancelFee + this.state.weekendFee;
          this.setState({
            interpretationFee: parsedValue,
            totalAmount: this.calculateTotalAmount(totalAmt.toFixed(3))
          });
          await this.loaderChange(false);
        }
      }
    }
  };





  onBookingFeeChange = async (e) => {
    let totalAmt = 0;


    const newValue = e.target.value;
    const regex = /^[+-]?\d*\.?\d{0,3}$/;
    // console.log("e.target.value", e.target.value)
    if (regex.test(newValue)) {
      const parsedValue = parseFloat(newValue); // Convert input to a float
      if (newValue === "" || newValue === "0") {
        totalAmt =
          totalAmt +
          0 +
          parseFloat(this.state.rushFee) + this.state.interpretationFee + this.state.nightFee + this.state.cancelFee + this.state.weekendFee;
        this.setState({
          bookingFee: 0,
          totalAmount: this.calculateTotalAmount(totalAmt.toFixed(3))
        });
      } else {
        const parsedValue = parseFloat(newValue);
        // console.log("Parsed Value >>>>> ", parsedValue)
        if (!isNaN(parsedValue)) {
          await this.loaderChange(true);
          totalAmt =
            totalAmt +
            parsedValue
            +
            parseFloat(this.state.rushFee) + this.state.interpretationFee + this.state.nightFee + this.state.cancelFee + this.state.weekendFee;
          // console.log("Total Amount >>>>> ", totalAmt)
          this.setState({
            bookingFee: parsedValue,
            totalAmount: this.calculateTotalAmount(totalAmt.toFixed(3))
          });
          await this.loaderChange(false);
        }
      }
    }
  };


  onNightFeeChange = async (e) => {
    let totalAmt = 0;


    const newValue = e.target.value;
    const regex = /^[+-]?\d*\.?\d{0,3}$/;
    // console.log("e.target.value", e.target.value)
    if (regex.test(newValue)) {
      const parsedValue = parseFloat(newValue); // Convert input to a float
      if (newValue === "" || newValue === "0") {
        totalAmt =
          totalAmt +
          0 +
          parseFloat(this.state.rushFee) + this.state.interpretationFee + this.state.bookingFee + this.state.cancelFee + this.state.weekendFee;
        this.setState({
          nightFee: 0,
          totalAmount: this.calculateTotalAmount(totalAmt)
        });
      } else {
        const parsedValue = parseFloat(newValue);
        if (!isNaN(parsedValue)) {
          await this.loaderChange(true);
          totalAmt =
            totalAmt +
            parseFloat(parsedValue) +
            parseFloat(this.state.rushFee) + this.state.interpretationFee + this.state.bookingFee + this.state.cancelFee + this.state.weekendFee;
          this.setState({
            nightFee: parsedValue,
            totalAmount: this.calculateTotalAmount(totalAmt)
          });
          await this.loaderChange(false);
        }
      }
    }
  };


  onWeekendFeeChange = async (e) => {
    let totalAmt = 0;


    const newValue = e.target.value;
    const regex = /^[+-]?\d*\.?\d{0,3}$/;
    // console.log("e.target.value", e.target.value)
    if (regex.test(newValue)) {
      const parsedValue = parseFloat(newValue); // Convert input to a float
      if (newValue === "" || newValue === "0") {
        totalAmt =
          totalAmt +
          0 +
          parseFloat(this.state.rushFee) +
          this.state.interpretationFee +
          this.state.nightFee + this.state.cancelFee + this.state.bookingFee;
        this.setState({
          weekendFee: 0,
          totalAmount: this.calculateTotalAmount(totalAmt)
        });
      } else {
        const parsedValue = parseFloat(newValue);
        if (!isNaN(parsedValue)) {
          await this.loaderChange(true);
          totalAmt =
            totalAmt +
            parseFloat(parsedValue) +
            parseFloat(this.state.rushFee) + this.state.interpretationFee + this.state.nightFee + this.state.cancelFee + this.state.bookingFee;
          this.setState({
            weekendFee: parsedValue,
            totalAmount: this.calculateTotalAmount(totalAmt)
          });
          await this.loaderChange(false);
        }
      }
    }
  };

  calculateTotalAmount = (val) => {
    let particularAmt = 0;
    let particularArray = this.state.particularsArr;
    for (let i = 0; i < particularArray.length; i++) {
      particularAmt = particularAmt + Number(particularArray[i].amt);
    }

    let total = Number(val) + particularAmt;
    return total;

  }


  addParticularField = () => {
    let arr = this.state.particularsArr;
    // particularItem = "",
    // particularAmnt = 0;
    // for (let i = 0; i < this.state.particularsArr.length; i++) {
    arr.push({
      title: this.state.particular,
      // particularAmt: parseInt(this.state.particularAmt),
      amt: this.state.particularAmt,
    });

    this.setState({
      particularsArr: arr,
    });
  };


  onDeleteParticulars = (index) => {
    // console.log("index no:", index);
    let particularArray = this.state.particularsArr;

    let arr = [],
      amt = 0,
      particularAmt = 0;
    for (let i = 0; i < particularArray.length; i++) {
      if (i != index) {
        particularAmt = particularAmt + parseFloat(particularArray[i].amt);
        arr.push(particularArray[i]);
      }
    }
    amt =
      amt +
      particularAmt + this.state.bookingFee + this.state.nightFee + this.state.cancelFee + this.state.weekendFee +
      parseFloat(this.state.rushFee) +
      parseFloat(this.state.interpretationFee);

    // console.log("_____+++++", arr);

    this.setState({
      particularsArr: arr,
      totalAmount: amt.toFixed(2),
      totalParticularAmt: particularAmt.toFixed(2),
    });
  };

  onCreateQuote = async () => {
    let mainData = this.props.location;
    let preData = mainData.state;
    let errorCount = 0;

    let validateInterpretationFee = inputEmptyValidate(
      this.state.interpretationFee
    );

    // if (validateInterpretationFee === false) {
    // if (this.state.interpretationFee === 0) {
    //   toast.error(AlertMessage.MESSAGE.JOB.EMPTY_FEE, {
    //     hideProgressBar: true,
    //   });
    //   errorCount++;
    // }

    if (errorCount === 0) {
      this.setState({
        isBtnSubmit: true
      })
      let data = {
        requestId: preData.id,
        fee: this.state.interpretationFee.toString(),
        total: this.state.totalAmount.toString(),
        rushFee: this.state.rushFee.toString(),
        // additionalFee: [{ title: "add", amt: "35.7" }],
        additionalFee: this.state.particularsArr,
      };
      consoleLog("req send Quote data", data);
      let res = await ApiCall("createQuote", data);
      // console.log("%%%%%%%%)))))))))", res);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        toast.success(AlertMessage.MESSAGE.JOB.QUOTE_SEND, {
          hideProgressBar: true,
        });
        this.setState({ isBtnSubmit: false })
        return history.push("/adminViewAllJobs");
      } else {
        this.setState({ isBtnSubmit: false })
        if (
          res.error === ErrorCode.ERROR.ERROR.WITH_ERROR &&
          res.respondcode === ErrorCode.ERROR.ERROR_CODE.EMAIL_EXIST
        ) {
          toast.error(AlertMessage.MESSAGE.EMAIL.EMAIL_EXIST, {
            hideProgressBar: true,
          });
        } else if (
          res.error === ErrorCode.ERROR.ERROR.WITH_ERROR &&
          res.respondcode === ErrorCode.ERROR.ERROR_CODE.PHONE_EXIST
        ) {
          toast.error(AlertMessage.MESSAGE.MOBILE.MOBILE_EXIST, {
            hideProgressBar: true,
          });
        } else if (
          res.error === ErrorCode.ERROR.ERROR.WITH_ERROR &&
          res.respondcode === ErrorCode.ERROR.ERROR_CODE.EMAIL_PHONE_EXIST
        ) {
          toast.error(AlertMessage.MESSAGE.EMAIL_MOBILE.DUBLICATE, {
            hideProgressBar: true,
          });
        }
      }
    }
  };

  goClientApprovalPage = (value) => {
    if (value === "client") {
      return history.push("/adminClientRequstList", { state: { formType: "rfq" } });
    } else {
      return history.push("/adminContactsRequest", { state: { formType: "rfq" } });
    }
  };

  fetchNotes = async (id) => {
    let apiObj = {
      requestId: id
    };
    // consoleLog("FETCH NOTES REQ >>> ", apiObj)
    let res = await ApiCall("fetchInternalNotes", apiObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      // consoleLog("FETCH NOTES Job", payload.data);
      this.setState({
        allJobNotes: payload.data.notesData
      })
    }
  }

  addNotes = async () => {
    let errorCounter = 0;
    if (this.state.notesText.trim() === "") {
      toast.error("Please input some text");
      errorCounter++;
      return false;
    }

    if (errorCounter === 0) {
      let apiObj = {
        requestId: this.state.requestId,
        notes: this.state.notesText,
      };
      // consoleLog("ADD NOTES REQ >>> ", apiObj)
      let res = await ApiCall("addInternalNotes", apiObj);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        this.fetchNotes(this.state.requestId);
        toast.success(AlertMessage.MESSAGE.NOTES.ADD_SUCCESS);
        this.setState({
          notesText: ""
        })
      }
    }
  }

  changeInternalNotes = (e) => {
    this.setState({
      notesText: e.target.value
    })
  }






  fetchProgressData = async (id) => {
    let obj = {
      jobId: id
    }

    let res = await ApiCall("fetchProgressLog", obj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      if (payload.data.progressData.length > 0) {
        let notiArr = [];
        payload.data.progressData.map((item) => {
          notiArr.push({
            body: item.progressType,
            time: moment(item.createDate).fromNow()
          })
        })
        this.setState({
          allJobNotifications: notiArr
        })
      } else {
        this.setState({
          allJobNotifications: []
        })
      }
    }

  }



  openParticipantModal = () => {
    this.setState({
      addParticipantShow: true
    })
  }


  fetchChatGroup = async (id) => {
    let apiObj = {
      jobId: id
    };

    let res = await ApiCall("fetchChatJoinRequest", apiObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      let arr = [];
      if (payload.data.length > 0) {
        payload.data.map((item, i) => {
          let obj = {
            label: item.jobId + " - " + item.groupName,
            value: item
          }
          arr.push(obj)

        })
      }
      this.setState({
        chatGroup: arr
      })
    }
  }

  changeChatGroup = (value) => {
    let data = localStorage.getItem("AuthToken");
    let authUser = Decoder.decode(data);
    // consoleLog("Group Value >>> ", value.participantId);
    let temp = [];
    for (let i = 0; i < value.participantId.length; i++) {
      if (authUser.data.userid !== value.participantId[i])
        temp.push({
          label: value.participantName[i],
          value: value.participantId[i],
          actualId: value.participantActulaId[i]
        })
    }
    // consoleLog("Temp Arr >>> ", temp)
    this.setState({
      selectedChatGroupValue: value,
      groupChatParticipant: temp
    })
    this.showChatPage(value)
  }



  showChatPage = (value) => {
    return value !== "" ? <>
      <div className="prticipants-area _fl">
        <div className="row">
          <div className="col-md-6">
            <h3><span style={{ cursor: "pointer" }} onClick={() => this.openChatParticipantModal()}>{value.participantId.length} Participant</span></h3>
          </div>

          <div className="col-md-6">
            <a href="javascript:void(0)" className="add-part-btn" onClick={this.openAddGroupChatParticipantModal}>
              + Add Participants
            </a>
          </div>
        </div>
      </div>
      <MainChatPage jobId={value._id} />
    </> : <></>

  }

  openChatParticipantModal = () => {
    this.setState({
      chatParticipantModal: true
    })
  }

  closeChatParticipantModal = () => {
    this.setState({
      chatParticipantModal: false
    })
  }

  openAddGroupChatParticipantModal = () => {
    this.setState({
      addGrouoChatParticipantModal: true
    })
  }

  closeAddGroupChatParticipantModal = () => {
    this.setState({
      addGrouoChatParticipantModal: false
    })
  }


  openParticipantModal = () => {
    this.setState({
      addParticipantShow: true
    })
  }

  closeParticipantModal = () => {
    this.setState({
      addParticipantShow: false
    })
  }

  changeChatGroupName = (e) => {
    this.setState({
      chatGroupName: e.target.value
    })
  }

  onSelectChatParticipants = (value) => {
    this.setState({
      selectedChatParticipants: value
    })
  }


  submitParticipants = async () => {
    let errorCounter = 0;
    if (this.state.chatGroupName === "") {
      errorCounter++;
      toast.error(AlertMessage.MESSAGE.CHAT.BLANK_GROUP_NAME);
      return false;
    } else if (this.state.selectedChatParticipants.length === 0) {
      errorCounter++;
      toast.error(AlertMessage.MESSAGE.CHAT.BLANK_PARTICIPANTS);
      return false;
    }

    if (errorCounter === 0) {
      let req = {
        groupName: this.state.chatGroupName,
        participants: this.state.selectedChatParticipants,
        jobId: this.state.jobId,
        requestId: this.state.requestId
      }

      let res = await ApiCall("submitChatJoinRequest", req);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        this.fetchChatGroup(this.state.requestId)
        this.closeParticipantModal();
        toast.success("Group initiated successfully");
        this.setState({
          chatGroupName: "",
          selectedChatParticipants: []
        })
      } else {
        toast.error("Some Error Occured !!!");
      }
    }
  }


  selectAddGroupParticipants = (value) => {
    let arr = [];
    for (let data of value) {
      for (let item of this.state.groupChatParticipant) {
        if (data.id === item.actualId) {
          toast.error(AlertMessage.MESSAGE.CHAT.ALREADY_HAVE_PARTICIPANTS);
          arr = [];
        } else {
          arr.push(data);
          consoleLog("add Arr >>> ", arr)
        }
      }
    }
    this.setState({
      selectedAddGroupChatParticipantValue: arr
    })
  }

  submitParticipantsInGroup = async () => {
    this.closeAddGroupChatParticipantModal();
  }

  removeParticipants = async (value) => {
    if (this.state.groupChatParticipant.length > 1) {
      let apiObj = {
        id: this.state.selectedChatGroupValue._id,
        participantName: value.label,
        participantId: value.value,
        participantActualId: value.actualId
      };
      let res = await ApiCall("removeParticipantFromGroup", apiObj);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        let payload = Decoder.decode(res.data.payload);
        this.fetchChatGroup(this.state.requestId);
        this.closeChatParticipantModal();
        toast.success(AlertMessage.MESSAGE.CHAT.REMOVE_PARTICIPANTS);
      }
    } else {
      toast.error(AlertMessage.MESSAGE.CHAT.NOT_REMOVE_PARTICIPANTS)
    }

  }

  fetchChatParticipants = async (id) => {
    let apiObj = {
      jobId: id
    };
    let res = await ApiCall("getChatParticipantList", apiObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let arr = [];
      let payload = Decoder.decode(res.data.payload);

      payload.data.participants.map((pp) => {
        arr.push({
          label: pp.name + " ( " + pp.userId + " - " + this.getUserType(pp.userTypeId) + " )",
          value: pp.userId,
          userTypeId: pp.userTypeId,
          id: pp.id
        });
      })
      payload.data.staffList.map((st) => {
        arr.push({
          label: st.name + " (" + st.userId + " - Staff)",
          value: st.userId,
          userTypeId: st.userTypeId,
          id: st.id
        });
      });


      this.setState({
        chatParticipants: arr
      })
    }
  }

  fetchJobQuoteData = async (id) => {
    let apiObj = {
      requestId: id
    };
    let res = await ApiCall("getFeesByRequestId", apiObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      consoleLog("Job Fees Data >>> ", payload.data);
      if (payload.data.data) {
        let allFees = payload.data.data;
        this.setState({
          interpretationFee: allFees.fee,
          rushFee: allFees.rushFee,
          nightFee: allFees.nightFee,
          weekendFee: allFees.weekendFee,
          totalAmount: allFees.total
        })
      }
    }
  }

  getUserType = (id) => {
    let str = "";
    if (id == 3) {
      str = "Client";
    } else if (id == 4) {
      str = "vendor";
    } else {
      str = "Client Requester"
    }

    return str;
  }



  render() {
    // const open = Boolean(this.state.anchorEl); //used in MenuButton open
    // const open1 = Boolean(this.state.anchorEl1);
    return (
      <React.Fragment>
        <ToastContainer hideProgressBar theme="colored" />
        <div class="component-wrapper" hidden={!this.state.isLoad}>
          <ReactLoader />
        </div>
        <div className="component-wrapper" hidden={this.state.isLoad}>
          <div
            className="vn_frm"
            style={{ color: "grey", paddingBottom: "2%", paddingTop: "5%" }}
          >
            <Link to="/adminDashboard">Dashboard</Link> / <Link to="/adminClientRfqList">Client RFQ</Link> / Details

          </div>
          <div className="job-details-tab jobdltste _fl sdw">
            <ul className="nav nav-tabs" style={{ justifyContent: "left" }}>
              <li className="nav-item">
                {" "}
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#jobdetails"
                >
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.JOB_DETAILS} />
                    </figure>{" "}
                    Job Details
                  </div>
                </a>{" "}
              </li>
              <li className="nav-item">
                {" "}
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#editjobdetails"
                >
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.JOB_DETAILS} />
                    </figure>{" "}
                    Edit Job Details
                  </div>
                </a>{" "}
              </li>
              {/* <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#sendqute">
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.TABBAR} />
                    </figure>
                    Send Quote
                  </div>
                </a>{" "}
              </li> */}
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#clientdetails">
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.TAB_USER_ICON} />
                    </figure>
                    Client Details
                  </div>
                </a>{" "}
              </li>
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#chattab">
                  <div className="taber">
                    <figure>
                      <img
                        src={ImageName.IMAGE_NAME.CHAT_ICON}
                        style={{ padding: "10px", width: "48px" }}
                      />
                    </figure>
                    Chat{" "}
                  </div>
                </a>{" "}
              </li>
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#nofifications">
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.NOTIFICATION_ICON} />
                    </figure>
                    Notifications
                  </div>
                </a>{" "}
              </li>
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#notetab">
                  <div className="taber">
                    <figure>
                      <img
                        src={ImageName.IMAGE_NAME.CHAT_ICON}
                        style={{ padding: "10px", width: "48px" }}
                      />
                    </figure>
                    Notes{" "}
                  </div>
                </a>{" "}
              </li>
              {/* <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#chattab">
                  <div className="taber">
                    <figure>
                      <img
                        src={ImageName.IMAGE_NAME.CHAT_ICON}
                        style={{ padding: "10px", width: "48px" }}
                      />
                    </figure>
                    Chat{" "}
                  </div>
                </a>{" "}
              </li>
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#nofifications">
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.NOTIFICATION_ICON} />
                    </figure>
                    Notifications
                  </div>
                </a>{" "}
              </li> */}
            </ul>

            <div className="tab-content">
              <div className="tab-pane  active" id="jobdetails">
                <div className="job-section-tab">
                  <table
                    width="100%"
                    cellspacing="0"
                    cellpadding="0"
                    border="0"
                  >
                    <tbody>
                      <tr>
                        <td width="50%" align="left">
                          Job ID
                        </td>
                        <th width="50%" align="right">
                          {this.state.jobId}
                        </th>
                      </tr>

                      <tr>
                        <td width="50%" align="left">
                          Appointment Type
                        </td>
                        <th width="50%" align="right">
                          <div className="f2f-b">
                            {this.state.appointmentType}
                          </div>
                        </th>
                      </tr>

                      <tr>
                        <td width="50%" align="left">
                          Job Type
                        </td>
                        <th width="50%" align="right">
                          {this.state.jobType}
                        </th>
                      </tr>

                      <tr>
                        <td width="50%" align="left">
                          Language
                        </td>
                        <th width="50%" align="right">
                          {this.state.language}
                        </th>
                      </tr>
                      <tr>
                        <td width="50%" align="left">
                          Date
                        </td>
                        <th width="50%" align="right">
                          {SetDateFormat(this.state.date)} {this.state.scheduleTime}
                        </th>
                      </tr>

                      <tr>
                        <td width="50%" align="left">
                          Service Location Address
                        </td>
                        <th width="50%" align="right">
                          {this.state.serviceAddress}
                        </th>
                      </tr>

                      <tr>
                        <td width="50%" align="left">
                          No. of Interpreters
                        </td>
                        <th width="50%" align="right">
                          {this.state.serviceProvider}
                        </th>
                      </tr>

                      <tr>
                        <td width="50%" align="left">
                          Duration
                        </td>
                        <th width="50%" align="right">
                          {this.state.duration}
                        </th>
                      </tr>

                      <tr>
                        <td width="50%" align="left">
                          Site Contact
                        </td>
                        <th width="50%" align="right">
                          {this.state.siteContant}
                        </th>
                      </tr>

                      <tr>
                        <td width="50%" align="left">
                          Customer
                        </td>
                        <th width="50%" align="right">
                          {this.state.consumer}
                        </th>
                      </tr>
                      <tr>
                        <td width="50%" align="left">
                          Notes by Client
                        </td>
                        <th width="50%" align="right">
                          &nbsp;{this.state.notesFromClient}
                        </th>
                      </tr>
                      {this.state.appointmentType !== "F2F" ? <React.Fragment>
                        <tr>
                          <td width="50%" align="left">
                            <p class="notes">
                              <span>7C Lingo</span> voice/video services
                            </p>
                          </td>
                          <th width="50%" align="right">
                            &nbsp;{this.state.otherService === 1 ? "Yes" : "No"}
                          </th>
                        </tr>
                        {this.state.otherService === 0 ? <React.Fragment>
                          <tr>
                            <td width="50%" align="left">
                              3rd party services
                            </td>
                            <th width="50%" align="right">
                              &nbsp;{this.state.otherServiceData}
                            </th>
                          </tr>
                        </React.Fragment> : <React.Fragment></React.Fragment>
                        }
                      </React.Fragment> : <React.Fragment></React.Fragment>
                      }
                      {/* <tr>
                        <td width="50%" align="left">
                          <p class="notes">
                            Notes From <span>7C Lingo</span>
                          </p>
                        </td>
                        <th width="50%" align="right">
                          &nbsp;{this.state.notesBy7C}
                        </th>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* ................for edit job details page................. */}
              <div className="tab-pane" id="editjobdetails">
                {!this.state.isApproveClient ?
                  <div className="job-section-tab">
                    <div class="approve_client_box">
                      <p class="centered-text">Please approve the client first</p>
                      <button class="centered-button " onClick={() => this.goClientApprovalPage("client")}>Go to Client Approval</button>
                    </div>
                  </div> : !this.state.isApproveLei ?
                    <div className="job-section-tab">
                      <div class="approve_client_box">
                        <p class="centered-text">Please Approve the LEI </p>
                        <button class="centered-button " onClick={() => this.goClientApprovalPage("lei")}>Go to LEI Approval</button>
                      </div>
                    </div> :
                    <div className="job-section-tab">
                      {/* <EditJob mainData={this.state.reqId} /> */}
                      <EditJobRFQ mainData={this.state.reqId} />
                    </div>
                }
              </div>

              <div className="tab-pane" id="sendqute">
                {!this.state.isApproveClient ?
                  <div className="job-section-tab">
                    <div class="approve_client_box">
                      <p class="centered-text">Please approve the client first</p>
                      <button class="centered-button " onClick={() => this.goClientApprovalPage("client")}>Go to Client Approval</button>
                    </div>
                  </div> : !this.state.isApproveLei ?
                    <div className="job-section-tab">
                      <div class="approve_client_box">
                        <p class="centered-text">Please Approve the LEI </p>
                        <button class="centered-button " onClick={() => this.goClientApprovalPage("lei")}>Go to LEI Approval</button>
                      </div>
                    </div> :
                    <div className="job-section-tab">
                      <div className="view-quote-details-wrap">
                        <h3>view quote details</h3>
                        {/* <p>
                      <span>Quote ID</span>
                      {this.state.quoteId}
                    </p> */}
                        {/* <p>
                      <span>Target Audience</span> {this.state.targetAdience}
                    </p> */}
                        <p>
                          <span>Location</span> {this.state.location}
                        </p>
                        <p>
                          <span>Appontment Type</span> {this.state.appointmentType}
                        </p>
                        <p>
                          <span>Date & Time</span>{" "}
                          {SetDateFormat(this.state.dateTime)} {this.state.scheduleTime}
                        </p>
                        <p>
                          <span>Notes from Client</span>
                          {this.state.notesFromClient}
                        </p>
                        <p>
                          <span>Industry Type</span> {this.state.clientIndustryType}
                        </p>
                        <p>
                          <span>Language</span> {this.state.language}
                        </p>
                      </div>
                      <div className="add-more-field">
                        <div className="table-listing-app create">
                          <div className="creat_quot">
                            <h3>create quote</h3>
                            <div className="depr_table">
                              <div className="table-responsive_mb">
                                <div class="addmore_service text-right">
                                  <a href="javascript:void(0)">
                                    <div
                                      onClick={this.addParticularField}
                                      style={{ marginTop: "8px" }}
                                    >
                                      Add Additional Field
                                    </div>
                                  </a>
                                </div>
                                <table
                                  width="100%"
                                  cellspacing="0"
                                  cellpadding="0"
                                  border="0"
                                >
                                  <tbody>
                                    {this.state.appointmentType === "F2F" ? (
                                      <tr>
                                        <th width="50%" align="left">
                                          Delivery Type
                                        </th>
                                        <th width="50%" align="right">
                                          {this.state.deliveryType}
                                        </th>
                                      </tr>
                                    ) : (
                                      <React.Fragment />
                                    )}

                                    {/* <tr>
                                      <td width="50%" align="left">
                                        No. of interpreter
                                      </td>
                                      <td width="50%" align="right">
                                        {this.state.serviceProvider}
                                      </td>
                                    </tr> */}

                                    <tr>
                                      <td width="50%" align="left">
                                        Duration
                                      </td>
                                      <td width="50%" align="right">
                                        {this.state.duration}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td width="50%" align="left">
                                        Rush Fee
                                      </td>
                                      <td width="50%" align="right">
                                        <div className="row">
                                          <div className="col-md-6"></div>
                                          <div className="col-md-6">
                                            <div
                                              className="input-group"
                                              style={{ justifyContent: "end" }}
                                            >
                                              <div class="input-group-prepend">
                                                <span
                                                  class="input-group-text dollar"
                                                  id="basic-addon1"
                                                >
                                                  $
                                                </span>
                                              </div>

                                              <input
                                                className="inputfield flr"
                                                type="number"
                                                value={this.state.rushFee}
                                                style={{ width: "75%" }}
                                                onChange={(e) => { this.onRushFeeChange(e) }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        {/* <input
                                      className="inputfield flr"
                                      type="text"
                                      value={this.state.rushFee}
                                      onChange={this.onRushFeeChange}
                                    /> */}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td width="50%" align="left">
                                        Interpretation Fee
                                      </td>
                                      <td width="50%" align="right">
                                        <div className="row">
                                          <div className="col-md-6"></div>
                                          <div className="col-md-6">
                                            <div
                                              className="input-group"
                                              style={{ justifyContent: "end" }}
                                            >
                                              <div class="input-group-prepend">
                                                <span
                                                  class="input-group-text dollar"
                                                  id="basic-addon1"
                                                >
                                                  $
                                                </span>
                                              </div>

                                              <input
                                                className="inputfield flr"
                                                type="number"
                                                value={this.state.interpretationFee}
                                                style={{ width: "75%" }}
                                                onChange={(e) => this.onInterpretationFeeChange(e)}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        {/* <input
                                      className="inputfield flr"
                                      type="text"
                                      value={this.state.interpretationFee}
                                      onChange={(e) =>
                                        this.onInterpretationFeeChange(e)
                                      }
                                    /> */}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td width="50%" align="left">
                                        Booking Fee
                                      </td>
                                      <td width="50%" align="right">
                                        <div className="row">
                                          <div className="col-md-6"></div>
                                          <div className="col-md-6">
                                            <div
                                              className="input-group"
                                              style={{ justifyContent: "end" }}
                                            >
                                              <div class="input-group-prepend">
                                                <span
                                                  class="input-group-text dollar"
                                                  id="basic-addon1"
                                                >
                                                  $
                                                </span>
                                              </div>

                                              <input
                                                className="inputfield flr"
                                                type="number"
                                                value={this.state.bookingFee}
                                                style={{ width: "75%" }}
                                                onChange={(e) =>
                                                  this.onBookingFeeChange(e)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="50%" align="left">
                                        Night Fee
                                      </td>
                                      <td width="50%" align="right">
                                        <div className="row">
                                          <div className="col-md-6"></div>
                                          <div className="col-md-6">
                                            <div
                                              className="input-group"
                                              style={{ justifyContent: "end" }}
                                            >
                                              <div class="input-group-prepend">
                                                <span
                                                  class="input-group-text dollar"
                                                  id="basic-addon1"
                                                >
                                                  $
                                                </span>
                                              </div>

                                              <input
                                                className="inputfield flr"
                                                type="number"
                                                value={this.state.nightFee}
                                                style={{ width: "75%" }}
                                                onChange={(e) =>
                                                  this.onNightFeeChange(e)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="50%" align="left">
                                        Weekend Fee
                                      </td>
                                      <td width="50%" align="right">
                                        <div className="row">
                                          <div className="col-md-6"></div>
                                          <div className="col-md-6">
                                            <div
                                              className="input-group"
                                              style={{ justifyContent: "end" }}
                                            >
                                              <div class="input-group-prepend">
                                                <span
                                                  class="input-group-text dollar"
                                                  id="basic-addon1"
                                                >
                                                  $
                                                </span>
                                              </div>

                                              <input
                                                className="inputfield flr"
                                                type="number"
                                                value={this.state.weekendFee}
                                                style={{ width: "75%" }}
                                                onChange={(e) =>
                                                  this.onWeekendFeeChange(e)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    {this.state.particularsArr.map((item, key) => (
                                      <tr>
                                        <td width="50%" align="left">
                                          <input
                                            className="inputfield flr"
                                            type="text"
                                            placeholder="Particulars"
                                            value={item.title}
                                            onChange={(e) =>
                                              this.onParticularChange(e, key)
                                            }
                                          />
                                        </td>
                                        <td width="50%" align="right">
                                          <div className="row">
                                            <div className="col-md-5"></div>
                                            <div className="col-md-6">
                                              <div
                                                className="input-group"
                                                style={{ justifyContent: "end" }}
                                              >
                                                <div class="input-group-prepend">
                                                  <span
                                                    class="input-group-text dollar"
                                                    id="basic-addon1"
                                                  >
                                                    $
                                                  </span>
                                                </div>

                                                <input
                                                  className="inputfield flr"
                                                  type="number"
                                                  placeholder="Enter Amount"
                                                  value={item.amt}
                                                  style={{ width: "75%" }}
                                                  onChange={(e) =>
                                                    this.onParticularAmountChange(
                                                      e,
                                                      key
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-1 delete-btn">
                                              <img
                                                src={ImageName.IMAGE_NAME.TRASH_BTN}
                                                onClick={() =>
                                                  this.onDeleteParticulars(key)
                                                }
                                                style={{ cursor: "pointer" }}
                                              />
                                            </div>
                                          </div>
                                          {/* <input
                                        className="inputfield flr"
                                        type="text"
                                        placeholder="Enter Amount"
                                        value={item.amt}
                                        onChange={(e) =>
                                          this.onParticularAmountChange(e, key)
                                        }
                                      />
                                      &nbsp;{" "}
                                      <span>
                                        <img
                                          src={ImageName.IMAGE_NAME.TRASH_BTN}
                                          onClick={() =>
                                            this.onDeleteParticulars(key)
                                          }
                                          style={{ cursor: "pointer" }}
                                        />
                                      </span> */}
                                        </td>
                                      </tr>
                                    ))}

                                    <tr>
                                      <td
                                        width="50%"
                                        align="left"
                                        style={{ color: "#5ea076" }}
                                      >
                                        Total Payable
                                      </td>
                                      <td
                                        width="50%"
                                        align="right"
                                        style={{ color: "#5ea076" }}
                                      >
                                        $ {parseFloat(this.state.totalAmount) * Number(this.state.serviceProvider)}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="translation_table">
                    <div class="tsk-col _fl m30 p-20">
                      <h3>Task 1 Translation</h3>
                      <ul>
                        <li>
                          <a href="#" style={{ textDecoration: "none" }}>
                            English
                          </a>
                        </li>
                        <li>
                          <a href="#" style={{ textDecoration: "none" }}>
                            Spanish
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="tsk-tabl">
                      <table
                        width="100%"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                      >
                        <tr>
                          <th style={{ width: "33%", textAlign: "center" }}>
                            ITEM(S)
                          </th>
                          <th style={{ width: "33%", textAlign: "center" }}>
                            UNIT COST/WORD
                          </th>
                          <th style={{ width: "33%", textAlign: "center" }}>
                            RATE
                          </th>
                        </tr>

                        <tr>
                          <td style={{ textAlign: "left" }}>Free</td>
                          <td style={{ textAlign: "center" }}>
                            <input
                              type="text"
                              value={this.state.translationUnitCost}
                              name=""
                              placeholder="$000"
                              class="in-field4"
                              onChange={this.onTranslationUnitCostChange}
                            />
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <input
                              type="text"
                              value={this.state.translationRate}
                              name=""
                              placeholder="$000"
                              class="in-field4"
                              onChange={this.onTranslationRateChange}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td style={{ textAlign: "left" }}>SUB COST</td>
                          <td style={{ textAlign: "center" }}>&nbsp;</td>
                          <td style={{ textAlign: "center" }} class="text-ttt">
                            $6650.000
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div> */}
                      <div className="_button-style m30 _fl text-center">
                        <a
                          href="javascript:void(0)"
                          className="white-btn"
                          style={{ textDecoration: "none" }}
                          onClick={() => {
                            return history.push("/adminClientRfqList")
                          }}
                        >
                          Back
                        </a>
                        {this.state.isBtnSubmit ?
                          <a
                            href="javascript:void(0)"
                            className="blue-btn"
                            style={{ textDecoration: "none", color: "#fff" }}
                          >
                            <i class="fa fa-spinner fa-spin"></i>
                          </a> :
                          <a
                            href="javascript:void(0)"
                            className="blue-btn"
                            style={{ textDecoration: "none", color: "#fff" }}
                            onClick={this.onCreateQuote}
                          >
                            Send Quote
                          </a>
                        }
                      </div>
                    </div>
                }
              </div>

              <div className="tab-pane" id="clientdetails">
                <div className="job-section-tab">
                  <h3>Client Information</h3>
                  <div className="c-l-s _fl">
                    <div className="row">
                      <div className="col-md-4">
                        <h4>First Name</h4>
                        <p>{this.state.clientFirstName}</p>
                      </div>
                      <div className="col-md-4">
                        <h4>Last Name</h4>
                        <p>{this.state.clientLastName}</p>
                      </div>
                      <div className="col-md-4">
                        <h4>Client</h4>
                        <p>
                          <span className="blue">{this.state.clientName}</span>
                        </p>
                      </div>
                      <div className="col-md-4">
                        <h4>Email ID</h4>
                        <p>{this.state.clientEmailId}</p>
                      </div>
                      <div className="col-md-4">
                        <h4>Phone Number</h4>
                        <p>
                          +{this.state.countryCode} {this.state.clientPhone}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <h4>Industry Type</h4>
                        <p>{this.state.clientIndustryType}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="tab-pane" id="chattab">
                <div className="job-section-tab">
                  <div className="prticipants-area _fl">

                    <div className="row">
                      <div className="col-md-6">
                        {/* <h3>3 Participants</h3> */}
                      </div>

                      <div className="col-md-6">
                        <a href="javascript:void(0)" className="add-part-btn" onClick={this.openParticipantModal}>
                          + Create Group
                        </a>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        {this.state.chatGroup.length > 0 ?
                          <ChatGroupList
                            value={this.state.chatGroup}
                            changeGroupValue={(value) => this.changeChatGroup(value)}
                          /> :
                          <></>
                        }
                      </div>
                    </div>
                  </div>
                  {this.showChatPage(this.state.selectedChatGroupValue)}
                  {/* {this.state.selectedChatGroupValue !== "" ? <MainChatPage jobId={this.state.selectedChatGroupValue} /> : <></>} */}
                </div>

              </div>

              <Modal show={this.state.addParticipantShow} onHide={this.closeParticipantModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Participants</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="web-form-bx">
                    <div className="frm-label">
                      Group Name
                    </div>
                    <div className="form-input-fields">
                      <input
                        type="text"
                        placeholder="Enter group name..."
                        className="textbox4"
                        style={{
                          borderRadius: "9px",
                          boxShadow:
                            "0px 0px 4px 0px rgb(0 0 0 / 20%)",
                        }}
                        value={this.state.chatGroupName}
                        onChange={this.changeChatGroupName}
                      />
                    </div>
                  </div>

                  <div className="web-form-bx">
                    <div className="frm-label">Participants *</div>
                    <div className="bts-drop">
                      <MultiSelectBox
                        optionData={this.state.chatParticipants}
                        value={this.state.selectedChatParticipants}
                        onSelectChange={(value) =>
                          this.onSelectChatParticipants(value)
                        }
                      ></MultiSelectBox>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.closeParticipantModal}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={this.submitParticipants}>
                    Send Requests
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal show={this.state.addGrouoChatParticipantModal} >
                <Modal.Header closeButton>
                  <Modal.Title>Add Participants</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="web-form-bx">
                    <div className="frm-label">Participants *</div>
                    <div className="bts-drop">
                      <MultiSelectBox
                        optionData={this.state.chatParticipants}
                        value={this.state.selectedAddGroupChatParticipantValue}
                        onSelectChange={(value) =>
                          this.selectAddGroupParticipants(value)
                        }
                      ></MultiSelectBox>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.closeAddGroupChatParticipantModal}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={this.submitParticipantsInGroup}>
                    Add
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal show={this.state.chatParticipantModal} onHide={this.closeChatParticipantModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Participants List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="web-form-bx">
                    <div className="frm-label">Participants List</div>
                    <div className="row">
                      {this.state.groupChatParticipant.map((data, i) => <>
                        <div className="col-md-10" key={i}>
                          {data.label}
                        </div>
                        {this.state.groupChatParticipant.length > 1 ?
                          <div className="col-md-2">
                            <MdDelete style={{ cursor: "pointer" }} onClick={() => this.removeParticipants(data)} />
                          </div> : <></>}
                      </>)}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.closeChatParticipantModal}>
                    Close
                  </Button>
                  {/* <Button variant="primary" onClick={this.submitParticipants}>
                    Add
                  </Button> */}
                </Modal.Footer>
              </Modal>

              <div className="tab-pane" id="nofifications">
                <div className="job-section-tab">
                  <div className="chat-app-information-component">
                    <div className="chat-app-component">
                      {this.state.allJobNotifications.map((data, key) => <>
                        <div class="accordion" id={"accordionExample" + key} >
                          <div class="card notify_card">
                            <div
                              class="card-header"
                              id={"headingOne" + key}
                              type="button"
                              style={{
                                borderRadius: "20px ",
                                padding: "20px 0px",
                                backgroundColor: "white",
                              }}
                              data-toggle="collapse"
                              data-target={"#collapseOne" + key}
                              aria-expanded="false"
                              aria-controls={"collapseOne" + key}
                            >
                              <div>
                                <div className="row notify">
                                  <div className="col-md-2">
                                    <React.Fragment>
                                      <img
                                        src={ImageName.IMAGE_NAME.CLINGO_LOGO_GRAY_COLOR}
                                        style={{ width: "40px" }}
                                      ></img>
                                    </React.Fragment>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="job_details">
                                      {data.body}
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="notify_time">
                                      {data.time}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                      )}
                    </div>
                  </div>
                </div>
              </div>


              <div className="tab-pane" id="notetab">
                <div className="job-section-tab">
                  <div className="chat-app-information-component">
                    <div className="chat-app-component">
                      {this.state.allJobNotes.map((data, i) =>
                        <div className="participants-chat-row" key={i}>
                          <figure>
                            <img src={ImageName.IMAGE_NAME.LOGO1} />
                          </figure>
                          <div className="chatinfo-bx">
                            <p className="cht-user">
                              <b>{data.userName}</b> <span>{SetUSAdateFormatV2(data.createDate) + " " + SetTimeFormat(data.createDate)}</span>
                            </p>
                            <p className="chat-text">
                              {data.notes}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>


                  </div>
                  <div className="chat-app-type-area">
                    <input
                      type="text"
                      value={this.state.notesText}
                      name="notes"
                      className="chat-field-bx"
                      onChange={(e) => this.changeInternalNotes(e)}
                    />
                    <button type="submit" className="send-btn-app" onClick={() => this.addNotes()}>
                      send
                    </button>
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="chattab">
                <div className="job-section-tab">
                  <div className="chat-app-information-component">
                    <div className="prticipants-area _fl">
                      <div className="row">
                        <div className="col-md-6">
                          <h3>3 Participants</h3>
                        </div>

                        <div className="col-md-6">
                          <a href="#" className="add-part-btn">
                            Add Participants
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="chat-app-component">
                      <div className="participants-chat-row">
                        <figure>
                          <img src={ImageName.IMAGE_NAME.LOGO1} />
                        </figure>
                        <div className="chatinfo-bx">
                          <p className="cht-user">
                            <b>Admin ID</b> <span>10:20 AM</span>
                          </p>
                          <p className="chat-text">
                            Kindly check milestones, added bellowKindly check
                            milestones, added bellowKindly check milestones,
                            awKindly check milestones, added bellow
                          </p>
                        </div>
                      </div>
                      <div className="participants-chat-row">
                        <figure>
                          <img src={ImageName.IMAGE_NAME.LOGO1} />
                        </figure>
                        <div className="chatinfo-bx">
                          <p className="cht-user">
                            <b>Admin ID</b> <span>10:20 AM</span>
                          </p>
                          <p className="chat-text">
                            Kindly check milestones, added bellowKindly check
                            milestones, added bellowKindly check milestones,
                            awKindly check milestones, added bellow
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="chat-app-component">
                      <div className="participants-chat-row reply">
                        <div className="chatinfo-bx">
                          <p className="cht-user">
                            <b>Admin ID</b> <span>10:20 AM</span>
                          </p>
                          <p className="chat-text">
                            Kindly check milestones, added bellowKindly check
                            milestones, added bellowKindly check milestones,
                            awKindly check milestones, added bellow
                          </p>
                        </div>
                      </div>
                      <div className="participants-chat-row">
                        <figure>
                          <img src={ImageName.IMAGE_NAME.LOGO1} />
                        </figure>
                        <div className="chatinfo-bx">
                          <p className="cht-user">
                            <b>Admin ID</b> <span>10:20 AM</span>
                          </p>
                          <p className="chat-text">
                            Kindly check milestones, added bellowKindly check
                            milestones, added bellowKindly check milestones,
                            awKindly check milestones, added bellow
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="chat-app-type-area">
                    <input
                      type="text"
                      value=""
                      name=""
                      className="chat-field-bx"
                    />
                    <button type="submit" className="send-btn-app">
                      send
                    </button>
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="nofifications">
                Notification
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
